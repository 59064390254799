export const collectionDetails = [
  {
    key: '3rd Party Tools',
    icon: 'fa-regular fa-user',
  },
  {
    key: 'Account Management',
    icon: 'fa-regular fa-user',
  },
  {
    key: 'Advertising',
    icon: 'fa-regular fa-rectangle-ad',
  },
  {
    key: 'Amazon 101',
    icon: 'fa-brands fa-amazon',
  },
  {
    key: 'BBE',
    icon: 'fa-regular fa-chevron-up',
  },
  {
    key: 'Catalog',
    icon: 'fa-regular  fa-newspaper',
  },
  {
    key: 'Company Knowledge',
    icon: 'fa-regular fa-building',
  },
  {
    key: 'Creative',
    icon: 'fa-regular fa-pen-ruler',
  },
  {
    key: 'Customer Service',
    icon: 'fa-regular fa-user-headset',
  },
  {
    key: ' International Expansion Services',
    icon: 'fa-regular fa-book-atlas',
  },
  {
    key: 'Human Resource',
    icon: 'fa-regular fa-handshake-angle',
  },
  {
    key: 'Logistics',
    icon: 'fa-regular fa-warehouse-full',
  },
  {
    key: 'Marketing',
    icon: 'fa-regular fa-megaphone',
  },
  {
    key: 'NEXT',
    icon: 'fa-regular fa-location-question',
  },
  {
    key: 'Productivity Tools',
    icon: 'fa-regular fa-screwdriver-wrench',
  },
  {
    key: 'Sales',
    icon: 'fa-regular fa-piggy-bank',
  },
  {
    key: 'Seller Support - Seller Performance',
    icon: 'fa-regular fa-chart-line-up',
  },
  {
    key: 'Vendor Collection',
    icon: 'fa-regular fa-boxes-stacked',
  },
  {
    key: 'Technology',
    icon: 'fa-regular fa-laptop-mobile',
  },
];

export const managementLink =
  'https://sites.google.com/buyboxexperts.com/employee-engagement/home';
export const helpDeskLink =
  'https://bbe.atlassian.net/servicedesk/customer/portals';

export const brandSteps = [
  {
    key: 'brand_logo',
    url: 'brand-logo',
    label: 'Brand Logo',
    step: 1,
    subtitle: 'Please upload one or more versions of your brand logo.',
    format: 'AI or EPS file',
    skip: 'brand-guidelines',
  },
  {
    key: 'brand_guidelines',
    url: 'brand-guidelines',
    label: 'Brand Guidelines',
    step: 2,
    subtitle:
      'Please upload a brand style guide. This should include specifics around brand colors, logo usage, fonts, tone of voice and other relevant information.',
    format: 'PDF',
    skip: 'font-files',
  },
  {
    key: 'font_files',
    url: 'font-files',
    label: 'Font Files',
    step: 3,
    subtitle:
      'Please upload the raw font files that are used across your brand identity, such as for your logo or any specific fonts used on your website and marketing material.',
    format: 'OTF, TTF, WOFF',
    skip: 'iconography',
  },
  {
    key: 'iconography',
    url: 'iconography',
    label: 'Iconography',
    step: 4,
    subtitle:
      "Please upload any iconography you use across your brand's various platforms, such as your website and marketing material.",

    format: 'AI, EPS, SVG',
    skip: 'additional-brand-material',
  },
  {
    key: 'additional_brand_material',
    url: 'additional-brand-material',
    label: 'Additional Brand Material',
    step: 5,
    subtitle:
      'Please upload anything else you have that will help us understand and represent your brand image as accurately as possible.',
    format: '',
    skip: 'summary',
  },
];

export const dspPacingTooltipConstants = {
  totalAmountPaidTillDateInfo: {
    id: 'totalAmountPaidTillDateInfo',
    text: null,
    dataCy: 'BT-dspAdPacingOverlay-totalAmountPaidTillDateInfo',
  },
  invoicePaidInfo: {
    id: 'invoicePaidInfo',
    text: 'Invoice(s) paid for current month',
    dataCy: 'BT-dspAdPacingOverlay-invoicePaidInfo',
  },
  carryOverInfo: {
    id: 'carryOverInfo',
    text: 'Escrow amount that was reallocated from previous months and/or future months to the current month. The amount is locked in on day 2 of the current month.',
    dataCy: 'BT-dspAdPacingOverlay-carryOverInfo',
  },
  initialTotalbudgetInfo: {
    id: 'initialTotalbudgetInfo',
    text: 'Invoice amount/paid + Carry-over',
    dataCy: 'BT-dspAdPacingOverlay-initialTotalBudgetInfo',
  },
  initialDailyBudgetInfo: {
    id: 'initialDailybudgetInfo',
    text: 'Initial Total Budget/number of days in current month',
    dataCy: 'BT-dspAdPacingOverlay-initialDailyBudgetInfo',
  },
  dayPassedInfo: {
    id: 'dayPassedInfo',
    text: null,
    dataCy: 'BT-dspAdPacingOverlay-dayPassedInfo',
  },
  plannedSpendToDateInfo: {
    id: 'plannedSpendToDateInfo',
    text: `This values is affected by an escrow reallocation. Planned spend value which accounts for 1- the budget initially allocated to the current month and 2- any escrow reallocations throughout the month.`,
    dataCy: 'BT-dspAdPacingOverlay-plannedSpendToDateInfo',
  },
  actualSpendToDateInfo: {
    id: 'actualSpendToDateInfo',
    data_for: 'actualSpendToDateInfo',
    text: null,
    dataCy: 'BT-dspAdPacingOverlay-actualSpendToDateInfo',
  },
  remainingBudgetInfo: {
    id: 'remainingBudgetInfo',
    data_for: 'remainingBudgetInfo',
    text: 'Remaining Budget = Initial Total Budget +/- escrow reallocation - Actual Spend to date',
    dataCy: 'BT-dspAdPacingOverlay-remainingBudgetInfo',
  },
  escrowBalanceInfo: {
    id: 'escrowBalanceInfo',
    text: 'Escrow Balance = Remaining Budget + budgets of future months.',
    dataCy: 'BT-dspAdPacingOverlay-escrowBalanceInfo',
  },
};

export const billingOffboardErrorMsg = [
  'This cancel request has been already approved',
  'This cancel request has been already rejected',
  'This pause request has been already approved',
  'This pause request has been already rejected',
];

export const oneTimeServicesTitle = [
  { key: 'Brand Story', title: 'General' },
  { key: 'Amazon Storefront Home Page', title: 'General' },
  { key: 'Amazon Storefront Tab', title: 'General' },
  { key: 'Amazon Storefront Sub Tab', title: 'General' },
  { key: 'Walmart Storefront Home Page', title: 'General' },
  { key: 'Walmart Storefront Tab', title: 'General' },
  { key: 'Walmart Storefront Sub Tab', title: 'General' },
  { key: 'Infographics Original', title: 'General' },
  { key: 'Infographics Variation', title: 'General' },
  { key: 'A+ Original', title: 'General' },
  { key: 'A+ Similar', title: 'General' },
  { key: 'Premium A+ Content Original', title: 'General' },
  { key: 'Premium A+ Content Variation', title: 'General' },

  { key: 'Enhanced Original', title: 'General' },
  { key: 'Enhanced Similar', title: 'General' },
  { key: 'Premium Enhanced Content Original', title: 'General' },
  { key: 'Premium Enhanced Content Variation', title: 'General' },

  { key: 'Original Listing', title: 'Content' },
  { key: 'Variation Listing', title: 'Content' },

  { key: 'Backend Search Terms Update', title: 'Search Engine Optimization' },
  { key: 'Keyword Audit', title: 'Search Engine Optimization' },

  { key: 'DSP Ads (1 Set)', title: 'Creative Add-Ons' },
  { key: 'Email Template', title: 'Creative Add-Ons' },
  { key: 'Product Insert', title: 'Creative Add-Ons' },
  { key: 'Style Guide', title: 'Creative Add-Ons' },
  { key: 'Graphics Translation', title: 'Creative Add-Ons' },

  { key: 'Studio Photography, 1 product', title: 'Photography' },
  { key: 'Studio Photography up to 5 products', title: 'Photography' },
  { key: 'Styled Studio Photography for 1 product', title: 'Photography' },
  {
    key: 'Styled Studio Photography for up to 5 products',
    title: 'Photography',
  },
  { key: 'Studio and Lifestyle Photography 1 product', title: 'Photography' },
  {
    key: 'Studio and Lifestyle Photography up to 5 products',
    title: 'Photography',
  },
];
export const dataScrappedMsg =
  "Data is scrapped since it's not available through Amazon, therefore there might be some discrepancy.";

export const SpreetailRole = 'Spreetail';

export const SpreetailRoleRestrictMessage =
  'Only authorized users are permitted to perform this action.';
