import {
  PATH_AMAZON_ACCOUNT,
  PATH_AMAZON_MERCHANT,
  PATH_BILLING_DETAILS,
  PATH_COMPANY_DETAILS,
  PATH_SUMMARY,
} from './RouteConstants';

export const billingStaticMessage = {
  walmartRecipientMessage:
    'If there are no Walmart billing recipients entered, Walmart invoices will automatically be sent to Amazon billing recipients.',
};

export const achDetails = [
  { key: 'account_name', label: 'Name on Account', type: 'text' },
  { key: 'bank_name', label: 'Bank Name', type: 'text' },
  { key: 'account_number', label: 'Account Number', type: 'number' },
  { key: 'routing_number', label: 'Routing Number', type: 'number' },
];

export const creditCardDetails = [
  {
    details: [
      {
        key: 'card_holder_name',
        label: 'Cardholder Name',
        type: 'text',
        property: 'col-12',
      },
      {
        key: 'card_number',
        label: 'Credit Card Number',
        type: 'number',
        property: 'col-12',
        format: '#### #### #### ####',
      },
      {
        key: 'expiration_date',
        label: 'Expiration Date',
        type: 'number',
        property: 'col-8 pr-0',
        format: '##/##',
      },
      {
        key: 'card_code',
        label: 'CVV',
        type: 'number',
        property: 'col-4',
        format: '####',
      },
    ],
  },
];

export const billingAddress = [
  {
    key: 'address',
    label: 'Address',
    type: 'text',
    property: 'col-12',
    section: 'address',
  },
  {
    key: 'city',
    label: 'City',
    type: 'text',
    property: 'col-12',
    section: 'address',
  },
  {
    key: 'state',
    label: 'State',
    type: 'text',
    property: 'col-6 pr-2',
    section: 'address',
  },
  {
    key: 'postal_code',
    label: 'Postal Code',
    type: 'text',
    property: 'col-6 pl-2',
    section: 'address',
  },
  {
    key: 'first_name',
    label: 'First name',
    type: 'text',
    property: 'col-6 pr-2',
    section: 'contact',
  },
  {
    key: 'last_name',
    label: 'Last name',
    type: 'text',
    property: 'col-6 pl-2',
    section: 'contact',
  },

  {
    key: 'email',
    label: 'Email',
    type: 'email',
    property: 'col-6 pr-2',
    section: 'contact',
  },
  {
    key: 'phone_number',
    label: 'Phone number',
    type: 'number',
    property: 'col-6 pl-2',
    section: 'contact',
  },
];

export const paymentTermValueLabel = [
  {
    value: 'auto pay',
    label: 'Autopay',
  },
  {
    value: 'due on receipt',
    label: 'Due on receipt',
  },
  {
    value: 'net 7',
    label: 'NET-7',
  },
  {
    value: 'net 14',
    label: 'NET-14',
  },
  {
    value: 'net 30',
    label: 'NET-30',
  },
  {
    value: 'net 9',
    label: 'NET-9',
  },
  {
    value: 'net 10',
    label: 'NET-10',
  },
  {
    value: 'net 15',
    label: 'NET-15',
  },
  {
    value: 'net 60',
    label: 'NET-60',
  },
];

export const paymentType = [
  { key: 'ach', label: 'ACH', type: 'radio' },
  { key: 'credit_card', label: 'Credit Card', type: 'radio' },
];

export const whichStep = [
  {
    key: 'digital presence',
    stepof: 2,
    title: "Your Company's Digital Presence",
    skip: PATH_BILLING_DETAILS,
    bar: '20',
    path: 'company-details',
    subTitle: 'Need help on why we need this information?',
    video: true,
  },
  {
    key: 'billing information',
    stepof: 3,
    title: 'Billing Information',
    skip: PATH_AMAZON_MERCHANT,
    back: PATH_COMPANY_DETAILS,
    bar: '35',
    path: 'billing-details',
    video: false,
  },
  {
    key: 'merchant id',
    stepof: 4,
    title: 'Amazon Account Access',
    skip: PATH_AMAZON_ACCOUNT,
    back: PATH_BILLING_DETAILS,
    bar: '55',
    path: 'amazon-merchant',
    subTitle: '',
    video: false,
  },
  {
    key: 'amazon account',
    stepof: 5,
    title: 'Amazon Account Permissions',
    skip: PATH_SUMMARY,
    back: PATH_AMAZON_MERCHANT,
    bar: '85',
    path: 'amazon-account',
    subTitle: '',
    video: false,
  },
];

export const stepPath = [
  {
    key: 'digital presence',
    view: PATH_COMPANY_DETAILS,
    title: "Your Company's Digital Presence",
  },
  {
    key: 'billing information',
    title: 'Billing Information',
    view: PATH_BILLING_DETAILS,
  },
  {
    key: 'merchant id',
    title: 'Amazon Account Access',
    view: PATH_AMAZON_MERCHANT,
  },
  {
    key: 'amazon account',
    title: 'Amazon Account Permissions',
    view: PATH_AMAZON_ACCOUNT,
  },
];

export const amazonSellerAccountDetails = [
  {
    key: 'seller_central_name',
    label: 'Seller Central Account Name',
    section: 1,
  },
  { key: 'merchant_id', label: 'Merchant ID', section: 1 },
  { key: 'advertiser_name', label: 'Advertiser Name', section: 2 },
  { key: 'advertiser_id', label: 'Advertiser ID', section: 2 },
];

export const amazonVendorAccountDetails = [
  {
    key: 'vendor_central_name',
    label: 'Vendor Central Account Name',
    section: 1,
  },
  { key: 'vendor_code', label: 'Vendor Code', section: 1 },
  { key: 'advertiser_name', label: 'Advertiser Name', section: 2 },
  { key: 'advertiser_id', label: 'Advertiser ID', section: 2 },
];

export const amazonSellerAccDetails = [
  {
    key: 'ad_account_name',
    label: 'Ad Account Name on Amazon',
    section: 1,
  },
  {
    key: 'seller_central_name',
    label: 'Seller Central Account Name',
    section: 1,
  },
  { key: 'merchant_id', label: 'Merchant ID', section: 1 },
  { key: 'advertiser_name', label: 'Advertiser Name', section: 2 },
  { key: 'advertiser_id', label: 'Advertiser ID', section: 2 },
];

export const amazonVendorAccDetails = [
  {
    key: 'ad_account_name',
    label: 'Ad Account Name on Amazon',
    section: 1,
  },
  {
    key: 'vendor_central_name',
    label: 'Vendor Central Account Name',
    section: 1,
  },
  { key: 'vendor_code', label: 'Vendor Code', section: 1 },
  { key: 'advertiser_name', label: 'Advertiser Name', section: 2 },
  { key: 'advertiser_id', label: 'Advertiser ID', section: 2 },
];

export const amazonDspAccDetails = [
  {
    key: 'advertiser_name',
    label: 'DSP Advertiser Name on Amazon',
    section: 3,
  },
  {
    key: 'entity_name',
    label: 'DSP Entity Name',
    section: 3,
  },
  { key: 'entity_id', label: 'DSP Entity ID', section: 3 },
  { key: 'advertiser_id', label: 'Advertiser ID', section: 3 },
];

export const authorizeLink =
  'https://www.authorize.net/en-us/about-us/dpa.html';

export const customVideoStyle = {
  width: '16px',
  marginRight: '6px',
  verticalAlign: 'text-bottom',
  cursor: 'pointer',
};

export const billingTerms =
  'I hereby acknowledge that I am an authorized signer on the account listed above and hereby authorize payments to be made to BBE using this payment method to satisfy any and all invoices or bills on our account with BBE moving forward until or unless further notice is provided in writing. I further agree to the additional Terms & Conditions for these payment and agree to the terms and conditions found ';

export const delegatedInfo =
  ' If you’re unable to provide this information or you think this was sent to you unintentionally, please let them know via the email address highlighted above.';

export const billingInformation = [
  {
    label: 'Billing Address',
    part: 'Part 1',
    array: billingAddress.filter((op) => op.section === 'address'),
    key: 'billing_address',
    hasCheckbox: false,
  },
  {
    label: 'Billing Contact',
    additional_label: 'Additional Billing Contact',
    part: 'Part 2',
    array: billingAddress.filter((op) => op.section === 'contact'),
    key: 'billing_contact',
    hasCheckbox: false,
  },
];

export const amazonAccountManual = [
  {
    icon: 'fa-regular fa-1',
    info: 'Go to Settings → User Permissions → Invite amazon5@buyboxexperts.com to your user list.',
  },
  {
    icon: 'fa-regular fa-2',
    info: 'Apply this invitation to all of your marketplaces that you require BBE to manage for your Seller Central/Vendor Central.',
  },
  {
    icon: 'fa-regular fa-3',
    info: "Complete the checkboxes below to make sure that you've added us to all the agreed marketplaces.",
  },
];

export const accountInfo = [
  'Create User Account on Next',
  'Provide Your Company’s Digital Presence',
  'Provide Billing Information',
  'Provide Amazon Account Access',
  'Provide Amazon Account Permissions',
];
