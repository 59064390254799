/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string, bool, number, func, arrayOf, shape } from 'prop-types';

import { PlusIcon, MinusIcon } from '../../../../theme/images';
import { ErrorMsg, InputFormField, CheckBox } from '../../../../common';
import { checkDisabledConditionForServices } from '../../../../common/ConditionsForStatementOfWork';
import { oneTimeServicesTitle } from '../../../../constants';
import AdditionalOneTimeServicesSubTabs from './AdditionalOneTimeServicesSubTabs';

function AdditionalOneTimeServices({
  setFormData,
  setAdditionalOnetimeServices,
  setUpdatedFormData,
  updatedFormData,
  onAddDiscount,
  formData,
  oneTimeService,
  handleChange,
  changeQuantity,
  additionalOnetimeSerError,
  showFooter,
  additionalOnetimeServices,
  clearOneTimeQntyError,
  updateAdditionalOnetimeServicesSelectedData,
  discountData,
  notIncludedOneTimeServices,
  setNotIncludedOneTimeServices,
  setAdditionalOnetimeSerError,
  details,
  updateStatementErrorValue,
  sectionError,
  contractAddendumDetails,
  combinedOneTimeServices,
  filterNotIncludedOneTimeServices,
}) {
  const [openCollapse, setOpenCollapse] = useState({
    general: true,
    content: false,
    seo: false,
    creative: false,
    photography: false,
  });
  const additionalOneTimeServicesLength =
    formData?.additional_one_time_services?.length;

  const additionalOneTimeSerErrQuantityLength =
    additionalOnetimeSerError?.quantity?.length;

  useEffect(() => {
    for (const service of oneTimeService) {
      for (const title of oneTimeServicesTitle) {
        if (title.key === service.label) {
          service.title = title.title;
        }
      }
    }
  }, [oneTimeService]);

  const showDiscountLabel = () => {
    const discount =
      discountData?.length &&
      discountData.filter((item) => item.service_type === 'one time service');
    if (discount?.length && discount[0]?.type) {
      return 'Edit Discount';
    }
    return 'Add Discount';
  };

  const showAdditionalOneTimeServiceError = () => {
    if (formData?.contract_type?.toLowerCase()?.includes('one')) {
      return formData?.additional_one_time_services?.length ? (
        ``
      ) : (
        <ErrorMsg>At least 1 one time service required</ErrorMsg>
      );
    }
    return null;
  };

  const generateHTML = (oneTimeServiceData) => {
    return (
      <React.Fragment key={oneTimeServiceData.value}>
        <div className="col-7 ">
          <CheckBox>
            <label
              className={
                contractAddendumDetails?.notActive
                  ? 'check-container customer-pannel isDisabled'
                  : 'check-container customer-pannel'
              }
              htmlFor={oneTimeServiceData.value}
            >
              {oneTimeServiceData.label}
              <input
                type="checkbox"
                name={oneTimeServiceData.label}
                id={oneTimeServiceData.value}
                onClick={(event) =>
                  handleChange(
                    event,
                    'additional_one_time_services',
                    'checkbox',
                    oneTimeServiceData,
                  )
                }
                defaultChecked={
                  additionalOneTimeServicesLength &&
                  formData.additional_one_time_services.find(
                    (item) =>
                      item.service?.id === oneTimeServiceData.value ||
                      item.service_id === oneTimeServiceData.value,
                  )
                }
                disabled={contractAddendumDetails?.notActive}
              />
              <span className="checkmark" />
            </label>
          </CheckBox>
        </div>
        {additionalOneTimeServicesLength &&
        formData.additional_one_time_services.find((item) =>
          item.service_id
            ? item.service_id === oneTimeServiceData.value
            : item.service?.id === oneTimeServiceData.value,
        ) ? (
          <>
            <div className="col-5 pl-0 text-end">
              <button
                type="button"
                className="decrement"
                onClick={() => {
                  changeQuantity(oneTimeServiceData, 'minus');
                }}
                disabled={
                  contractAddendumDetails?.notActive
                    ? true
                    : checkDisabledConditionForServices(
                        formData?.additional_one_time_services,
                        oneTimeServiceData?.value,
                        1,
                      )
                }
              >
                {' '}
                <img className="minus-icon" src={MinusIcon} alt="" />
              </button>

              <NumberFormat
                name={oneTimeServiceData.label}
                className={
                  contractAddendumDetails?.notActive
                    ? 'form-control max-min-number isDisabled'
                    : 'form-control max-min-number'
                }
                value={
                  formData.additional_one_time_services.find((item) =>
                    item.service_id
                      ? item.service_id === oneTimeServiceData.value
                      : item.service?.id === oneTimeServiceData.value,
                  ).quantity
                }
                id={oneTimeServiceData.value}
                onChange={(event) =>
                  handleChange(
                    event,
                    'additional_one_time_services',
                    'quantity',
                    oneTimeServiceData,
                  )
                }
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  if (floatValue == null) {
                    return formattedValue === '';
                  }
                  return floatValue <= 100;
                }}
                disabled={contractAddendumDetails?.notActive}
                allowNegative={false}
              />

              <button
                type="button"
                className="increment"
                onClick={() => {
                  changeQuantity(oneTimeServiceData, 'add');
                }}
                disabled={
                  contractAddendumDetails?.notActive
                    ? true
                    : checkDisabledConditionForServices(
                        formData?.additional_one_time_services,
                        oneTimeServiceData?.value,
                        100,
                      )
                }
              >
                <img className="plus-icon" src={PlusIcon} alt="" />
              </button>
            </div>
            <div className="col-12 text-left ">
              {additionalOneTimeSerErrQuantityLength &&
              additionalOnetimeSerError.quantity.find(
                (item) => item.service_id === oneTimeServiceData.value,
              ) ? (
                <ErrorMsg className="mb-3">
                  {additionalOneTimeSerErrQuantityLength &&
                    additionalOnetimeSerError.quantity.find(
                      (item) => item.service_id === oneTimeServiceData.value,
                    ).error}
                </ErrorMsg>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };

  const generateHeading = (key, label) => {
    return (
      <div
        onClick={() =>
          setOpenCollapse({ ...openCollapse, [key]: !openCollapse[key] })
        }
        role="presentation"
        className=" row cursor"
      >
        <div className="col-9">
          <div className=" mb-3 text-bold black-heading-title">{label}</div>
        </div>
        <div className="col-3 mb-3 text-right">
          <FontAwesomeIcon
            icon={
              openCollapse[key] ? 'fa-regular fa-minus' : 'fa-regular fa-plus'
            }
            style={{
              color: '#000',
              fontSize: '16px',
            }}
          />
        </div>
      </div>
    );
  };

  const showOneTimeSubTabs = (tab) => {
    return (
      <AdditionalOneTimeServicesSubTabs
        tabName={tab}
        details={details}
        formData={formData}
        setFormData={setFormData}
        updatedFormData={updatedFormData}
        setUpdatedFormData={setUpdatedFormData}
        additionalOneTimeServicesLength={additionalOneTimeServicesLength}
        oneTimeService={oneTimeService}
        contractAddendumDetails={contractAddendumDetails}
        additionalOnetimeServices={additionalOnetimeServices}
        setAdditionalOnetimeServices={setAdditionalOnetimeServices}
        showFooter={showFooter}
        additionalOnetimeSerError={additionalOnetimeSerError}
        setAdditionalOnetimeSerError={setAdditionalOnetimeSerError}
        notIncludedOneTimeServices={notIncludedOneTimeServices}
        setNotIncludedOneTimeServices={setNotIncludedOneTimeServices}
        updateAdditionalOnetimeServicesSelectedData={
          updateAdditionalOnetimeServicesSelectedData
        }
        clearOneTimeQntyError={clearOneTimeQntyError}
        updateStatementErrorValue={updateStatementErrorValue}
        sectionError={sectionError}
        combinedOneTimeServices={combinedOneTimeServices}
        filterNotIncludedOneTimeServices={filterNotIncludedOneTimeServices}
      />
    );
  };

  const generateCollapseHTML = (key, label) => {
    return (
      <Collapse isOpened={openCollapse[key]}>
        <div className="row">
          {oneTimeService
            ?.filter((op) => op.title === label)
            ?.map((oneTimeServiceData) => {
              if (
                oneTimeServiceData?.label?.includes('Amazon Storefront') ||
                oneTimeServiceData?.label?.includes('Walmart Storefront') ||
                oneTimeServiceData?.label?.includes('Infographics') ||
                oneTimeServiceData?.label?.includes('A+') ||
                oneTimeServiceData?.label?.includes('Enhanced')
              ) {
                if (
                  oneTimeServiceData?.label?.includes(
                    'Amazon Storefront Home Page',
                  )
                )
                  return <>{showOneTimeSubTabs('Amazon Storefront')}</>;
                if (
                  oneTimeServiceData?.label?.includes(
                    'Walmart Storefront Home Page',
                  )
                )
                  return <>{showOneTimeSubTabs('Walmart Storefront')}</>;
                if (
                  oneTimeServiceData?.label?.includes('Infographics Original')
                )
                  return <>{showOneTimeSubTabs('Infographics')}</>;
                if (oneTimeServiceData?.label?.includes('A+ Similar'))
                  return <>{showOneTimeSubTabs('A+')}</>;
                if (oneTimeServiceData?.label?.includes('Enhanced Similar'))
                  return <>{showOneTimeSubTabs('Enhanced')}</>;
                return null;
              }

              return <> {generateHTML(oneTimeServiceData)}</>;
            })}
        </div>
      </Collapse>
    );
  };

  const displayOneTimeServices = () => {
    return (
      <li>
        <InputFormField className="mb-3">
          <label htmlFor="additional_one_time_services">
            One Time Services
          </label>
          <div
            className={
              contractAddendumDetails?.notActive
                ? 'add-discount isDisabled'
                : 'add-discount'
            }
            role="presentation"
            onClick={() => onAddDiscount('one time service')}
          >
            {showDiscountLabel()}
          </div>
          {showAdditionalOneTimeServiceError()}
        </InputFormField>
        <>
          {generateHeading('general', 'General')}
          {generateCollapseHTML('general', 'General')}
          <div className="horizontal-line straight-line mt-2 mb-3 d-lg-block" />
        </>
        <>
          {generateHeading('content', 'Content')}
          {generateCollapseHTML('content', 'Content')}
          <div className="horizontal-line straight-line mt-2 mb-3 d-lg-block" />
        </>
        <>
          {generateHeading('seo', 'Search Engine Optimization')}
          {generateCollapseHTML('seo', 'Search Engine Optimization')}
          <div className="horizontal-line straight-line mt-2 mb-3 d-lg-block" />
        </>
        <>
          {generateHeading('creative', 'Creative Add-Ons')}
          {generateCollapseHTML('creative', 'Creative Add-Ons')}
          <div className="horizontal-line straight-line mt-2 mb-3 d-lg-block" />
        </>
        {/* <>
          {generateHeading('photography', 'Photography')}
          {generateCollapseHTML('photography', 'Photography')}
          <div className="horizontal-line straight-line mt-2 mb-3 d-lg-block" />
        </> */}
      </li>
    );
  };

  return <div>{displayOneTimeServices()}</div>;
}

export default AdditionalOneTimeServices;

AdditionalOneTimeServices.defaultProps = {
  onAddDiscount: () => {},
  formData: {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  setFormData: () => {},
  oneTimeService: [],
  handleChange: () => {},
  changeQuantity: () => {},
  additionalOnetimeSerError: {},
  showFooter: () => {},
  additionalOnetimeServices: {},
  clearOneTimeQntyError: () => {},
  updateAdditionalOnetimeServicesSelectedData: () => {},
  discountData: [],
  setAdditionalOnetimeServices: () => {},
  notIncludedOneTimeServices: [],
  setNotIncludedOneTimeServices: () => {},
  setAdditionalOnetimeSerError: () => {},
  details: {},
  updateStatementErrorValue: () => {},
  sectionError: {},
  contractAddendumDetails: {},
  combinedOneTimeServices: [],
  filterNotIncludedOneTimeServices: () => {},
};

AdditionalOneTimeServices.propTypes = {
  onAddDiscount: func,
  formData: shape({}),
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  setFormData: func,
  oneTimeService: arrayOf(shape({})),
  handleChange: func,
  changeQuantity: func,
  additionalOnetimeSerError: shape({
    quantity: number,
    custom_amazon_store_price: number,
  }),
  showFooter: func,
  additionalOnetimeServices: shape({
    create: arrayOf(shape({})),
    delete: arrayOf(string, shape({})),
  }),
  clearOneTimeQntyError: func,
  updateAdditionalOnetimeServicesSelectedData: func,
  discountData: arrayOf(shape()),
  setAdditionalOnetimeServices: func,
  notIncludedOneTimeServices: arrayOf(shape({})),
  setNotIncludedOneTimeServices: func,
  setAdditionalOnetimeSerError: func,
  details: shape({}),
  updateStatementErrorValue: func,
  sectionError: shape({}),
  contractAddendumDetails: shape({ notActive: bool }),
  combinedOneTimeServices: arrayOf(shape({})),
  filterNotIncludedOneTimeServices: func,
};
