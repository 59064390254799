// Add quamma seperator in a number
const displayNumber = (num) => {
  const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return res;
};

// Show Infographics and A+ content in a table row
// Hardcoded code for Onetimeservice subtab starts

// export const oneTimeSpecificServices = (formData, serviceName) => {
//   return formData?.additional_one_time_services?.filter((service) =>
//     service?.name
//       ? service?.name?.includes(serviceName)
//       : service?.service?.name?.includes(serviceName),
//   );
// };

// export const oneTimeSpecificServicesFees = (servicesFees, serviceName) => {
//   return servicesFees?.filter((service) =>
//     service?.name
//       ? service?.name?.includes(serviceName)
//       : service?.service?.name?.includes(serviceName),
//   );
// };

// export const oneTimeOneService = (services, serviceName) => {
//   return services?.find((item) =>
//     item?.name
//       ? item?.name?.includes(serviceName)
//       : item?.service?.name?.includes(serviceName),
//   );
// };

// export const oneTimeOneServiceFee = (services, serviceName) => {
//   return services?.find((fees) => fees?.name === serviceName);
// };

// export const amazonStorefrontRow = (
//   formData,
//   servicesFees,
//   serviceName,
//   selectedCurrency,
// ) => {
//   const amazonStorefrontServices = oneTimeSpecificServices(
//     formData,
//     serviceName,
//   );

//   const amazonStorefrontServicesFees = oneTimeSpecificServicesFees(
//     servicesFees,
//     serviceName,
//   );

//   const homePage = oneTimeOneService(
//     amazonStorefrontServices,
//     'Amazon Storefront Home Page',
//   );

//   const homePageFee = oneTimeOneServiceFee(
//     amazonStorefrontServicesFees,
//     'Amazon Storefront Home Page',
//   );

//   const storefrontTab = oneTimeOneService(
//     amazonStorefrontServices,
//     'Amazon Storefront Tab',
//   );

//   const storefrontTabFee = oneTimeOneServiceFee(
//     amazonStorefrontServicesFees,
//     'Amazon Storefront Tab',
//   );

//   const storefrontSubTab = oneTimeOneService(
//     amazonStorefrontServices,
//     'Amazon Storefront Sub Tab',
//   );

//   const storefrontSubTabFee = oneTimeOneServiceFee(
//     amazonStorefrontServicesFees,
//     'Amazon Storefront Sub Tab',
//   );
//   return amazonStorefrontServices?.length
//     ? `<tr>
//       <td style="border: 1px solid black;padding: 13px; ">
//              <span style=" background:#ffe5df; padding: 4px 9px;">

//         ${
//           amazonStorefrontServices && amazonStorefrontServices.length ? '1' : ''
//         }
//         </span>
//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">

//         Amazon Storefront ( ${
//           homePage ? `${homePage?.quantity}` : '0'
//         } Home Page + ${
//         storefrontTab ? `${storefrontTab?.quantity}` : '0'
//       } Storefront Tab(s) + ${
//         storefrontSubTab ? `${storefrontSubTab?.quantity}` : '0'
//       } Storefront Sub Tab(s))

//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">
//              <span style="background:#ffe5df; padding: 4px 9px; line-height: 1.7;">

//              ${
//                homePage?.quantity ? `${homePage?.quantity}` : 0
//              } (${selectedCurrency}${displayNumber(homePageFee?.fee)})
//              +
//              ${
//                storefrontTab?.quantity ? `${storefrontTab?.quantity}` : 0
//              } (${selectedCurrency}${displayNumber(storefrontTabFee?.fee)})
//              +
//              ${
//                storefrontSubTab?.quantity ? `${storefrontSubTab?.quantity}` : 0
//              } (${selectedCurrency}${displayNumber(storefrontSubTabFee?.fee)})

//         </span>
//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">
//       <span style="background:#ffe5df; padding: 4px 9px;">
//       ${selectedCurrency}${displayNumber(
//         (homePage?.quantity ? homePage?.quantity * homePageFee?.fee : 0) +
//           (storefrontTab?.quantity
//             ? storefrontTab?.quantity * storefrontTabFee?.fee
//             : 0) +
//           (storefrontSubTab?.quantity
//             ? storefrontSubTab?.quantity * storefrontSubTabFee?.fee
//             : 0),
//       )}
//       </td>
//       <tr>`
//     : '';
// };

// export const infographicsRow = (
//   formData,
//   servicesFees,
//   serviceName,
//   selectedCurrency,
// ) => {
//   const infographicsServices = oneTimeSpecificServices(formData, serviceName);
//   const infographicsServicesFees = oneTimeSpecificServicesFees(
//     servicesFees,
//     serviceName,
//   );

//   const infographicsOriginalServices = oneTimeOneService(
//     infographicsServices,
//     'Infographics Original',
//   );
//   const infographicsOriginalServicesFee = oneTimeOneServiceFee(
//     infographicsServicesFees,
//     'Infographics Original',
//   );

//   const infographicsVariationServices = oneTimeOneService(
//     infographicsServices,
//     'Infographics Variation',
//   );
//   const infographicsVariationServicesFees = oneTimeOneServiceFee(
//     infographicsServicesFees,
//     'Infographics Variation',
//   );

//   return infographicsServices?.length
//     ? `<tr>
//       <td style="border: 1px solid black;padding: 13px; ">
//              <span style=" background:#ffe5df; padding: 4px 9px;">

//         ${infographicsServices?.length ? '1' : ''}
//         </span>
//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">

//       Infographics ( ${
//         infographicsOriginalServices
//           ? `${infographicsOriginalServices?.quantity}`
//           : '0'
//       } Original(s) + ${
//         infographicsVariationServices
//           ? `${infographicsVariationServices?.quantity}`
//           : '0'
//       } Variation(s))

//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">
//              <span style="background:#ffe5df; padding: 4px 9px; line-height: 1.7;">

//              ${
//                infographicsOriginalServices?.quantity
//                  ? `${infographicsOriginalServices?.quantity}`
//                  : 0
//              } (${selectedCurrency}${displayNumber(
//         infographicsOriginalServicesFee?.fee,
//       )})
//              +
//              ${
//                infographicsVariationServices?.quantity
//                  ? `${infographicsVariationServices?.quantity}`
//                  : 0
//              } (${selectedCurrency}${displayNumber(
//         infographicsVariationServicesFees?.fee,
//       )})
//         </span>
//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">
//       <span style="background:#ffe5df; padding: 4px 9px;">
//       ${selectedCurrency}${displayNumber(
//         (infographicsOriginalServices?.quantity
//           ? infographicsOriginalServices?.quantity *
//             infographicsOriginalServicesFee?.fee
//           : 0) +
//           (infographicsVariationServices?.quantity
//             ? infographicsVariationServices?.quantity *
//               infographicsVariationServicesFees?.fee
//             : 0),
//       )}
//       </td>
//       <tr>`
//     : ``;
// };

// export const aPlusContentRow = (
//   formData,
//   servicesFees,
//   serviceName,
//   selectedCurrency,
// ) => {
//   const aPlusContentServices = oneTimeSpecificServices(formData, serviceName);

//   const aPlusContentServicesFees = oneTimeSpecificServicesFees(
//     servicesFees,
//     serviceName,
//   );

//   const aPlusOriginal = oneTimeOneService(aPlusContentServices, 'A+ Original');

//   const aPlusOriginalFees = oneTimeOneServiceFee(
//     aPlusContentServicesFees,
//     'A+ Original',
//   );

//   const aPlusSimilar = oneTimeOneService(aPlusContentServices, 'A+ Similar');

//   const aPlusSimilarFees = oneTimeOneServiceFee(
//     aPlusContentServicesFees,
//     'A+ Similar',
//   );

//   const premiumAPlusContentOriginal = oneTimeOneService(
//     aPlusContentServices,
//     'Premium A+ Content Original',
//   );

//   const premiumAPlusContentOriginalFees = oneTimeOneServiceFee(
//     aPlusContentServicesFees,
//     'Premium A+ Content Original',
//   );
//   const premiumAPlusContentVariation = oneTimeOneService(
//     aPlusContentServices,
//     'Premium A+ Content Variation',
//   );

//   const premiumAPlusContentVariationFees = oneTimeOneServiceFee(
//     aPlusContentServicesFees,
//     'Premium A+ Content Variation',
//   );
//   return aPlusContentServices?.length
//     ? `<tr>
//       <td style="border: 1px solid black;padding: 13px; ">
//              <span style=" background:#ffe5df; padding: 4px 9px;">

//         ${aPlusContentServices?.length ? '1' : ''}
//         </span>
//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">

//         A+ Content ( ${
//           aPlusOriginal ? `${aPlusOriginal?.quantity}` : '0'
//         } Original(s) + ${
//         aPlusSimilar ? `${aPlusSimilar?.quantity}` : '0'
//       } Similar(s) + ${
//         premiumAPlusContentOriginal
//           ? `${premiumAPlusContentOriginal?.quantity}`
//           : '0'
//       } Premium Original(s) + ${
//         premiumAPlusContentVariation
//           ? `${premiumAPlusContentVariation?.quantity}`
//           : '0'
//       } Premium Variation(s))

//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">
//              <span style="background:#ffe5df; padding: 4px 9px; line-height: 1.7;">

//              ${
//                aPlusOriginal?.quantity ? `${aPlusOriginal?.quantity}` : 0
//              } (${selectedCurrency}${displayNumber(aPlusOriginalFees?.fee)})
//              +
//              ${
//                aPlusSimilar?.quantity ? `${aPlusSimilar?.quantity}` : 0
//              } (${selectedCurrency}${displayNumber(aPlusSimilarFees?.fee)})
//              +
//              ${
//                premiumAPlusContentOriginal?.quantity
//                  ? `${premiumAPlusContentOriginal?.quantity}`
//                  : 0
//              } (${selectedCurrency}${displayNumber(
//         premiumAPlusContentOriginalFees?.fee,
//       )}) +
//               ${
//                 premiumAPlusContentVariation?.quantity
//                   ? `${premiumAPlusContentVariation?.quantity}`
//                   : 0
//               } (${selectedCurrency}${displayNumber(
//         premiumAPlusContentVariationFees?.fee,
//       )})

//         </span>
//       </td>
//       <td style="border: 1px solid black;padding: 13px; ">
//       <span style="background:#ffe5df; padding: 4px 9px;">
//       ${selectedCurrency}${displayNumber(
//         (aPlusOriginal?.quantity
//           ? aPlusOriginal?.quantity * aPlusOriginalFees?.fee
//           : 0) +
//           (aPlusSimilar?.quantity
//             ? aPlusSimilar?.quantity * aPlusSimilarFees?.fee
//             : 0) +
//           (premiumAPlusContentOriginal?.quantity
//             ? premiumAPlusContentOriginal?.quantity *
//               premiumAPlusContentOriginalFees?.fee
//             : 0) +
//           (premiumAPlusContentVariation?.quantity
//             ? premiumAPlusContentVariation?.quantity *
//               premiumAPlusContentVariationFees?.fee
//             : 0),
//       )}
//       </td>
//       <tr>`
//     : ``;
// };

// Hardcoded code for Onetimeservice subtab Ends
// ------------------------------------------------------------------------------------

// Functions to return onetime service subtab values for table row
// Optimized code for Onetimeservice subtab Starts

// Break the onetime service sub tab names
export const oneTimeServiceShortNames = (param) => {
  switch (param) {
    case 'Infographics Original':
      return 'Original(s)';
    case 'Infographics Variation':
      return 'Variation(s)';
    case 'A+ Original':
      return 'Original(s)';
    case 'A+ Similar':
      return 'Similar(s)';
    case 'Premium A+ Content Original':
      return 'Premium Original(s)';
    case 'Premium A+ Content Variation':
      return 'Premium Variation(s)';
    case 'Amazon Storefront Home Page':
      return 'Home Page(s)';
    case 'Amazon Storefront Tab':
      return 'Storefront Tab(s)';
    case 'Amazon Storefront Sub Tab':
      return 'Storefront Sub Tab';
    case 'Enhanced Original':
      return 'Original(s)';
    case 'Enhanced Similar':
      return 'Similar(s)';
    case 'Premium Enhanced Content Variation':
      return 'Premium Variation(s)';
    case 'Premium Enhanced Content Original':
      return 'Premium Original(s)';
    case 'Walmart Storefront Sub Tab':
      return 'Storefront Sub Tab';
    case 'Walmart Storefront Tab':
      return 'Storefront Tab(s)';
    case 'Walmart Storefront Home Page':
      return 'Home Page(s)';
    default:
      return '';
  }
};

export const oneTimeSpecificServices = (formData, serviceName) => {
  return formData?.additional_one_time_services?.filter((service) =>
    service?.name
      ? service?.name?.includes(serviceName)
      : service?.service?.name?.includes(serviceName),
  );
};

export const oneTimeSpecificServicesFees = (servicesFees, serviceName) => {
  return servicesFees?.filter((service) =>
    service?.name
      ? service?.name?.includes(serviceName)
      : service?.service?.name?.includes(serviceName),
  );
};

export const returnOneTimeServiceNames = (param) => {
  let serviceNames = param?.map((item) => {
    if (item?.quantity)
      return `${`${item?.quantity} ${oneTimeServiceShortNames(
        item?.name ? item?.name : item?.service?.name,
      )}`}`;
    return null;
  });
  if (serviceNames?.length) {
    serviceNames = serviceNames?.filter(Boolean);
  }
  return `(${serviceNames?.toString().replaceAll(',', ' + ')})`;
};

export const returnServiceFees = (servicesFees, serviceName) => {
  const itemFees = servicesFees?.filter((item1) =>
    serviceName === item1?.name ? item1?.fee : '',
  );
  if (itemFees?.length) return itemFees[0]?.fee;
  return '';
};

export const returnOneTimeServiceFees = (
  servicesFees,
  selectedCurrency,
  service,
) => {
  let serviceFees = service?.map((item) => {
    if (item?.quantity) {
      return ` ${item?.quantity} (${selectedCurrency}${displayNumber(
        returnServiceFees(servicesFees, item?.name || item?.service?.name),
      )})`;
    }
    return null;
  });
  if (serviceFees?.length) {
    serviceFees = serviceFees?.filter(Boolean);
  }
  return `${serviceFees?.toString().replaceAll(', ', ' + ')}`;
};

export const returnOneTimeServiceFeesTotal = (
  servicesFees,
  selectedCurrency,
  service,
) => {
  const serviceFeesTotal = service?.map((item) => {
    return `${
      item?.quantity
        ? item?.quantity *
          returnServiceFees(servicesFees, item?.name || item?.service?.name)
        : 0
    }`;
  });
  // eslint-disable-next-line no-unused-vars
  const sum = serviceFeesTotal.reduce((a, b) => a + b, 0);
  return `${selectedCurrency}${displayNumber(
    serviceFeesTotal?.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0),
  )}`;
};

// Optimized code for Onetimeservice subtab Ends
