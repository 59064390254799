// React imports
import React, { useState } from 'react';

// Third party imports
import Select from 'react-select';
import dayjs from 'dayjs';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { shape, string, func, arrayOf, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Local imports
import Theme from '../../../../theme/Theme';
import AgreementDetailOuterContainer from './AgreementDetailsStyled';
import OneTimeAgreementCompletionDateComfirmationModal from '../../../Contract/ContractModals/OneTimeAgreementCompletionDateComfirmationModal';
import { updateAccountDetails } from '../../../../api';
import { getAccountDetails } from '../../../../store/actions/accountState';
import {
  PATH_AGREEMENT,
  contractOptionsWithPaused,
  contractStatusIcons,
  draftContractOptions,
} from '../../../../constants';
import { FileContract, ServiceIcon } from '../../../../theme/images';
import {
  Button,
  WhiteCard,
  PageLoader,
  ActionDropDown,
  ViewData,
  Status,
  InputFormField,
  SingleSelectDropdown,
} from '../../../../common';

export default function OneTimeAgreement({
  agreements,
  id,
  history,
  DropdownIndicator,
  IconOption,
  setShowModal,
  userRole,
  childBPList,
  selectedCustomerId,
  setSelectedCustomerId,
  isSpreetailUser,
}) {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.accountState.isLoading);
  const [editCompletionDate, setEditCompletionDate] = useState(false);
  const [showCompletionDateModal, setShowCompletionDateModal] = useState({
    show: false,
    contractId: null,
  });
  const [saveCompletionDate, setSaveCompletionDate] = useState({});
  const [saveCompletionDateLoader, setSaveCompletionDateLoader] =
    useState(false);

  // https://bbe.atlassian.net/browse/PDV-8278
  // Removing the hard coded user emails and making it as dynamic access for authorized users.
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const allowedToComplete = userInfo?.allowed_to_completed_ontime_fe;

  // redirect to pages according to the option which we select from dropdown
  const handleContractOptions = (event, agreementId) => {
    switch (event.value) {
      case 'view':
        history.push({
          pathname: PATH_AGREEMENT.replace(':id', id).replace(
            ':contract_id',
            agreementId,
          ),
          state: history?.location?.search,
        });
        break;
      case 'edit':
        history.push({
          pathname: PATH_AGREEMENT.replace(':id', id).replace(
            ':contract_id',
            agreementId,
          ),
          state: history?.location?.search,
          showEditView: true,
        });
        break;
      case 'delete':
        setShowModal({ delete: true, agreementId });
        break;
      default:
        break;
    }
  };

  const formatAmazonStorefront = (services, channelType) => {
    const storeFrontHome = services?.find(
      (op) =>
        op?.service?.name?.includes(`${channelType} Storefront Home`) &&
        op?.quantity !== 0,
    );
    const storeFrontTab = services?.find(
      (op) =>
        op?.service?.name === `${channelType} Storefront Tab` &&
        op?.quantity !== 0,
    );
    const storeFrontSubTab = services?.find(
      (op) =>
        op?.service?.name?.includes(`${channelType} Storefront Sub`) &&
        op?.quantity !== 0,
    );
    return (
      <li className="mb-2">
        {`${channelType} Storefront (${
          storeFrontHome?.quantity
            ? `${storeFrontHome.quantity} Home Page(s)`
            : '0 Home Page'
        } 
          ${
            storeFrontTab?.quantity
              ? ` + ${storeFrontTab.quantity} Tab(s)`
              : '+ 0 Tab'
          } 
          ${
            storeFrontSubTab?.quantity
              ? ` + ${storeFrontSubTab?.quantity} Sub Tab(s)`
              : '+ 0 Sub Tab'
          })`}
      </li>
    );
  };

  const formatInforgraphics = (services) => {
    const infoOriginal = services?.find(
      (op) =>
        op?.service?.name?.includes('Infographics Original') &&
        op?.quantity !== 0,
    );
    const infoVariation = services?.find(
      (op) =>
        op?.service?.name === 'Infographics Variation' && op?.quantity !== 0,
    );
    return (
      <li className="mb-2">
        {`Infographics (${
          infoOriginal?.quantity
            ? `${infoOriginal.quantity} Original(s)`
            : '0 Original'
        } 
          ${
            infoVariation?.quantity
              ? ` + ${infoVariation.quantity} Variation(s)`
              : '+ 0 Variation'
          })`}
      </li>
    );
  };

  const formatAContent = (services, serviceName) => {
    const similar = services?.find(
      (op) =>
        op?.service?.name?.includes(`${serviceName} Similar`) &&
        op?.quantity !== 0,
    );
    const original = services?.find(
      (op) =>
        op?.service?.name === `${serviceName} Original` && op?.quantity !== 0,
    );
    const premiumOriginal = services?.find(
      (op) =>
        op?.service?.name === `Premium ${serviceName} Content Original` &&
        op?.quantity !== 0,
    );
    const premiumVariation = services?.find(
      (op) =>
        op?.service?.name === `Premium ${serviceName} Content Variation` &&
        op?.quantity !== 0,
    );
    return (
      <li className="mb-2">
        {`${serviceName} Content (${
          similar?.quantity ? `${similar.quantity} Similar(s)` : '0 Similar'
        } 
          ${
            original?.quantity
              ? ` + ${original.quantity} Original(s)`
              : '+ 0 Original'
          } 
           ${
             premiumOriginal?.quantity
               ? ` + ${premiumOriginal.quantity} Premium Original(s)`
               : '+ 0 Premium Original'
           } 
            ${
              premiumVariation?.quantity
                ? ` + ${premiumVariation.quantity} Premium Variation(s)`
                : '+ 0 Premium Variation'
            })`}
      </li>
    );
  };

  // function used to return one time services UI
  const displayOneTimeServices = (services) => {
    const checkAmazonStoreFront = services?.some((op) =>
      op?.service?.name?.includes('Amazon Storefront'),
    );
    const checkWalmartStoreFront = services?.some((op) =>
      op?.service?.name?.includes('Walmart Storefront'),
    );
    const checkInfographics = services?.some((op) =>
      op?.service?.name?.includes('Infographics'),
    );
    const checkAContent = services?.some((op) =>
      op?.service?.name?.includes('A+'),
    );

    // const checkAPlusContentSpecific = services?.find(
    //   (op) => op?.service?.name === 'A+ Content',
    // );

    const checkEnhancedContent = services?.some((op) =>
      op?.service?.name?.includes('Enhanced'),
    );

    return (
      <>
        {services
          .filter(
            (op) =>
              !op?.service?.name.includes('Amazon Storefront') &&
              !op?.service?.name.includes('Infographics') &&
              !op?.service?.name.includes('A+') &&
              !op?.service?.name.includes('Walmart Storefront') &&
              !op?.service?.name.includes('Enhanced'),
          )
          .map((item) => (
            <li key={item.id}>
              {item?.service?.name || ''} ({item?.quantity || ''})
            </li>
          ))}
        {checkAmazonStoreFront ? (
          <>{formatAmazonStorefront(services, 'Amazon')}</>
        ) : null}
        {checkWalmartStoreFront ? (
          <>{formatAmazonStorefront(services, 'Walmart')}</>
        ) : null}
        {checkInfographics ? <>{formatInforgraphics(services)}</> : null}
        {checkAContent ? <>{formatAContent(services, 'A+')}</> : null}
        {checkEnhancedContent ? (
          <>{formatAContent(services, 'Enhanced')}</>
        ) : null}
      </>
    );
  };

  // update contract completion date
  const updateAgreement = (contractId) => {
    setSaveCompletionDateLoader(true);

    const details = {
      ...saveCompletionDate,
      end_date: dayjs(saveCompletionDate.end_date).format('YYYY-MM-DD'),
    };
    updateAccountDetails(contractId, details).then((res) => {
      setSaveCompletionDateLoader(false);
      if (res?.status === 200) {
        toast.success('Completion Date for One time agreement is updated.');
        dispatch(getAccountDetails(selectedCustomerId));
        setShowCompletionDateModal({ show: false, contractId: null });
      }
    });
  };

  // generate UI of dates inside one time agreement card
  const generateBasicInfo = (
    label,
    value,
    contractType,
    contractId,
    signedDate,
  ) => {
    return (
      <li className="other-details">
        <div className="label">
          {label}
          {label === 'Completion Date' &&
          contractType === 'active' &&
          allowedToComplete ? (
            <>
              {' '}
              {saveCompletionDate?.end_date &&
              editCompletionDate[contractId] ? (
                <button
                  style={{
                    width: 'fit-content',
                    backgroundColor: '#74b035',
                    color: '#FFFFF',
                    border: '1px solid #74b035',
                  }}
                  type="button"
                  className={` btn-primary  extraNormal-text ml-2`}
                  onClick={() => {
                    setShowCompletionDateModal({ show: true, contractId });
                  }}
                >
                  <FontAwesomeIcon
                    className="cursor "
                    icon="fa-regular fa-check"
                    color="#fff"
                    fontSize="12px"
                  />
                </button>
              ) : (
                <>
                  {!editCompletionDate[contractId] ? (
                    <button
                      style={{
                        width: 'fit-content',
                        backgroundColor: '#FF5933',
                        color: '#FFFFF',
                        border: '1px solid #FF5933',
                      }}
                      className="btn-primary extraNormal-text ml-2"
                      type="button"
                      onClick={() => {
                        setEditCompletionDate({ [contractId]: true });
                        setSaveCompletionDate({});
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-pen"
                        style={{ color: '#fff', fontSize: '12px' }}
                        className="cursor "
                      />{' '}
                    </button>
                  ) : null}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </div>
        {label === 'Completion Date' && editCompletionDate[contractId] ? (
          <div>
            <InputFormField backgroundTransparent="transparent">
              <DatePicker
                className="form-control modal-input-control mt-0 onetimeAgreementCompletionDate"
                placeholderText="Select Date"
                id="completionDate"
                value={saveCompletionDate.end_date}
                format="MM/dd/yyyy"
                clearIcon={null}
                maxDate={new Date()}
                minDate={new Date(signedDate)}
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                onChange={(event) => {
                  setSaveCompletionDate({
                    contract_status: 'completed',
                    end_date: event,
                  });
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </InputFormField>
          </div>
        ) : (
          <div className="label-info text-medium-font">
            {value ? dayjs(value).format('MMM DD, YYYY') : value}
          </div>
        )}
      </li>
    );
  };

  // return Agreement status name
  const getAgreementName = (agreementStatus) => {
    return agreementStatus === 'active'
      ? 'Signed'
      : agreementStatus === 'pending contract'
      ? 'Pending Agreement'
      : agreementStatus === 'pending contract approval'
      ? 'Pending Agreement Approval'
      : agreementStatus === 'pending contract signature'
      ? 'Pending Agreement Signature'
      : agreementStatus === 'completed'
      ? 'Completed'
      : agreementStatus;
  };

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      setSelectedCustomerId(event?.value);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        // isApiCall={isApiCall}
        isSearchable={false}
      />
    );
  };

  // main return of the file
  return loader ? (
    <PageLoader
      component="agrement-details"
      color="#FF5933"
      type="detail"
      width={40}
      height={40}
    />
  ) : (
    <>
      {childBPList?.length ? (
        <WhiteCard className="mb-3 mt-3">
          <ViewData>
            <div className="row">
              <div className="col-md-4  col-sm-12 ">
                <div className="view-data-for mt-2 pt-1 ">View data for:</div>{' '}
              </div>
              <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
              <div className="col-md-4 col-12 pl-0">
                {displayChildBPFilter()}
              </div>
            </div>
          </ViewData>
        </WhiteCard>
      ) : null}
      {agreements && agreements.length === 0 ? (
        <WhiteCard className="mt-3 mb-3 selected-card">
          No One Time Service Agreement found.
        </WhiteCard>
      ) : (
        <>
          {agreements &&
            agreements.map((agreement) => (
              <AgreementDetailOuterContainer>
                <WhiteCard
                  className="mt-3 mb-3 selected-card"
                  key={agreement.id}
                >
                  <div className="row">
                    <div className="col-lg-9 col-md-8 col-12">
                      <ul className="agreement-type">
                        <li>
                          <p className="black-heading-title text-medium-font  mt-0 mb-0 mr-5">
                            <img
                              width="48px"
                              className="solid-icon"
                              src={ServiceIcon}
                              alt="logo"
                            />
                            {agreement?.channel?.label} One Time Service
                            Agreement
                          </p>
                          <div style={{ display: 'flow-root' }}>
                            <Status
                              type="agreement"
                              icon={
                                !!contractStatusIcons[
                                  agreement?.contract_status?.value
                                ]
                              }
                              fontIcon={
                                contractStatusIcons[
                                  agreement?.contract_status?.value
                                ]
                              }
                              className="mr-2 mt-1 text-medium-font "
                              label={getAgreementName(
                                agreement.contract_status?.value,
                              )}
                              backgroundColor={
                                agreement.contract_status?.value === 'completed'
                                  ? Theme.lightGreen
                                  : Theme.gray8
                              }
                              labelColor={
                                agreement.contract_status?.value === 'completed'
                                  ? Theme.green
                                  : Theme.black
                              }
                            />
                          </div>
                        </li>

                        {generateBasicInfo(
                          'Signed Date',
                          agreement?.contract_signed_date,
                          agreement.contract_status?.value,
                          agreement?.id,
                          '',
                        )}
                        {generateBasicInfo(
                          'Completion Date',
                          agreement?.end_date,
                          agreement.contract_status?.value,
                          agreement?.id,
                          agreement?.contract_signed_date,
                        )}
                      </ul>
                    </div>
                    <div className="clear-fix" />
                    {agreement?.contract_status?.value === 'completed' ||
                    agreement?.contract_status?.value === 'active' ||
                    userRole === 'Customer' ? (
                      <div
                        className="col-lg-3  pl-lg-0 pr-lg-2 col-md-4 col-12 text-right"
                        role="presentation"
                        onClick={() =>
                          localStorage.setItem('agreementID', agreement.id)
                        }
                      >
                        <Link
                          to={{
                            pathname: PATH_AGREEMENT.replace(':id', id).replace(
                              ':contract_id',
                              agreement.id,
                            ),
                            state: history?.location?.search,
                          }}
                        >
                          <Button className="btn-transparent w-100 view-contract">
                            {' '}
                            <img
                              className="file-contract-icon"
                              src={FileContract}
                              alt=""
                            />
                            View Agreement
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <div
                        className="col-lg-3 pl-lg-0   col-md-3 col-12 text-right"
                        role="presentation"
                        onClick={() =>
                          localStorage.setItem('agreementID', agreement.id)
                        }
                      >
                        <ActionDropDown>
                          {' '}
                          <Select
                            classNamePrefix="react-select"
                            placeholder="View Actions"
                            className="active"
                            options={
                              isSpreetailUser
                                ? contractOptionsWithPaused
                                : draftContractOptions
                            }
                            onChange={(event) =>
                              handleContractOptions(event, agreement.id)
                            }
                            components={{
                              DropdownIndicator,
                              Option: IconOption,
                            }}
                            value=""
                          />
                        </ActionDropDown>
                      </div>
                    )}
                    <div className="straight-line horizontal-line pt-3 mb-3" />
                  </div>

                  <div className="DSP-contract-retainer">
                    <div className="label mb-2">One Time Services</div>
                    {agreement?.additional_one_time_services ? (
                      <ul className="selected-list">
                        {displayOneTimeServices(
                          agreement.additional_one_time_services,
                        )}
                      </ul>
                    ) : (
                      <div className="mt-3 ml-3 text-center">
                        No One Time services added.
                      </div>
                    )}
                  </div>
                </WhiteCard>
              </AgreementDetailOuterContainer>
            ))}
        </>
      )}
      <OneTimeAgreementCompletionDateComfirmationModal
        showModal={showCompletionDateModal}
        setShowModal={setShowCompletionDateModal}
        updateAgreement={updateAgreement}
        saveCompletionDateLoader={saveCompletionDateLoader}
      />
    </>
  );
}

OneTimeAgreement.defaultProps = {
  isSpreetailUser: false,
  childBPList: [],
  setSelectedCustomerId: () => {},
  selectedCustomerId: '',
};

OneTimeAgreement.propTypes = {
  isSpreetailUser: bool,
  id: string.isRequired,
  agreements: arrayOf(shape({})).isRequired,
  DropdownIndicator: func.isRequired,
  IconOption: func.isRequired,
  setShowModal: func.isRequired,
  history: shape({
    location: shape({
      pathname: string,
    }),
    push: () => {},
  }).isRequired,
  userRole: string.isRequired,
  childBPList: arrayOf(shape({})),
  setSelectedCustomerId: func,
  selectedCustomerId: string,
};
