/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import dayjs from 'dayjs';
import { func, shape, string } from 'prop-types';
import {
  monthlyRetainerLimit,
  monthlyRetainerLimitInString,
  revShareLimit,
  revShareThresholdLimit,
  revShareThresholdLimitInString,
  monthlyRetainerLimitWalmart,
  monthlyRetainerLimitInStringWalmart,
  revShareLimitWalmart,
  revShareThresholdLimitWalmart,
  revShareThresholdLimitInStringWalmart,
} from '../../../constants';

export default function AgreementAprovalConditions({
  formData,
  newAddendumData,
  setApprovalCondition,
  selectedCurrency,
}) {
  const monthlyRetainerLimitValue =
    formData?.channel?.value === 'amazon'
      ? monthlyRetainerLimit
      : monthlyRetainerLimitWalmart;
  const revShareLimitValue =
    formData?.channel?.value === 'amazon'
      ? revShareLimit
      : revShareLimitWalmart;

  const revShareThresholdLimitValue =
    formData?.channel?.value === 'amazon'
      ? revShareThresholdLimit
      : revShareThresholdLimitWalmart;

  const monthlyRetainerLimitStringValue =
    formData?.channel?.value === 'amazon'
      ? monthlyRetainerLimitInString
      : monthlyRetainerLimitInStringWalmart;

  const revShareThresholdLimitStringValue =
    formData?.channel?.value === 'amazon'
      ? revShareThresholdLimitInString
      : revShareThresholdLimitInStringWalmart;

  // check conditions for retainer values
  const checkCondition = (
    retainerObject,
    accountType,
    period,
    valueLimit,
    valueLimitInString,
    feeTypeStr,
  ) => {
    const retainerArray = [];
    Object.entries(retainerObject).forEach(([key, value]) => {
      if (Number(value) < valueLimit) retainerArray.push(key?.split(' ')[0]);
    });
    if (retainerArray.length)
      return `${accountType} ${feeTypeStr} has ${retainerArray.join(
        ', ',
      )} ${period} value less than ${selectedCurrency}${valueLimitInString}.`;
    return null;
  };

  // check Approval Conditions
  useEffect(() => {
    const approvalConditionsTemp = [];
    const feeStructureTemp = formData?.fee_structure;
    const sellerOrHybrid =
      formData?.seller_type?.value === 'Seller' ||
      formData?.seller_type?.value === 'Hybrid';
    const vendorOrHybrid =
      formData?.seller_type?.value === 'Vendor' ||
      formData?.seller_type?.value === 'Hybrid';
    const contractLengthTemp = Number(
      formData?.length?.value?.match(/(\d+)/)?.[0],
    );
    if (contractLengthTemp < 6) {
      approvalConditionsTemp.push(
        'This Agreement length is less than 6 months.',
      );
    }
    if (formData?.is_dynamic_dsp_fee) {
      if (
        (formData?.dsp_fee && Number(formData?.dsp_fee) < 10000) ||
        (formData?.dsp_fee && Number(formData?.dsp_fee_second) < 10000) ||
        (formData?.dsp_fee && Number(formData?.dsp_fee_third) < 10000)
      ) {
        //   approvalConditionsTemp.push(
        //     `DSP monthly budget is less than ${selectedCurrency}10,000, `,
        //   );
        // } else {
        let message = `DSP monthly budget is less than ${selectedCurrency}10,000`;
        if (formData?.dsp_fee && Number(formData?.dsp_fee) < 10000) {
          message += `, for ${dayjs(
            formData?.dsp_marketplaces?.[0]?.monthly_budget_date,
          ).format('MMMM')} at ${selectedCurrency}${formData?.dsp_fee}`;
        }
        if (
          formData?.dsp_fee_second &&
          Number(formData?.dsp_fee_second) < 10000
        ) {
          message += `, for ${dayjs(
            formData?.dsp_marketplaces?.[0]?.monthly_budget_second_date,
          ).format('MMMM')} at ${selectedCurrency}${formData?.dsp_fee_second}`;
        }
        if (
          formData?.dsp_fee_third &&
          Number(formData?.dsp_fee_third) < 10000
        ) {
          message += `, for ${dayjs(
            formData?.dsp_marketplaces?.[0]?.monthly_budget_third_date,
          ).format('MMMM')} at ${selectedCurrency}${formData?.dsp_fee_third}`;
        }
        message += '.';
        approvalConditionsTemp.push(message);
      }
    } else if (formData?.dsp_fee && Number(formData?.dsp_fee) < 10000) {
      approvalConditionsTemp.push(
        `DSP monthly budget is less than ${selectedCurrency}10,000.`,
      );
    }

    if (formData?.agency_fee?.value < 15) {
      approvalConditionsTemp.push('Agency fees is less than 15%.');
    }
    if (newAddendumData?.addendum && newAddendumData?.addendum !== '<p></p>') {
      approvalConditionsTemp.push('This Agreement has addendum.');
    }
    if (feeStructureTemp?.seller?.billing_cap) {
      approvalConditionsTemp.push('Seller has a billing cap.');
    }
    if (feeStructureTemp?.vendor?.billing_cap) {
      approvalConditionsTemp.push('Vendor has a billing cap.');
    }
    if (
      (feeStructureTemp?.seller?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      Number(feeStructureTemp?.seller?.rev_share) < revShareLimitValue
    ) {
      approvalConditionsTemp.push(
        `Seller revenue share is less than ${revShareLimitValue}.`,
      );
    }
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      Number(feeStructureTemp?.vendor?.rev_share) < revShareLimitValue
    ) {
      approvalConditionsTemp.push(
        `Vendor revenue share is less than ${revShareLimitValue}.`,
      );
    }
    // check for monthly retainer start
    if (
      (feeStructureTemp?.seller?.fee_type === 'Retainer Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      sellerOrHybrid &&
      feeStructureTemp?.seller?.monthly_retainer &&
      Number(feeStructureTemp?.seller?.monthly_retainer) <
        monthlyRetainerLimitValue
    ) {
      approvalConditionsTemp.push(
        `Seller retainer is less than ${selectedCurrency}${monthlyRetainerLimitStringValue}.`,
      );
    }
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Retainer Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      vendorOrHybrid &&
      feeStructureTemp?.vendor?.monthly_retainer &&
      Number(feeStructureTemp?.vendor?.monthly_retainer) <
        monthlyRetainerLimitValue
    ) {
      approvalConditionsTemp.push(
        `Vendor retainer is less than ${selectedCurrency}${monthlyRetainerLimitStringValue}.`,
      );
    }
    if (
      (feeStructureTemp?.seller?.fee_type === 'Retainer Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      sellerOrHybrid &&
      feeStructureTemp?.seller?.quarterly_monthly_retainer
    ) {
      const quartelyConditionSeller = checkCondition(
        feeStructureTemp?.seller?.quarterly_monthly_retainer,
        'Seller',
        'quarterly',
        monthlyRetainerLimitValue,
        monthlyRetainerLimitStringValue,
        'retainer',
      );
      if (quartelyConditionSeller)
        approvalConditionsTemp.push(quartelyConditionSeller);
    }
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Retainer Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      vendorOrHybrid &&
      feeStructureTemp?.vendor?.quarterly_monthly_retainer
    ) {
      const quartelyConditionVendor = checkCondition(
        feeStructureTemp?.vendor?.quarterly_monthly_retainer,
        'Vendor',
        'quarterly',
        monthlyRetainerLimitValue,
        monthlyRetainerLimitStringValue,
        'retainer',
      );
      if (quartelyConditionVendor)
        approvalConditionsTemp.push(quartelyConditionVendor);
    }
    if (
      (feeStructureTemp?.seller?.fee_type === 'Retainer Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      sellerOrHybrid &&
      feeStructureTemp?.seller?.monthly_monthly_retainer
    ) {
      const monthlyConditionSeller = checkCondition(
        feeStructureTemp?.seller?.monthly_monthly_retainer,
        'Seller',
        'Months',
        monthlyRetainerLimitValue,
        monthlyRetainerLimitStringValue,
        'retainer',
      );
      if (monthlyConditionSeller)
        approvalConditionsTemp.push(monthlyConditionSeller);
    }
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Retainer Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      vendorOrHybrid &&
      feeStructureTemp?.vendor?.monthly_monthly_retainer
    ) {
      const monthlyConditionVendor = checkCondition(
        feeStructureTemp?.vendor?.monthly_monthly_retainer,
        'Vendor',
        'Months',
        monthlyRetainerLimitValue,
        monthlyRetainerLimitStringValue,
        'retainer',
      );
      if (monthlyConditionVendor)
        approvalConditionsTemp.push(monthlyConditionVendor);
    }
    // check for monthly retainer end

    // check for revenue share threshold limit - start here
    // checking seller sales threshold
    if (
      (feeStructureTemp?.seller?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      sellerOrHybrid &&
      feeStructureTemp?.seller?.sales_threshold &&
      Number(feeStructureTemp?.seller?.sales_threshold) <
        revShareThresholdLimitValue
    ) {
      approvalConditionsTemp.push(
        `Seller threshold is less than ${selectedCurrency}${revShareThresholdLimitStringValue}.`,
      );
    }

    // checking vendor sales threshold
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      vendorOrHybrid &&
      feeStructureTemp?.vendor?.sales_threshold &&
      Number(feeStructureTemp?.vendor?.sales_threshold) <
        revShareThresholdLimitValue
    ) {
      approvalConditionsTemp.push(
        `Vendor threshold is less than ${selectedCurrency}${revShareThresholdLimitStringValue}.`,
      );
    }

    // checking seller quarterly threshold
    if (
      (feeStructureTemp?.seller?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      sellerOrHybrid &&
      feeStructureTemp?.seller?.quarterly_rev_share
    ) {
      const quartelyConditionSeller = checkCondition(
        feeStructureTemp?.seller?.quarterly_rev_share,
        'Seller',
        'quarterly',
        revShareThresholdLimitValue,
        revShareThresholdLimitStringValue,
        'rev share',
      );
      if (quartelyConditionSeller)
        approvalConditionsTemp.push(quartelyConditionSeller);
    }

    // checking vendor quarterly threshold
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      vendorOrHybrid &&
      feeStructureTemp?.vendor?.quarterly_rev_share
    ) {
      const quartelyConditionVendor = checkCondition(
        feeStructureTemp?.vendor?.quarterly_rev_share,
        'Vendor',
        'quarterly',
        revShareThresholdLimitValue,
        revShareThresholdLimitStringValue,
        'rev share',
      );
      if (quartelyConditionVendor)
        approvalConditionsTemp.push(quartelyConditionVendor);
    }

    // checking seller monthly threshold
    if (
      (feeStructureTemp?.seller?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.seller?.fee_type === 'Retainer + % Rev Share') &&
      sellerOrHybrid &&
      feeStructureTemp?.seller?.monthly_rev_share
    ) {
      const monthlyConditionSeller = checkCondition(
        feeStructureTemp?.seller?.monthly_rev_share,
        'Seller',
        'Months',
        revShareThresholdLimitValue,
        revShareThresholdLimitStringValue,
        'rev share',
      );
      if (monthlyConditionSeller)
        approvalConditionsTemp.push(monthlyConditionSeller);
    }

    // checking vendor monthly threshold
    if (
      (feeStructureTemp?.vendor?.fee_type === 'Revenue Share Only' ||
        feeStructureTemp?.vendor?.fee_type === 'Retainer + % Rev Share') &&
      vendorOrHybrid &&
      feeStructureTemp?.vendor?.monthly_rev_share
    ) {
      const monthlyConditionVendor = checkCondition(
        feeStructureTemp?.vendor?.monthly_rev_share,
        'Vendor',
        'Months',
        revShareThresholdLimitValue,
        revShareThresholdLimitStringValue,
        'rev share',
      );
      if (monthlyConditionVendor)
        approvalConditionsTemp.push(monthlyConditionVendor);
    }
    // check for revenue share threshold limit - end here

    if (formData?.contract_addendum?.length) {
      approvalConditionsTemp.push('This agreement has draft addendum.');
    }
    if (feeStructureTemp?.seller?.fee_type === 'Retainer Only') {
      approvalConditionsTemp.push('Seller is of type Retainer Only.');
    }
    if (feeStructureTemp?.vendor?.fee_type === 'Retainer Only') {
      approvalConditionsTemp.push('Vendor is of type Retainer Only.');
    }

    setApprovalCondition(approvalConditionsTemp);
  }, [
    formData,
    newAddendumData,
    formData.fee_structure,
    setApprovalCondition,
    selectedCurrency,
  ]);

  return null;
}

AgreementAprovalConditions.defaultProps = {
  formData: {},
  newAddendumData: {},
  setApprovalCondition: () => {},
  selectedCurrency: '$ (USD)',
};

AgreementAprovalConditions.propTypes = {
  formData: shape({}),
  newAddendumData: shape({}),
  setApprovalCondition: func,
  selectedCurrency: string,
};
