/* eslint-disable react/no-danger */

import React from 'react';
import { Activity } from '../constants';
import Theme from '../theme/Theme';

export default function useActivityLog({ viewComponent }) {
  const displayMixedLog = (logUser, msg, header) => {
    return msg.map((item, index) => {
      if (index === 0 && header !== '') {
        return (
          <>
            {index === 0 ? logUser : ''}
            <span>updated </span> {header}
          </>
        );
      }
      const field = item && item.split('from')[0];

      let oldValue = item && item?.split('from')?.[1]?.split(' to ')?.[0];
      let newValue =
        item &&
        item.split('from') &&
        item.split('from')[1] &&
        item.split('from')[1].split(' to ') &&
        item.split('from')[1].split(' to ')[1] &&
        item.split('from')[1].split(' to ')[1].split(', ,')[0];

      if (
        item.includes('annual revenue') ||
        item.includes('number of employees') ||
        item.includes('monthly retainer') ||
        item.includes('sales threshold') ||
        item.includes('fee') ||
        item.includes('discount amount') ||
        item.includes('billing cap')
        //  ||
        // item.includes('custom amazon store price')
      ) {
        oldValue = oldValue.replace('.00', '');
        newValue = newValue.replace('.00', '');
        oldValue =
          oldValue && oldValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        newValue =
          newValue && newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return (
        <>
          {index === 0 ? logUser : ''}
          <span>updated {field || ''} from </span> {oldValue || ''}
          <span> to </span> {newValue === '' ? 'None' : newValue}
        </>
      );
    });
  };

  const displayLog = (logUser, field, oldValue, newValue) => {
    return (
      <>
        {logUser || ''}
        <span>updated {field || ''} from </span> {oldValue || ''}
        <span> to </span> {newValue === '' ? 'None' : newValue}
      </>
    );
  };

  const activityDetail = (
    item,
    showAllActivity = false,
    index,
    handleShowMoreLessEvents,
  ) => {
    let activityMessage = '';
    let logUser;
    let field;
    let oldValue;
    let newValue = '';
    let mixedLog = false;
    let customerSetupHeader = '';
    const reason = item?.history_change_reason;
    const showMore = item?.showMore;
    const isAllowForShowMoreAndLess = item?.isAllowForShowMoreAndLess;

    // https://bbe.atlassian.net/browse/PDV-9350 - DSP Notes - Activity Logs and Billing Overlay
    // activity log for dsp notes
    if (reason?.includes('DSP_NOTES_START')) {
      const splittedReason = reason?.split('DSP_NOTES_START');
      const invoiceLog = splittedReason[0];
      const dspNoteLog = splittedReason[1]?.split('added');
      const splittedDspNoteLog = dspNoteLog[1]?.split('DSP_NOTES_END');
      const dspNoteString = splittedDspNoteLog[0];
      const invoiceInfoString = splittedDspNoteLog[1];
      // defining word limit for dsp note as 150
      const dspNoteWordsLimit = 150;

      const wordsToWrap = Activity?.dspSignOfflog.concat(
        Activity?.cancelPauseAggrementLog,
      );

      // Regular expression to match the words
      const regex = new RegExp(`\\b(${wordsToWrap.join('|')})\\b`, 'gi');
      // Replace the matched words with span tags
      const log = invoiceLog?.replace(regex, '<span>$1</span>');
      if (isAllowForShowMoreAndLess) {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: log,
              }}
            />
            <div>
              {dspNoteLog[0]}
              <span>added</span>
              {dspNoteString?.length > dspNoteWordsLimit ? (
                <>
                  {showMore ? (
                    <> {dspNoteString} &nbsp;</>
                  ) : (
                    `${dspNoteString?.slice(0, dspNoteWordsLimit)} .....`
                  )}
                  <span
                    role="presentation"
                    className="cursor"
                    style={{ color: Theme.orange }}
                    onClick={() => {
                      handleShowMoreLessEvents(index);
                    }}
                  >
                    {showMore ? 'Show less' : 'Show more'}
                  </span>
                </>
              ) : (
                dspNoteString
              )}
              {invoiceInfoString}
            </div>
          </>
        );
      }
    }

    // For deleted Activity
    if (item.history_change_reason.includes('deleted')) {
      activityMessage = item.history_change_reason.split('deleted');
      if (item.history_change_reason.includes('deleted note')) {
        return (
          <>
            {activityMessage[0]}
            <span>deleted</span>

            <span
              dangerouslySetInnerHTML={{
                __html:
                  viewComponent === 'activity' && showAllActivity
                    ? activityMessage &&
                      activityMessage.length &&
                      activityMessage[1]
                    : activityMessage &&
                      activityMessage.length &&
                      activityMessage[1] &&
                      activityMessage[1].slice(0, 80),
              }}
            />
            {/* {activityMessage[1]} */}
          </>
        );
      }
      return (
        <>
          {activityMessage[0]}
          <span>deleted</span>
          {activityMessage[1]}
        </>
      );
    }

    // download report activity log
    if (item?.history_change_reason?.includes('downloaded')) {
      const splitMsg = 'downloaded';
      activityMessage = item?.history_change_reason.split(splitMsg);

      return (
        <>
          {activityMessage[0]}
          <span>{splitMsg}</span>
          {activityMessage[1]}
        </>
      );
    }

    // Customised report activity log
    if (item && item.history_change_reason.includes('customized report')) {
      let splitMsg;
      Activity.customizedReportLog.forEach((value) => {
        if (item.history_change_reason.includes(value)) {
          splitMsg = value;
        }
      });

      activityMessage = item.history_change_reason.split(splitMsg);

      return (
        <>
          {activityMessage[0]}
          <span>{splitMsg}</span>
          {activityMessage[1]}
        </>
      );
    }

    // https://bbe.atlassian.net/browse/PDV-9280 - agreement pause request activity log.
    // https://bbe.atlassian.net/browse/PDV-9669 - As an internal user, I should be able to see in the activity logs all transactions related to Walmart

    // below is the new optimized method of formatting the activity reason string.
    if (
      reason?.includes('pausing request') ||
      reason?.includes('pending for pause') ||
      reason?.includes('contract cancellation') ||
      reason?.includes('cancellation request') ||
      reason?.includes('pending cancellation approval') ||
      reason?.includes('cancelled pending for billing') ||
      reason?.includes('contract signed date') ||
      reason?.includes('contract start date') ||
      reason?.includes('contract end date') ||
      reason?.includes('seller type') ||
      reason?.includes('suggested recipient') ||
      reason?.includes('dsp marketplaces ticket created') ||
      reason?.includes('copy optimization') ||
      reason?.includes('updated addendum') ||
      reason?.includes('addendum start date') ||
      reason?.includes('addendum signed date') ||
      reason?.includes('contract addendum signature') ||
      reason?.includes('approved and sent') ||
      reason?.includes('internal approval') ||
      reason?.includes('successfully logged in') ||
      reason?.includes('company name') ||
      reason?.includes('created new record by company name') ||
      reason?.includes('monthly budget distribution') ||
      reason?.includes('one time budget distribution') ||
      reason?.includes('playbook initiative id') ||
      reason?.includes('invoiced walmart customer id') ||
      reason?.includes('customer account type') ||
      reason?.includes('first contract signed') ||
      reason?.includes('retainer invoice creation date') ||
      reason?.includes('updated') ||
      reason?.includes('changed') ||
      reason?.includes('completed step')
    ) {
      // Words to wrap with span tags
      let wordsToWrap = [];
      if (
        reason?.includes('pending for pause') ||
        reason?.includes('cancelled pending for billing')
      ) {
        wordsToWrap = ['changed', 'from', 'to'];
      } else {
        wordsToWrap = [
          'requested',
          'approved and sent',
          'approved',
          'changed',
          'completed',
          'granted',
          'rejected',
          'submitted',
          'updated',
          'for',
          'from',
          'to',
          'go out on',
          'resume on',
          'logged in',
          'created new record by company name',
        ];
      }

      // Regular expression to match the words
      const regex = new RegExp(`\\b(${wordsToWrap.join('|')})\\b`, 'gi');
      // Replace the matched words with span tags
      const log = reason?.replace(regex, '<span>$1</span>');

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: log,
          }}
        />
      );
    }

    if (
      item &&
      item.history_change_reason.includes('updated') &&
      !item.history_change_reason.includes('brand profile note')
    ) {
      // For updated activity without brand profile activity
      activityMessage = item.history_change_reason.split('updated');
      const msg = activityMessage[0];
      logUser = msg;
      if (
        activityMessage[1] &&
        activityMessage[1].includes('Amazon account names and id')
      ) {
        const msg1 = activityMessage[1];
        customerSetupHeader = msg1;
        field = activityMessage[2] && activityMessage[2].split('from')[0];
        oldValue =
          activityMessage[2] &&
          activityMessage[2].split('from')[1].split(' to ')[0];
        newValue =
          activityMessage[2] &&
          activityMessage[2].split('from')[1].split(' to ')[1].split(', ,')[0];
      } else if (
        activityMessage[1]?.includes('DSP detail') ||
        activityMessage[1]?.includes('DSP Advertising Marketplace') ||
        activityMessage[1]?.includes('completion date') ||
        activityMessage[1]?.includes('Vendor central') ||
        activityMessage[1]?.includes('Seller central')
      ) {
        // https://bbe.atlassian.net/browse/PDV-9227 - Capture DSP Detail Company Details Page in Activity Logs
        // Words to wrap with span tags
        const wordsToWrap = ['updated', 'for', 'from', 'to'];
        // Regular expression to match the words
        const regex = new RegExp(`\\b(${wordsToWrap.join('|')})\\b`, 'gi');
        // Replace the matched words with span tags
        const log = item?.history_change_reason?.replace(
          regex,
          '<span>$1</span>',
        );

        return (
          <div
            dangerouslySetInnerHTML={{
              __html: log,
            }}
          />
        );
      } else {
        field = activityMessage[1] && activityMessage[1]?.split('from')?.[0];
        oldValue = activityMessage[1]?.split('from')?.[1]?.split(' to ')?.[0];
        newValue =
          activityMessage[1] &&
          activityMessage[1]?.split('from') &&
          activityMessage[1]?.split('from')[1] &&
          activityMessage[1]?.split('from')[1]?.split(' to ') &&
          activityMessage[1]?.split('from')[1]?.split(' to ')[1] &&
          activityMessage[1]
            ?.split('from')[1]
            ?.split(' to ')[1]
            ?.split(', ,')[0];
      }
      if (activityMessage && activityMessage.length > 2) {
        mixedLog = true;
        activityMessage.shift();
      }

      if (
        !mixedLog &&
        ((item && item.history_change_reason.includes('annual revenue')) ||
          (item &&
            item.history_change_reason.includes('number of employees')) ||
          (item && item.history_change_reason.includes('monthly retainer')) ||
          (item && item.history_change_reason.includes('sales threshold')) ||
          (item && item.history_change_reason.includes('fee')) ||
          (item && item.history_change_reason.includes('discount amount')) ||
          (item &&
            item.history_change_reason.includes('custom amazon store price')) ||
          (item && item.history_change_reason.includes('billing cap')))
      ) {
        let fromAmount = '';
        let toAmount = '';
        let rowAmount = [];
        if (
          activityMessage &&
          activityMessage[1].split(' from ')[1].split(' to ')[0] !== ''
        ) {
          const temp = activityMessage[1].split(' from ')[1].split(' to ')[0];
          rowAmount = temp;
          if (rowAmount.split('.')[1] === '00') {
            const amt = rowAmount.split('.')[0];
            fromAmount = amt;
          } else {
            fromAmount = rowAmount;
          }
        }
        if (
          activityMessage &&
          activityMessage[1].split(' from ')[1].split(' to ')[1] !== ''
        ) {
          const temp = activityMessage[1].split(' from ')[1].split(' to ')[1];
          rowAmount = temp;
          if (rowAmount.split('.')[1] === '00') {
            const amt = rowAmount.split('.')[0];
            toAmount = amt;
          } else {
            toAmount = rowAmount;
          }
        }
        return (
          <>
            {activityMessage && activityMessage[0]}
            <span>
              updated {activityMessage && activityMessage[1].split(' from ')[0]}{' '}
              from{' '}
            </span>{' '}
            {fromAmount === ''
              ? 'None'
              : fromAmount &&
                fromAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            <span> to </span>{' '}
            {toAmount === ''
              ? 'None'
              : toAmount &&
                toAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </>
        );
      }

      return activityMessage && activityMessage[1].includes('addendum')
        ? item.history_change_reason
        : mixedLog
        ? displayMixedLog(logUser, activityMessage, customerSetupHeader)
        : displayLog(logUser, field, oldValue, newValue);
    }

    // For updated activity without brand profile activity
    if (item && item.history_change_reason.includes('brand profile note')) {
      activityMessage = item.history_change_reason.includes('updated')
        ? item.history_change_reason.split('updated')
        : item.history_change_reason.split('created');
      return (
        <>
          {activityMessage && activityMessage[0]}
          {item.history_change_reason.includes('updated') ? (
            <span>updated</span>
          ) : (
            <span>created</span>
          )}
          {activityMessage && activityMessage[1]}
        </>
      );
    }

    // For added Activity
    if (reason?.includes('added') && !reason?.includes('DSP_NOTES_START')) {
      activityMessage = item.history_change_reason.split('added');
      let value;
      if (
        item &&
        item.history_change_reason.includes('Amazon Store Package Custom')
      ) {
        value = activityMessage[1].split('as');
        return (
          <>
            {activityMessage && activityMessage[0]}
            <span>added</span>
            {value && value[0]}
            as
            {value &&
              value[1] &&
              value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </>
        );
      }

      return (
        <>
          {activityMessage && activityMessage[0]}
          <span>added</span>
          {activityMessage && activityMessage[1]}
        </>
      );
    }

    // For removed Activity
    if (item && item.history_change_reason.includes('removed')) {
      activityMessage = item.history_change_reason.split('removed');
      return (
        <>
          {activityMessage && activityMessage[0]}
          <span>removed</span>
          {activityMessage && activityMessage[1]}
        </>
      );
    }
    // For signed Activity
    if (item && item.history_change_reason.includes('signed')) {
      activityMessage = item.history_change_reason.split('signed');
      return (
        <>
          {activityMessage && activityMessage[0]}
          <span>signed</span>
          {activityMessage && activityMessage[1]}
        </>
      );
    }

    // for sent activity
    if (item.history_change_reason.includes('sent')) {
      activityMessage = item.history_change_reason?.split('sent');
      return (
        <>
          {activityMessage[0]}
          <span>sent</span>
          {activityMessage[1]}
        </>
      );
    }
    // for delegated activity
    if (item.history_change_reason.includes('delegated')) {
      activityMessage = item.history_change_reason?.split('delegated');
      return (
        <>
          {activityMessage[0]}
          <span>delegated</span>
          {activityMessage[1]}
        </>
      );
    }
    // for  reallocated escrow balance activity
    if (item.history_change_reason.includes('reallocated')) {
      activityMessage = item.history_change_reason?.split('reallocated');
      [logUser, field] = activityMessage;
      if (field.includes('worth of escrow from')) {
        [oldValue, newValue] = field.split('worth of escrow from');
        const splittedNewValue = newValue.split('to');
        const [oldMonth, newMonth] = splittedNewValue;
        return (
          <>
            {logUser}
            <span>reallocated</span>
            {oldValue}
            <span>worth of escrow from</span>
            {oldMonth}
            <span>to</span>
            {newMonth}.
          </>
        );
      }
      [oldValue, newValue] = field.split('worth of escrow to');
      return (
        <>
          {logUser}
          <span>reallocated</span>
          {oldValue}
          <span>worth of escrow to</span>
          {newValue}.
        </>
      );
    }
    // for payment term activity log
    if (
      item.history_change_reason.includes('has changed the payment terms for')
    ) {
      activityMessage = item.history_change_reason?.split(
        'has changed the payment terms for',
      );
      [logUser, field] = activityMessage;
      [oldValue, newValue] = field.split('invoices to');
      return (
        <>
          {logUser}
          <span>has changed the payment terms for</span>
          {oldValue}
          <span>invoices to</span>
          {newValue}
        </>
      );
    }

    const highLightLogs = Activity.dspSignOfflog
      .concat(Activity.cancelPauseAggrementLog)
      .filter((logItem) => item.history_change_reason.includes(logItem));
    if (
      item.history_change_reason.includes(
        highLightLogs.length > 0
          ? highLightLogs.length === 1
            ? highLightLogs[0]
            : highLightLogs[highLightLogs.length - 1]
          : null,
      )
    ) {
      activityMessage = item.history_change_reason?.split(
        highLightLogs[highLightLogs.length > 0 ? highLightLogs.length - 1 : 0],
      );

      const lastAcitvityMessage = activityMessage[1].split('/n').map((str) => (
        <p>
          {str}
          <br />
        </p>
      ));

      return (
        <>
          {activityMessage[0]}
          <span>
            {
              highLightLogs[
                highLightLogs.length > 0 ? highLightLogs.length - 1 : 0
              ]
            }
          </span>
          {lastAcitvityMessage}
        </>
      );
    }

    return item && item.history_change_reason ? item.history_change_reason : '';
  };

  return activityDetail;
}
