// react imports
import React, { useCallback, useEffect, useState } from 'react';

// third party imports
import { components } from 'react-select';
import { arrayOf, func, shape, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// local file imports
import { CaretUp } from '../../../../theme/images';
import {
  getBpListInAMCAudienceDashboard,
  getManagersList,
  getMarketPlaceList,
} from '../../../../api';
import {
  WhiteCard,
  DropdownIndicator,
  MultiSelectSearchableDropdown,
  SingleSelectDropdown,
  DropdownOptions,
} from '../../../../common';

const AMCDashboardLeftSidePanel = ({
  setSelectedMarketplace,
  selectedMarketplace,
  bindUserListData,
  handleMultiSelectDropdownFilterEvents,
  selectedDSPManager,
  setSelectedDSPManager,
  selectedBp,
  setSelectedBp,
}) => {
  const { SingleValue } = components;
  const defaultMarketPlace = {
    value: 'all',
    label: 'All Marketplaces',
    currency: 'USD',
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [dspManagerList, setDSPManagerList] = useState([]);
  const [dspManageApiList, setDSPManagerApiList] = useState([]);
  const [bpList, setBPList] = useState([]);
  const [bpApiList, setBPApiList] = useState([]);
  const [isManagerUsersSelected, setIsManagerUsersSelected] = useState(false);
  const [isBPSelected, setIsBPSelected] = useState(false);
  const [marketplaceOption, setMarketplaceChoices] = useState([]);
  const [bpListLoader, setBpListLoader] = useState(false);
  const [dspManagerListLoader, setDspManagerListLoader] = useState(false);
  const [marketplaceListLoader, setMarketplaceListLoader] = useState([]);

  // handle marketplace filter events
  const handleMarketplaceOptions = (event) => {
    setSelectedMarketplace(event);
  };

  // react select - single value
  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
    </SingleValue>
  );

  // react select - get dropdown components
  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // render marketplace dropdown filter
  const renderMarketplaceDropdownFilter = () => {
    return (
      <div className="col-lg-12 col-md-6">
        <div className="label mt-3">Marketplace</div>
        <SingleSelectDropdown
          filterId="BT-AmcMarketplaceFilter"
          placeholder={`Select `}
          className="single-select-dropdown"
          isApiCall={marketplaceListLoader}
          dropdownOptions={marketplaceOption}
          selectedValue={selectedMarketplace}
          onChangeHandler={(event) => handleMarketplaceOptions(event)}
          dropdownComponents={getSelectComponents}
          isSearchable={false}
        />
      </div>
    );
  };

  // handle bgs manager/ad team managerfilter events
  const handleManagerUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedDSPManager,
      dspManageApiList,
    );
    setSelectedDSPManager(selectedValues);
    setIsManagerUsersSelected(true);
  };

  // handle bgs manager/ad team manager on dropdwon menu close events
  const handleManagerUserOnMenuCloseEvents = () => {
    if (isManagerUsersSelected) {
      const managerUsers =
        selectedDSPManager?.length > 0 ? selectedDSPManager : dspManagerList;
      setSelectedDSPManager(managerUsers);
    }
  };

  // display dsp manager dropdown filter
  const renderManagerUserDropdown = () => {
    return (
      <div className="col-12 col-md-6 col-lg-12">
        <div className="label mt-3">DSP Manager</div>
        <div id="manager-user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-AmcManagerFilter"
            options={dspManagerList?.filter(
              (option) => option?.value !== 'all',
            )}
            label=""
            value="managerUser"
            multiSelectedValues={{ managerUser: selectedDSPManager }}
            setMultiSelectedValues={setSelectedDSPManager}
            handleMultiSelectChange={handleManagerUserFilterEvents}
            handleOnMenuClose={handleManagerUserOnMenuCloseEvents}
            isLoading={dspManagerListLoader}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // handle bgs manager/ad team managerfilter events
  const handleBPFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedBp,
      bpApiList,
    );
    setSelectedBp(selectedValues);
    setIsBPSelected(true);
  };

  // handle bgs manager/ad team manager on dropdwon menu close events
  const handleBPOnMenuCloseEvents = () => {
    if (isBPSelected) {
      const bps = selectedBp?.length > 0 ? selectedBp : bpList;
      setSelectedBp(bps);
    }
  };

  // display brand partner dropdown filter
  const renderBPDropdown = () => {
    return (
      <div className="col-12 col-md-6 col-lg-12">
        <div className="label mt-3">Brand Partner</div>
        <div id="bp-user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-AmcBpFilter"
            options={bpList?.filter((option) => option?.value !== 'all')}
            label=""
            value="managerUser"
            multiSelectedValues={{ managerUser: selectedBp }}
            setMultiSelectedValues={setSelectedBp}
            handleMultiSelectChange={handleBPFilterEvents}
            handleOnMenuClose={handleBPOnMenuCloseEvents}
            isLoading={bpListLoader}
            isSearchable
          />
        </div>
      </div>
    );
  };

  const getBpListDropdownValues = useCallback(() => {
    setBpListLoader(true);

    getBpListInAMCAudienceDashboard().then((res) => {
      setBpListLoader(false);

      if (res?.status === 200) {
        setBPList(res?.data);
        const tempBPList = res?.data;
        const tempUserListWithAll = [...tempBPList];

        if (tempBPList?.length > 1) {
          tempUserListWithAll.unshift({
            value: 'all',
            label: 'All',
          });
        }
        setSelectedBp(tempUserListWithAll);
        setBPApiList(tempUserListWithAll);
      }
    });
  }, [setSelectedBp]);

  const getManagerUserList = useCallback(
    (role) => {
      setDspManagerListLoader(true);
      getManagersList(role, 'dsp_ad_performance').then((res) => {
        setDspManagerListLoader(false);

        const formatedApiData = bindUserListData(res?.data, role);
        setDSPManagerList(formatedApiData?.userListWithAll);
        setSelectedDSPManager(formatedApiData?.userListWithAll);
        setDSPManagerApiList(formatedApiData?.userList);
      });
    },
    [bindUserListData, setSelectedDSPManager],
  );

  // bind all marketplaces
  const bindAllMarketplaces = useCallback((responseData) => {
    const tempMarketplacesList = [];

    if (responseData?.length > 0) {
      for (const item of responseData) {
        tempMarketplacesList.push({
          value: item?.name,
          label: item?.country,
          currency: item?.currency,
          id: item?.marketplace_id,
        });
      }
    }

    if (tempMarketplacesList?.length > 1) {
      tempMarketplacesList.unshift({
        value: 'all',
        label: 'All Marketplaces',
        currency: 'USD',
      });
    }
    return tempMarketplacesList;
  }, []);

  const getMarketplaces = useCallback(() => {
    setMarketplaceListLoader(true);
    getMarketPlaceList().then((response) => {
      setMarketplaceListLoader(false);
      if (response?.status === 200) {
        if (response?.data?.length) {
          const formatedApiData = bindAllMarketplaces(response?.data);
          setMarketplaceChoices(formatedApiData);
          setSelectedMarketplace(formatedApiData?.[0]);
        }
      }
    });
  }, [bindAllMarketplaces, setSelectedMarketplace]);

  const handleResetFilterOfAmcDashboard = () => {
    const tempUserListWithAll = [...bpList];

    if (bpList?.length > 1) {
      tempUserListWithAll.unshift({
        value: 'all',
        label: 'All',
      });
    }
    setSelectedBp(tempUserListWithAll);
    setSelectedDSPManager(dspManagerList);
    setSelectedMarketplace(defaultMarketPlace);
  };

  // use effect
  useEffect(() => {
    getMarketplaces('amazon');
    getBpListDropdownValues();
    getManagerUserList();
  }, [getBpListDropdownValues, getManagerUserList, getMarketplaces]);

  const renderFilters = () => {
    return (
      <>
        {renderMarketplaceDropdownFilter()}
        {renderManagerUserDropdown()}
        {renderBPDropdown()}
      </>
    );
  };

  return (
    <>
      {' '}
      <WhiteCard
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        className={
          isDesktop ? 'mb-3 d-lg-block d-none' : 'mb-3 d-lg-none d-block'
        }
      >
        <div className="row  ">
          <div className="col-6">
            <div className="black-heading-title ">Filters</div>
          </div>
          <div className={isDesktop ? 'col-6 text-right' : 'col-6 text-right'}>
            <p
              onClick={() => {
                handleResetFilterOfAmcDashboard();
              }}
              role="presentation"
              className="gray-normal-text cursor mt-0"
            >
              Reset filters
              {!isDesktop && (
                <span
                  style={{
                    borderLeft: '1px solid #e2e2ea',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                >
                  <span
                    role="presentation"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCollapseOpen(!isCollapseOpen);
                    }}
                  >
                    <img
                      src={CaretUp}
                      alt="caret"
                      style={{
                        transform: isCollapseOpen ? 'rotate(180deg)' : '',
                        width: '25px',
                        height: '25px',
                        position: 'absolute',
                        right: '5px',
                        top: '-3px',
                      }}
                    />
                  </span>
                </span>
              )}
            </p>
          </div>

          {isDesktop
            ? renderFilters()
            : isCollapseOpen
            ? renderFilters()
            : null}
        </div>
      </WhiteCard>
    </>
  );
};

export default AMCDashboardLeftSidePanel;

AMCDashboardLeftSidePanel.defaultProps = {
  // objects
  data: {},
  selectedMarketplace: {},
  setSelectedMarketplace: () => {},
  bindUserListData: () => {},
  handleMultiSelectDropdownFilterEvents: () => {},
  selectedDSPManager: [],
  setSelectedDSPManager: () => {},
  selectedBp: [],
  setSelectedBp: () => {},
};

AMCDashboardLeftSidePanel.propTypes = {
  selectedMarketplace: shape({}),
  // objects
  data: shape({ sub: string, label: string }),
  setSelectedMarketplace: func,
  bindUserListData: func,
  handleMultiSelectDropdownFilterEvents: func,
  selectedDSPManager: arrayOf(shape({})),
  setSelectedDSPManager: func,
  selectedBp: arrayOf(shape({})),
  setSelectedBp: func,
};
