import React, { useState, useEffect, useCallback, useRef } from 'react';

import Modal from 'react-modal';
import $ from 'jquery';
import dayjs from 'dayjs';
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { DateRange } from 'react-date-range';
import { enGB } from 'react-date-range/src/locale';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { DebounceInput } from 'react-debounce-input';

import Theme from '../../../theme/Theme';

import CustomerListFilters from './CustomerListFilters';
import CustomerListLeftSidePanel from './CustomerListLeftSidePanel';
import { getCustomerDetails } from '../../../store/actions/customerState';
import { CustomerListPage } from './CustomerListStyle';
import {
  ModalBox,
  Button,
  GetInitialName,
  DropdownIndicator,
  GlobalNavbar,
  ModalRadioCheck,
  CheckBox,
  InputSearchWithRadius,
  CustomDropDown,
} from '../../../common';
import {
  PATH_AGREEMENT,
  performanceSortOptions,
  sadSortOptions,
  dadSortOptions,
  sortOptions,
  sortByOrderOptions,
  options,
  timeFrameFilters,
  bpAccountTypes,
  pipelineChoices,
  vendorSalesPerformanceOptions,
  columnsForVendorSalesPerformance,
  bpChannelTypes,
} from '../../../constants';
import {
  CloseIcon,
  InfoIcon,
  SearchIcon,
  // CompanyDefaultUser,
} from '../../../theme/images';
import {
  getCustomerList,
  getGrowthStrategist,
  getStatus,
  getManagersList,
  getSellerType,
  getcontract,
  getAddendumStatus,
} from '../../../api';
import CustomerListRightSidePanel from './CustomerListRightSidePanel';
import {
  contractChoices,
  contractStatus,
} from '../../../constants/FieldConstants';

const salesSortOptions = sortOptions.concat(performanceSortOptions);
const sponsorAdSortOptions = sortOptions.concat(sadSortOptions);
const dspAdSortOptions = sortOptions.concat(dadSortOptions);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '420px ',
    width: '100% ',
    minHeight: '390px',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function CustomerList() {
  const mounted = useRef(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const selectInputRef = useRef();
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [isBGSLoading, setIsBGSLoading] = useState({
    loader: false,
    type: 'options',
  });
  const [allowApiCall, setAllowApiCall] = useState(true);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [pageNumber, setPageNumber] = useState();
  const [brandGrowthStrategist, setBrandGrowthStrategist] = useState([]);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    JSON.parse(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters')).searchQuery
      : '',
  );
  const [status, setStatus] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    view: null,
    'order-by': '-created_at',
  });

  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem('filters')) || {
      status: [],
      contract_status: [],
      cd_user: [],
      user: [],
      ad_user: [],
      dsp_user: [],
      contract_type: [],
      agreement_type: '',
      customer_account_type: [],
      addendum_status: [],
      bpAccount: 'all',
      channel_type: 'all_channels',
    },
  );

  const [showContractDetails, setShowContractDetails] = useState(
    !(
      JSON.parse(localStorage.getItem('filters')) &&
      (JSON.parse(localStorage.getItem('filters')).showPerformance ||
        JSON.parse(localStorage.getItem('filters')).showAdPerformance ||
        JSON.parse(localStorage.getItem('filters')).showDspAdPerformance)
    ),
  );

  const [showPerformance, setShowPerformance] = useState(
    JSON.parse(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters')).showPerformance
      : false,
  );

  const [showAdPerformance, setShowAdPerformance] = useState(
    JSON.parse(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters')).showAdPerformance
      : false,
  );
  const [showDspAdPerformance, setShowDspAdPerformance] = useState(
    JSON.parse(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters')).showDspAdPerformance
      : false,
  );

  const setDefaultVendorDashboard = () => {
    const list = [];
    const selectedColumnsFromLocalstorage =
      JSON.parse(localStorage.getItem('filters'))?.selectedColumns || [];

    selectedColumnsFromLocalstorage.map((item) => list.push(item.value));

    const OrderedRevVendorValues = [
      ...columnsForVendorSalesPerformance.ordered_revenue_tab,
    ].map((item) => item.value);

    const shippedCOGsVendorValues = [
      ...columnsForVendorSalesPerformance.shipped_cogs_tab,
    ].map((item) => item.value);

    const isOrderedRevFieldSelected = list.some((element) => {
      return OrderedRevVendorValues.includes(element);
    });

    const isShippedCOGsFieldSelected = list.some((element) => {
      return shippedCOGsVendorValues.includes(element);
    });

    if (isOrderedRevFieldSelected) {
      return 'ordered_revenue';
    }
    if (isShippedCOGsFieldSelected) {
      return 'shipped_cogs';
    }
    return 'ordered_revenue';
  };

  const [selectedVendorDashboard, setSelectedVendorDashboard] = useState(
    setDefaultVendorDashboard(),
  );

  const [activeVendorTab, setActiveVendorTab] = useState(
    setDefaultVendorDashboard === 'shipped_cogs'
      ? vendorSalesPerformanceOptions[1]
      : vendorSalesPerformanceOptions[0],
  );

  const selectedAccountTypes = JSON.parse(
    localStorage.getItem('filters'),
  )?.customer_account_type;

  const setColumns = () => {
    if (showPerformance) {
      return JSON.parse(localStorage.getItem('filters'))?.selectedColumns
        ? [...JSON.parse(localStorage.getItem('filters')).selectedColumns]
        : selectedAccountTypes?.includes('Vendor')
        ? [...columnsForVendorSalesPerformance?.[activeVendorTab?.value]]
        : [...performanceSortOptions];
    }
    if (showAdPerformance)
      return JSON.parse(localStorage.getItem('filters'))?.selectedColumns
        ? JSON.parse(localStorage.getItem('filters')).selectedColumns
        : [...sadSortOptions];
    if (showDspAdPerformance)
      return JSON.parse(localStorage.getItem('filters'))?.selectedColumns
        ? JSON.parse(localStorage.getItem('filters')).selectedColumns
        : [...dadSortOptions];
    return [];
  };

  const [selectedColumns, setSelectedColumns] = useState(setColumns());

  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    daily_facts: JSON.parse(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters')).daily_facts
      : 'week',
  });

  const [orderByFlag, setOrderByFlag] = useState(
    JSON.parse(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters')).sequence
      : false,
  );
  const [showOrderOption, setShowOrderOption] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const currentDate = new Date();

  const setDefaultDate = (
    customerAccountTypes = filters?.customer_account_type,
    isShowPerformance = showPerformance,
    isShowAdPerformance = showAdPerformance,
    isShowDspAdPerformance = showDspAdPerformance,
  ) => {
    const d = new Date();
    if (isShowPerformance) {
      if (customerAccountTypes?.length) {
        if (customerAccountTypes?.length === 1) {
          if (
            customerAccountTypes?.includes('Seller') ||
            customerAccountTypes?.includes('Hybrid')
          ) {
            d.setDate(d.getDate() - 2);
          }
          if (customerAccountTypes?.includes('Vendor')) {
            d.setDate(d.getDate() - 3);
          }
        } else {
          d.setDate(d.getDate() - 2);
        }
      } else {
        d.setDate(d.getDate() - 2);
      }
    }
    if (isShowAdPerformance) {
      d.setDate(d.getDate() - 2);
    }
    if (isShowDspAdPerformance) {
      d.setDate(d.getDate() - 1);
    }
    return new Date(d);
  };

  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [customDateData, setCustomDateData] = useState([
    {
      startDate: setDefaultDate(),
      endDate: setDefaultDate(),
      key: 'bgsSelection',
    },
  ]);

  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [expiringSoon, setExpiringSoon] = useState(
    !!(
      JSON.parse(localStorage.getItem('filters')) &&
      JSON.parse(localStorage.getItem('filters')).sort_by === 'expiring_soon'
    ),
  );
  const { Option, SingleValue } = components;

  const [accountType, setAccountType] = useState([]);
  const [addendumStatus, setAddendumStatus] = useState([]);
  const [showCustomizeColModal, setShowCustomiseColModal] = useState(false);

  const [isCsv, setIsCsv] = useState(false);

  const params = queryString.parse(history.location.search);

  const CustomDateFilter = (startDate, endDate, type) => {
    let sd = startDate;
    let ed = endDate;

    if (type === 'custom') {
      sd = `${startDate.getDate()}-${
        startDate.getMonth() + 1
      }-${startDate.getFullYear()}`;
      ed = `${endDate.getDate()}-${
        endDate.getMonth() + 1
      }-${endDate.getFullYear()}`;

      setSelectedTimeFrame({
        daily_facts: 'custom',
        start_date: sd,
        end_date: ed,
        startDate,
        endDate,
      });
    }
  };

  const applyCustomDate = (
    startDate = customDateData[0].startDate,
    endDate = customDateData[0].endDate,
  ) => {
    CustomDateFilter(startDate, endDate, 'custom');
    setIsCustomDateApply(true);
    setShowCustomDateModal(false);
  };

  const setMaxDate = () => {
    const d = currentDate;
    if (showPerformance) {
      // PDV-8793 - Update Vendor Sales data to T-3
      const accType = filters?.customer_account_type;
      if (accType?.length === 1 && accType?.[0] === 'Vendor') {
        d.setDate(d.getDate() - 3);
      } else {
        d.setDate(d.getDate() - 2);
      }
    }
    if (showAdPerformance) {
      d.setDate(d.getDate() - 2);
    }
    if (showDspAdPerformance) {
      d.setDate(d.getDate() - 1);
    }

    return d;
  };

  const renderCustomDateModal = () => {
    return (
      <Modal
        isOpen={showCustomDateModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setShowCustomDateModal(false);

            if (selectedTimeFrame?.daily_facts !== 'custom') {
              setCustomDateData([
                {
                  startDate: currentDate,
                  endDate: currentDate,
                  key: 'bgsSelection',
                },
              ]);
            }
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            <h4>Select Date Range</h4>
            <DateRange
              editableDateInputs
              onChange={(item) => {
                setCustomDateData([item.bgsSelection]);
              }}
              ranges={customDateData}
              moveRangeOnFirstSelection={false}
              showDateDisplay={false}
              maxDate={setMaxDate()}
              rangeColors={[Theme.baseColor]}
              weekdayDisplayFormat="EEEEE"
              locale={enGB}
            />
            <div className="text-center mt-3">
              <Button
                onClick={() => applyCustomDate()}
                type="button"
                className="btn-primary on-boarding   w-100"
              >
                Apply
              </Button>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  // function added for PDV-8257 subbrand ticket
  // Purpose of writting this function:- when user directly click on contract from
  // customer list then in customerState object latest child customer is not getting save.
  // It causes different customer object in state and opened contract is of different user
  // and call this function on every click on contract from customer list
  const getCustomer = (selectedCustomerId) => {
    dispatch(getCustomerDetails(selectedCustomerId));
  };

  const customerList = useCallback(
    (currentPage) => {
      if (allowApiCall) {
        setIsLoading({ loader: true, type: 'page' });
        getCustomerList(
          currentPage,
          selectedValue,
          JSON.parse(localStorage.getItem('filters')) || filters,
          searchQuery,
          showContractDetails,
          showPerformance,
          showAdPerformance,
          showDspAdPerformance,
          expiringSoon,
          selectedTimeFrame,
          orderByFlag ? { sequence: 'desc' } : { sequence: 'asc' },
          selectedColumns,
          selectedVendorDashboard,
        ).then((response) => {
          if (mounted.current) {
            setData(response && response.data && response.data.results);
            setPageNumber(currentPage);
            setCount(response && response.data && response.data.count);
            setIsLoading({ loader: false, type: 'page' });
          }
        });
      }
    },
    [
      allowApiCall,
      selectedValue,
      filters,
      searchQuery,
      showContractDetails,
      showPerformance,
      showAdPerformance,
      showDspAdPerformance,
      expiringSoon,
      selectedTimeFrame,
      orderByFlag,
      selectedColumns,
      selectedVendorDashboard,
    ],
  );

  // Download csv report
  useEffect(
    () => {
      if (isCsv) {
        toast.warning('Download might take few minutes.');

        getCustomerList(
          pageNumber || 1,
          selectedValue,
          JSON.parse(localStorage.getItem('filters')) || filters,
          searchQuery,
          showContractDetails,
          showPerformance,
          showAdPerformance,
          showDspAdPerformance,
          expiringSoon,
          selectedTimeFrame,
          orderByFlag ? { sequence: 'desc' } : { sequence: 'asc' },
          selectedColumns,
          selectedVendorDashboard,
          isCsv,
        ).then((response) => {
          if (response?.status === 200) {
            if (response?.data?.message) {
              toast.warning(response?.data?.message);
            }

            if (response?.data?.url)
              window.location.assign(response?.data?.url);
          }
          setIsCsv(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCsv],
  );

  useEffect(() => {
    if (showAdPerformance || showDspAdPerformance) {
      const type = showAdPerformance
        ? 'sponsored_ad_dashboard'
        : 'dsp_ad_performance';
      getManagersList(type).then((adm) => {
        if (mounted.current) {
          if (adm && adm.data) {
            const list = [{ value: 'any', label: 'All' }]; // for select one user
            for (const brand of adm.data) {
              list.push({
                value: brand.id,
                label: `${brand.first_name} ${brand.last_name}`,
                icon:
                  brand.documents &&
                  brand.documents[0] &&
                  Object.values(brand.documents[0]),
              });
            }
            setBrandGrowthStrategist(list);
          }
        }
      });
    } else {
      setIsBGSLoading({ loader: true, type: 'options' });
      getGrowthStrategist().then((gs) => {
        setIsBGSLoading({ loader: false, type: 'options' });

        if (mounted.current) {
          if (gs && gs.data) {
            const list = [{ value: 'any', label: 'All' }]; // for select one use
            for (const brand of gs.data) {
              list.push({
                value: brand.id,
                label: `${brand.first_name} ${brand.last_name}`,
                icon:
                  brand.documents &&
                  brand.documents[0] &&
                  Object.values(brand.documents[0]),
              });
            }
            setBrandGrowthStrategist(list);
          }
        }
      });
    }
  }, [showAdPerformance, showDspAdPerformance]);

  // use effect for addendum status,  partner status and partner account type
  useEffect(() => {
    mounted.current = true;
    getAddendumStatus().then((res) => {
      setAddendumStatus(res?.data);
    });

    getStatus().then((statusResponse) => {
      if (statusResponse && statusResponse.status === 200) {
        setStatus(statusResponse.data);
      }
    });

    getSellerType().then((sellerResponse) => {
      if (sellerResponse && sellerResponse.status === 200) {
        setAccountType(sellerResponse.data);
      }
    });
    return () => {
      mounted.current = false;
    };
  }, []);

  // use effect for customer list data
  useEffect(() => {
    mounted.current = true;

    customerList(1);
    return () => {
      mounted.current = false;
    };
  }, [customerList]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowSortDropdown(false);
    }
  };

  useEffect(() => {
    if (showSortDropdown)
      document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showSortDropdown]);

  const handlePageChange = (currentPage) => {
    localStorage.setItem('page', currentPage || 1);
    setPageNumber(currentPage);
    customerList(currentPage, selectedValue, filters, searchQuery);
  };
  const handleSortFilters = (orderKey, orderByAccountType) => {
    setOrderByFlag(!orderByFlag);
    setExpiringSoon(false);
    const selectedData = {
      ...selectedValue,
      'order-by': orderKey,
      sequence: !orderByFlag,
    };

    if (showAdPerformance) {
      selectedData['order-by-account-type'] = orderByAccountType;
    }

    setSelectedValue({
      ...selectedData,
    });
    const filterData = {
      ...filters,
      sort_by: orderKey,
      sequence: !orderByFlag,
    };

    if (showAdPerformance) {
      filterData['order-by-account-type'] = orderByAccountType;
    }
    setFilters({
      ...filterData,
    });
    localStorage.setItem(
      'filters',
      JSON.stringify({
        ...filterData,
      }),
    );
  };

  const IconOption = (dataProps) => (
    <Option {...dataProps}>
      {/* {props.data.icon ? ( // for multi slect iser */}
      {dataProps.data && dataProps.data.label !== 'All' ? (
        dataProps.data.icon ? ( // for single user select
          <img
            className="drop-down-user"
            src={dataProps.data.icon}
            alt="user"
            style={{
              borderRadius: 50,
              marginRight: '9px',
              height: '28px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <GetInitialName
            userInfo={dataProps.data.label}
            type="list"
            property="mr-2"
          />
        )
      ) : null}
      {dataProps.data.label}
    </Option>
  );

  const IconSingleOption = (dataProps) => (
    <SingleValue {...dataProps}>
      {dataProps.data && dataProps.data.label !== 'All' ? (
        dataProps.data.icon ? (
          <img
            className="drop-down-user"
            src={dataProps.data.icon}
            alt="user"
            style={{
              borderRadius: 50,
              width: '28px',
              verticalAlign: 'middle',
              marginBottom: '',
            }}
          />
        ) : (
          <GetInitialName
            userInfo={dataProps.data.label}
            type="list"
            property=""
          />
        )
      ) : null}
      <span style={{ lineHeight: 0, fontSize: '15px', marginLeft: '8px' }}>
        {dataProps.data.label}
      </span>
    </SingleValue>
  );
  const SortOption = (dataProps) => (
    <SingleValue {...dataProps}>
      {dataProps.data.label === 'Sales Performance' ||
      dataProps.data.label === 'Accounts' ||
      dataProps.data.label === 'Sponsored Ad Performance' ||
      dataProps.data.label === 'DSP Ad Performance'
        ? 'View:'
        : 'Sort by:'}
      &nbsp;
      <span style={{ lineHeight: 0, fontSize: '15px' }}>
        {dataProps.data.label}
      </span>
    </SingleValue>
  );

  const handleSearch = (event, type) => {
    localStorage.setItem('page', 1);
    if (type === 'view') {
      setShowOrderOption(false);
      setExpiringSoon(false);

      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filters,
          sort_by: '-created_at',
        }),
      );
      setSelectedValue({
        ...selectedValue,
        'order-by': '-created_at',
      });
      setFilters({
        ...filters,
        sort_by: '-created_at',
      });

      if (event.value === 'contract_details') {
        setShowContractDetails(true);
        setShowPerformance(false);
        setShowAdPerformance(false);
        setShowDspAdPerformance(false);
        setFilters({
          ...filters,
          showContractDetails: true,
          showPerformance: false,
          showAdPerformance: false,
          showDspAdPerformance: false,
          sort_by: '-created_at',
          sequence: false,
          daily_facts: 'week',
          selectedColumns: [],
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            showContractDetails: true,
            showPerformance: false,
            showAdPerformance: false,
            showDspAdPerformance: false,
            sort_by: '-created_at',
            sequence: false,
            daily_facts: 'week',
            selectedColumns: [],
          }),
        );

        setSelectedColumns([]);
        setShowCustomiseColModal(false);
      } else if (event.value === 'performance') {
        setShowContractDetails(false);
        setShowPerformance(true);
        setShowAdPerformance(false);
        setShowDspAdPerformance(false);
        setSelectedValue({
          ...selectedValue,
          'order-by': '-created_at',
        });
        // PDV-9198 default date and custom range selected logic
        // if daily fact !== custom then set default end date
        // else check if daily fact is custom and end date is > than default date
        // then set end date as default date
        // default date calculated as per t-2(seller) or t-3(vendor) logic for Sponsored Ad
        const salesDefaultDate = setDefaultDate(
          filters?.customer_account_type,
          true,
          false,
          false,
        );

        if (selectedTimeFrame?.daily_facts !== 'custom') {
          setCustomDateData([
            {
              startDate: salesDefaultDate,
              endDate: salesDefaultDate,
              key: 'bgsSelection',
            },
          ]);
        } else if (new Date(customDateData?.[0]?.endDate) > salesDefaultDate) {
          setCustomDateData([
            {
              startDate: customDateData?.[0]?.startDate,
              endDate: salesDefaultDate,
              key: 'bgsSelection',
            },
          ]);
        }

        const filterData = {
          ...filters,
          showContractDetails: false,
          showPerformance: true,
          showAdPerformance: false,
          showDspAdPerformance: false,
          sort_by: '-created_at',
          selectedColumns: [...performanceSortOptions],
        };
        if (
          selectedAccountTypes?.includes('Vendor') &&
          selectedAccountTypes?.length === 1
        ) {
          filterData.selectedColumns =
            columnsForVendorSalesPerformance?.ordered_revenue_tab;
          filterData.vendor_dashboard = 'ordered_revenue';
          setSelectedVendorDashboard('ordered_revenue');
          setActiveVendorTab(vendorSalesPerformanceOptions[0]);
        }
        setFilters({
          ...filterData,
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filterData,
          }),
        );

        setSelectedColumns([...filterData.selectedColumns]);
        setShowCustomiseColModal(false);
      } else if (event.value === 'sponsored_ad_performance') {
        setShowContractDetails(false);
        setShowPerformance(false);
        setShowAdPerformance(true);
        setShowDspAdPerformance(false);
        setSelectedValue({
          ...selectedValue,
          'order-by': '-created_at',
        });

        // PDV-9198 default date and custom range selected logic
        // if daily fact !== custom then set default end date
        // else check if daily fact is custom and end date is > than default date
        // then set end date as default date
        // default date calculated as per t-2 logic for Sponsored Ad

        const spAdDefaultDate = setDefaultDate(
          filters?.customer_account_type,
          false,
          true,
          false,
        );
        if (selectedTimeFrame?.daily_facts !== 'custom') {
          setCustomDateData([
            {
              startDate: spAdDefaultDate,
              endDate: spAdDefaultDate,
              key: 'bgsSelection',
            },
          ]);
        } else if (new Date(customDateData?.[0]?.endDate) > spAdDefaultDate) {
          setCustomDateData([
            {
              startDate: customDateData?.[0]?.startDate,
              endDate: spAdDefaultDate,
              key: 'bgsSelection',
            },
          ]);
        }

        setFilters({
          ...filters,
          showContractDetails: false,
          showPerformance: false,
          showAdPerformance: true,
          showDspAdPerformance: false,
          selectedColumns: [...sadSortOptions],
          sort_by: '-created_at',
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            showContractDetails: false,
            showPerformance: false,
            showAdPerformance: true,
            showDspAdPerformance: false,
            selectedColumns: [...sadSortOptions],
            sort_by: '-created_at',
          }),
        );

        setSelectedColumns([...sadSortOptions]);
        setShowCustomiseColModal(false);
      } else if (event.value === 'dsp_ad_performance') {
        setShowContractDetails(false);
        setShowPerformance(false);
        setShowAdPerformance(false);
        setShowDspAdPerformance(true);
        setSelectedValue({
          ...selectedValue,
          'order-by': '-created_at',
        });

        // PDV-9198 default date and custom range selected logic
        // if daily fact !== custom then set default end date
        // else check if daily fact is custom and end date is > than default date
        // then set end date as default date
        // default date calculated as per t-3 logic for DSP

        const dspDefaultDate = setDefaultDate(
          filters?.customer_account_type,
          false,
          false,
          true,
        );

        if (selectedTimeFrame?.daily_facts !== 'custom') {
          setCustomDateData([
            {
              startDate: dspDefaultDate,
              endDate: dspDefaultDate,
              key: 'bgsSelection',
            },
          ]);
        } else if (new Date(customDateData?.[0]?.endDate) > dspDefaultDate) {
          setCustomDateData([
            {
              startDate: customDateData?.[0]?.startDate,
              endDate: dspDefaultDate,
              key: 'bgsSelection',
            },
          ]);
        }

        setFilters({
          ...filters,
          showContractDetails: false,
          showPerformance: false,
          showAdPerformance: false,
          showDspAdPerformance: true,
          selectedColumns: [...dadSortOptions],
          sort_by: '-created_at',
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            showContractDetails: false,
            showPerformance: false,
            showAdPerformance: false,
            showDspAdPerformance: true,
            selectedColumns: [...dadSortOptions],
            sort_by: '-created_at',
          }),
        );

        setSelectedColumns([...dadSortOptions]);
        setShowCustomiseColModal(false);
      }
    }
    if (type === 'sort') {
      setShowOrderOption(false);
      if (event && event.order) {
        setShowOrderOption(true);
      }
      if (event.value === 'expiring_soon') {
        setExpiringSoon(true);
        setSelectedValue({ ...selectedValue, 'order-by': 'expiring_soon' });
        setFilters({
          ...filters,
          sort_by: event.value,
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            sort_by: event.value,
          }),
        );
      } else {
        setExpiringSoon(false);
        setSelectedValue({ ...selectedValue, 'order-by': event.value });
        setFilters({
          ...filters,
          sort_by: event.value,
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            sort_by: event.value,
          }),
        );
      }
    }
    if (type === 'stats') {
      if (event.value === 'custom') {
        setShowCustomDateModal(true);
      } else {
        setIsCustomDateApply(false);
        setSelectedTimeFrame({ daily_facts: event.value });
        setFilters({
          ...filters,
          daily_facts: event.value,
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            daily_facts: event.value,
          }),
        );
        setCustomDateData([
          {
            startDate: setDefaultDate(),
            endDate: setDefaultDate(),
            key: 'bgsSelection',
          },
        ]);
      }
    }
    if (type === 'search') {
      setTimeout(() => {
        customerList(
          pageNumber,
          selectedValue,
          filters,
          event && event.target && event.target.value ? event.target.value : '',
          showPerformance,
        );
      }, 1000);
    }

    if (type === 'order') {
      setOrderByFlag(event.value);
      setSelectedValue({
        ...selectedValue,
        sequence: event.value,
      });
      setFilters({
        ...filters,
        sequence: event.value,
      });
      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filters,
          sequence: event.value,
        }),
      );
    }
  };

  const setStartEndDateOfFilter = (customerAccountType) => {
    let startDate = customDateData?.[0]?.startDate;
    let endDate = customDateData?.[0]?.endDate;
    const defaultDate = setDefaultDate(customerAccountType);

    if (
      customerAccountType?.includes('Vendor') &&
      customerAccountType?.length === 1
    ) {
      if (startDate > defaultDate) {
        startDate = defaultDate;
      }
      if (endDate > defaultDate) {
        endDate = defaultDate;
      }
    }

    setCustomDateData([
      {
        startDate,
        endDate,
        key: 'bgsSelection',
      },
    ]);
    if (selectedTimeFrame?.daily_facts === 'custom')
      applyCustomDate(new Date(startDate), new Date(endDate));
  };

  // handle customer list filter events
  const handleFilters = (event, key, type, action) => {
    if (action === 'agreement_type') {
      setAllowApiCall(false);
    } else {
      setAllowApiCall(true);
    }

    // for multi select user
    // const handleFilters = (event, key, type) => {
    // for one select user

    // if (key === 'user')
    //   localStorage.setItem(
    //     'filters',
    //     JSON.stringify({
    //       ...filters,
    //       cd_user: event,
    //     }),
    //   );

    if (key === 'user') {
      if (showContractDetails) {
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            cd_user: event?.value,
          }),
        );
      }
      if (showPerformance) {
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            user: event?.value,
          }),
        );
      }
      if (showAdPerformance) {
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            ad_user: event?.value,
          }),
        );
      }
      if (showDspAdPerformance) {
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            dsp_user: event?.value,
          }),
        );
      }
    }
    localStorage.setItem('page', 1);
    if (key === 'unselected') {
      $('.checkboxes input:checkbox').prop('checked', false);
      $('.checkboxes input:radio').prop('checked', false);
      if (selectInputRef && selectInputRef.current)
        selectInputRef.current.select.clearValue();
      // if (selectInputRefMobile && selectInputRefMobile.current)
      //   selectInputRefMobile.current.select.clearValue();
      setSearchQuery('');
      setFilters({
        ...filters,
        status: [],
        contract_status: [],
        contract_type: [],
        agreement_type: '',
        customer_account_type: [],
        cd_user: [],
        user: [],
        ad_user: [],
        dsp_user: [],
        searchQuery: '',
        pipeline: [],
        addendum_status: [],
        bpAccount: 'all',
        channel_type: 'all_channels',
      });
      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filters,
          status: [],
          contract_status: [],
          contract_type: [],
          agreement_type: '',
          customer_account_type: [],
          cd_user: [],
          user: [],
          ad_user: [],
          dsp_user: [],
          searchQuery: '',
          pipeline: [],
          addendum_status: [],
          bpAccount: 'all',
          channel_type: 'all_channels',
        }),
      );
    }

    if (type === 'status' && key !== 'unselected') {
      if (
        event.target.checked &&
        filters.status.indexOf(event.target.name) === -1
      ) {
        setFilters({
          ...filters,
          status: [...filters.status, event.target.name],
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            status: [...filters.status, event.target.name],
          }),
        );
      } else {
        setFilters({
          ...filters,
          status: filters.status.filter((op) => op !== event.target.name),
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            status: filters.status.filter((op) => op !== event.target.name),
          }),
        );
      }
    }

    if (type === 'contract_status' && key !== 'unselected') {
      if (
        event.target.checked &&
        filters.contract_status.indexOf(event.target.name) === -1
      ) {
        setFilters({
          ...filters,
          contract_status: [...filters.contract_status, event.target.name],
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            contract_status: [...filters.contract_status, event.target.name],
          }),
        );
      } else {
        setFilters({
          ...filters,
          contract_status: filters.contract_status.filter(
            (op) => op !== event.target.name,
          ),
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            contract_status: filters.contract_status.filter(
              (op) => op !== event.target.name,
            ),
          }),
        );
      }
    }

    // For Adandum Status
    if (type === 'addendum_status' && key !== 'unselected') {
      if (
        event.target.checked &&
        filters?.addendum_status?.indexOf(event.target.name) === -1
      ) {
        setFilters({
          ...filters,
          addendum_status: [...filters.addendum_status, event.target.name],
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            addendum_status: [...filters.addendum_status, event.target.name],
          }),
        );
      } else {
        setFilters({
          ...filters,
          addendum_status: filters.addendum_status.filter(
            (op) => op !== event.target.name,
          ),
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            addendum_status: filters.addendum_status.filter(
              (op) => op !== event.target.name,
            ),
          }),
        );
      }
    }

    // for account type
    if (type === 'customer_account_type' && key !== 'unselected') {
      if (
        event.target.checked &&
        filters.customer_account_type.indexOf(event.target.name) === -1
      ) {
        const customerAccountType = [
          ...filters.customer_account_type,
          event.target.name,
        ];

        // set default time filters start and end date
        setStartEndDateOfFilter(customerAccountType);

        setSelectedValue({
          ...selectedValue,
          'order-by': '-created_at',
        });

        const filterData = {
          ...filters,
          customer_account_type: [...customerAccountType],
          sort_by: '-created_at',
        };

        if (filters?.showPerformance) {
          if (
            customerAccountType?.includes('Vendor') &&
            customerAccountType?.length === 1
          ) {
            filterData.selectedColumns =
              columnsForVendorSalesPerformance?.ordered_revenue_tab;
            filterData.vendor_dashboard = 'ordered_revenue';
            setSelectedVendorDashboard('ordered_revenue');
            setActiveVendorTab(vendorSalesPerformanceOptions[0]);
          } else {
            filterData.selectedColumns = [...performanceSortOptions];
            filterData.vendor_dashboard = 'ordered_revenue';
            setSelectedVendorDashboard('ordered_revenue');
            setActiveVendorTab(vendorSalesPerformanceOptions[0]);
          }
          setSelectedColumns([...filterData.selectedColumns]);
        }

        if (
          filters?.showAdPerformance &&
          customerAccountType?.includes('Vendor') &&
          customerAccountType?.length === 1
        ) {
          filterData.selectedColumns = selectedColumns?.filter(
            (item) =>
              item?.label !== 'TACOS' && item?.label !== 'Total AD Spend',
          );
          setSelectedColumns([...filterData.selectedColumns]);
        }

        setFilters({
          ...filterData,
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filterData,
          }),
        );
      } else {
        const customerAccountType = filters.customer_account_type.filter(
          (op) => op !== event.target.name,
        );

        // set default time filters start and end date
        setStartEndDateOfFilter(customerAccountType);

        setSelectedValue({
          ...selectedValue,
          'order-by': '-created_at',
        });
        const filterData = {
          ...filters,
          sort_by: '-created_at',

          customer_account_type: filters.customer_account_type.filter(
            (op) => op !== event.target.name,
          ),
        };

        if (
          customerAccountType?.includes('Vendor') &&
          customerAccountType?.length === 1
        ) {
          if (filters?.showPerformance) {
            filterData.selectedColumns =
              columnsForVendorSalesPerformance?.ordered_revenue_tab;
            setSelectedColumns([...filterData.selectedColumns]);
            filterData.vendor_dashboard = 'ordered_revenue';
            setSelectedVendorDashboard('ordered_revenue');
            setActiveVendorTab(vendorSalesPerformanceOptions[0]);
          }

          if (filters?.showAdPerformance) {
            filterData.selectedColumns = selectedColumns?.filter(
              (item) =>
                item?.label !== 'TACOS' && item?.label !== 'Total AD Spend',
            );
            setSelectedColumns([...filterData.selectedColumns]);
          }
        }

        if (
          customerAccountType?.includes('Seller') &&
          customerAccountType?.length === 1
        ) {
          if (filters?.showPerformance) {
            filterData.selectedColumns = performanceSortOptions;
            setSelectedColumns([...filterData.selectedColumns]);
            filterData.vendor_dashboard = '';
          }
        }

        setFilters({
          ...filterData,
        });

        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filterData,
          }),
        );
      }
    }

    // for contract type
    if (type === 'contract_type' && key !== 'unselected') {
      if (
        event.target.checked &&
        filters.contract_type.indexOf(event.target.name) === -1
      ) {
        setFilters({
          ...filters,
          contract_type: [...filters.contract_type, event.target.name],
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            contract_type: [...filters.contract_type, event.target.name],
          }),
        );
      } else {
        setFilters({
          ...filters,
          contract_type: filters.contract_type.filter(
            (op) => op !== event.target.name,
          ),
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            contract_type: filters.contract_type.filter(
              (op) => op !== event.target.name,
            ),
          }),
        );
      }
    }

    // for single user selected
    if (type === 'brand') {
      if (action.action === 'clear') {
        setFilters({
          ...filters,
          cd_user: [],
          user: [],
          ad_user: [],
          dsp_user: [],
        });
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            cd_user: [],
            user: [],
            ad_user: [],
            dsp_user: [],
          }),
        );
      } else {
        if (showContractDetails) {
          if (event.value === 'any') {
            setFilters({
              ...filters,
              cd_user: [],
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,
                cd_user: [],
              }),
            );
          } else {
            setFilters({
              ...filters,

              cd_user: event.value,
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,

                cd_user: event.value,
              }),
            );
          }
        }
        if (showPerformance) {
          if (event.value === 'any') {
            setFilters({
              ...filters,
              user: [],
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,
                user: [],
              }),
            );
          } else {
            setFilters({
              ...filters,
              user: event.value,
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,

                user: event.value,
              }),
            );
          }
        }

        if (showAdPerformance) {
          if (event.value === 'any') {
            setFilters({
              ...filters,
              ad_user: [],
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,
                ad_user: [],
              }),
            );
          } else {
            setFilters({
              ...filters,

              ad_user: event.value,
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,

                ad_user: event.value,
              }),
            );
          }
        }

        if (showDspAdPerformance) {
          if (event.value === 'any') {
            setFilters({
              ...filters,
              dsp_user: [],
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,
                dsp_user: [],
              }),
            );
          } else {
            setFilters({
              ...filters,

              dsp_user: event.value,
            });
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,

                dsp_user: event.value,
              }),
            );
          }
        }
      }
    }

    if (type === 'radio') {
      if (event.target.checked) {
        if (action === 'pipeline') {
          setFilters({
            ...filters,
            pipeline: event.target.value,
          });
          localStorage.setItem(
            'filters',
            JSON.stringify({
              ...filters,
              pipeline: event.target.value,
            }),
          );
        }
        if (action === 'channel_type') {
          setFilters({
            ...filters,
            channel_type: event.target.value,
          });
          localStorage.setItem(
            'filters',
            JSON.stringify({
              ...filters,
              channel_type: event.target.value,
            }),
          );
        }
        if (action === 'bpAccount') {
          setFilters({
            ...filters,
            bpAccount: event.target.value,
          });
          localStorage.setItem(
            'filters',
            JSON.stringify({
              ...filters,
              bpAccount: event.target.value,
            }),
          );
        }
        if (action === 'agreement_type') {
          setFilters({
            ...filters,
            agreement_type: event?.target?.value,
            contract_type: [],
          });
          localStorage.setItem(
            'filters',
            JSON.stringify({
              ...filters,
              agreement_type: event?.target?.value,
              contract_type: [],
            }),
          );
        }
      }
    }
  };

  const getSelectPlaceholder = (item) => {
    if (item === 'user') {
      return 'All';
    }

    if (item === 'sort') {
      if (isDesktop) {
        return 'Sort by: Recently Added';
      }
      return 'Sort by';
    }
    if (item === 'view') {
      if (isDesktop) {
        return 'View: Accounts';
      }
      return 'View';
    }
    if (item === 'stats') {
      if (isDesktop) {
        return 'Stats For: Last 7 days';
      }
      return 'Stats For';
    }
    if (item === 'order') {
      return 'Highest to Lowest';
    }
    return '';
  };

  const renderCustomDateSubLabel = (dataProps) => {
    if (selectedTimeFrame.daily_facts === 'custom' && isCustomDateApply) {
      return `From- ${dayjs(customDateData?.[0]?.startDate).format(
        'D MMM, YYYY',
      )}  To- ${dayjs(customDateData?.[0]?.endDate).format('D MMM, YYYY')}`;
    }

    return dataProps.data.sub;
  };

  const filterOption = (dataProps) => (
    <Option {...dataProps}>
      <div>
        <span style={{ fontSize: '14px' }}>{dataProps.data.label}</span>

        <div style={{ fontSize: '10px' }}>{dataProps.data.sub}</div>
      </div>
    </Option>
  );

  const TimeFrameFilters = (dataProps) => (
    <SingleValue {...dataProps}>
      Stats For:&nbsp;
      <span style={{ lineHeight: 0, fontSize: '14px' }}>
        {dataProps.data.label}
      </span>
      <div style={{ fontSize: '10px' }}>
        {renderCustomDateSubLabel(dataProps)}
      </div>
    </SingleValue>
  );
  const getSelectComponents = (key) => {
    if (key === 'user') {
      return {
        Option: IconOption,
        SingleValue: IconSingleOption,
        DropdownIndicator,
      };
    }
    if (key === 'sort') {
      if (isDesktop) {
        return {
          SingleValue: SortOption,
          DropdownIndicator,
        };
      }

      return DropdownIndicator;
    }
    if (key === 'stats') {
      if (isDesktop) {
        return {
          Option: filterOption,
          SingleValue: TimeFrameFilters,
          DropdownIndicator,
        };
      }
      return DropdownIndicator;
    }
    if (key === 'view') {
      if (isDesktop) {
        return {
          SingleValue: SortOption,
          DropdownIndicator,
        };
      }
      return DropdownIndicator;
    }
    return DropdownIndicator;
  };
  const bindDropDownValue = (item) => {
    if (item === 'user') {
      let selectedUserValue = [];
      if (filters.cd_user && showContractDetails) {
        selectedUserValue = brandGrowthStrategist.filter(
          (option) => filters.cd_user === option.value,
        );
      }
      if (filters.user && showPerformance) {
        return brandGrowthStrategist.filter(
          (option) => filters.user === option.value,
        );
      }
      if (filters.ad_user && showAdPerformance) {
        return brandGrowthStrategist.filter(
          (option) => filters.ad_user === option.value,
        );
      }

      if (filters.dsp_user && showDspAdPerformance) {
        return brandGrowthStrategist.filter(
          (option) => filters.dsp_user === option.value,
        );
      }

      if (!selectedUserValue.length) {
        return [{ value: 'any', label: 'All' }];
      }

      return selectedUserValue;

      // return [{ value: 'any', label: 'Any' }];
      // return brandGrowthStrategist.filter(
      //   (option) => filters.cd_user === option.value,
      // );
    }

    if (item === 'sort') {
      if (!isDesktop) {
        if (showPerformance) {
          return (
            selectedValue[item.key] ||
            salesSortOptions.filter(
              (op) => op.value === selectedValue['order-by'],
            )
          );
        }
        if (showAdPerformance) {
          return (
            selectedValue[item.key] ||
            sponsorAdSortOptions.filter(
              (op) => op.value === selectedValue['order-by'],
            )
          );
        }
        if (showDspAdPerformance) {
          return (
            selectedValue[item.key] ||
            dspAdSortOptions.filter(
              (op) => op.value === selectedValue['order-by'],
            )
          );
        }
        return (
          selectedValue[item.key] ||
          sortOptions.filter((op) => op.value === selectedValue['order-by'])
        );
      }
      return (
        selectedValue[item.key] ||
        sortOptions.filter((op) => op.value === selectedValue['order-by'])
      );
    }

    if (item === 'stats') {
      return timeFrameFilters.filter(
        (op) => op.value === selectedTimeFrame.daily_facts,
      );
    }

    if (item === 'order') {
      return sortByOrderOptions.filter((op) => op.value === orderByFlag);
    }

    if (showPerformance) {
      return [{ value: 'performance', label: 'Sales Performance' }];
    }
    if (showAdPerformance) {
      return [
        {
          value: 'sponsored_ad_performance',
          label: 'Sponsored Ad Performance',
        },
      ];
    }
    if (showDspAdPerformance) {
      return [
        {
          value: 'dsp_ad_performance',
          label: 'DSP Ad Performance',
        },
      ];
    }
    return [{ value: 'contract_details', label: 'Accounts' }];
  };

  const getDropDownOptions = (optionsFor) => {
    switch (optionsFor) {
      case 'user':
        return brandGrowthStrategist;
      case 'sort':
        if (!isDesktop) {
          if (showPerformance) {
            return salesSortOptions;
          }
          if (showAdPerformance) {
            return sponsorAdSortOptions;
          }
          if (showDspAdPerformance) {
            return dspAdSortOptions;
          }
          return sortOptions;
        }
        return sortOptions;
      case 'stats':
        return timeFrameFilters;

      case 'order':
        return sortByOrderOptions;
      default:
        return options;
    }
  };
  const generateDropdown = (item, reff = null) => {
    const searchFor =
      item === 'order'
        ? 'order'
        : item === 'sort'
        ? 'sort'
        : item === 'stats'
        ? 'stats'
        : 'view';
    return (
      <div className={isLoading?.loader ? 'disabled' : ''}>
        <Select
          classNamePrefix="react-select"
          isClearable={false}
          className="active"
          ref={reff}
          placeholder={getSelectPlaceholder(item)}
          options={getDropDownOptions(item)}
          onChange={(event, action) =>
            item === 'user'
              ? handleFilters(event, item, 'brand', action)
              : handleSearch(event, searchFor)
          }
          value={bindDropDownValue(item)}
          // isMulti={item === 'user'}
          isMulti={false}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          components={getSelectComponents(item)}
          componentsValue={item === 'user' ? { Option: IconOption } : ''}
          isLoading={isBGSLoading.loader}
        />
      </div>
    );
  };

  useEffect(() => {
    if (!isDesktop && selectedValue['order-by'] !== null) {
      sortOptions.forEach((element) => {
        if (selectedValue['order-by'] !== element.value) {
          setShowOrderOption(true);
        }
      });
    }
  }, [isDesktop, selectedValue, setShowOrderOption]);

  const redirectIfContractExists = (type, id) => {
    getcontract(type.contract_id).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.contract_url) {
          history.push({
            pathname: `${PATH_AGREEMENT.replace(':id', id).replace(
              ':contract_id',
              type.contract_id,
            )}`,
            state: `${history.location.pathname}`,
          });
        }
      }
    });
  };

  // display input dearch with radius
  const displayInputSearchWithRadius = () => {
    return (
      <InputSearchWithRadius
        id="BT-order-customerlist-search-input"
        className="customer-list-header"
      >
        <DebounceInput
          // minLength={2}
          debounceTimeout={600}
          className="form-control search-filter form-control-info"
          placeholder="Search"
          onChange={(event) => {
            setSearchQuery(event?.target?.value);
            setFilters({
              ...filters,
              searchQuery: event?.target?.value,
            });
            localStorage.setItem('page', 1);
            localStorage.setItem(
              'filters',
              JSON.stringify({
                ...filters,
                searchQuery: event?.target?.value,
              }),
            );
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSearch(event, 'search');
            }
          }}
          value={searchQuery || (filters && filters.searchQuery) || ''}
        />

        <img
          src={InfoIcon}
          alt="search cursor"
          data-tip="Search by Company Name,<br/> Contact First, Last Name or Email"
          data-for="info"
          className="info-icon"
        />

        <ReactTooltip
          className={isDesktop ? 'customer-list-tooltip' : ''}
          id="info"
          aria-haspopup="true"
          place={isDesktop ? 'bottom' : 'left'}
          arrowColor={isDesktop ? 'transparent' : ''}
          style={{ left: isDesktop ? '' : '5%!important' }}
          html
        />

        <img src={SearchIcon} alt="search" className="search-input-icon" />
      </InputSearchWithRadius>
    );
  };

  // display bgs, ad and dsp manager filter
  const displayManagerFilter = () => {
    return (
      <>
        {showAdPerformance ? (
          <div className="label mt-2 mb-2">Sponsored Ad Manager</div>
        ) : showDspAdPerformance ? (
          <div className="label mt-2 mb-2">DSP Ad Manager</div>
        ) : (
          <div className="label mt-2 mb-2">Growth Strategist</div>
        )}
        <CustomDropDown id="BT-user-customerlist-dropdown">
          {generateDropdown('user', selectInputRef)}
        </CustomDropDown>
      </>
    );
  };

  // display radio check filter for various partner parameter types
  const displayRadioCheckFilters = (filterLabel, filterType, view) => {
    const dataArray =
      filterType === 'bpAccount'
        ? bpAccountTypes
        : filterType === 'pipeline'
        ? pipelineChoices
        : filterType === 'channel_type'
        ? bpChannelTypes
        : [];
    return (
      <div>
        <div className="label mt-4 pt-2">{filterLabel}</div>
        <div className="clear-fix" />
        <ul className="check-box-list">
          {dataArray?.map((item) => (
            <li key={item?.value}>
              <ModalRadioCheck>
                <label
                  className="checkboxes radio-container customer-list"
                  htmlFor={item?.value}
                >
                  {item?.label}
                  <input
                    type="radio"
                    name={`radio-${filterType}-${view}`}
                    id={item?.value}
                    value={item?.value}
                    onChange={(event) =>
                      handleFilters(event, item, 'radio', filterType)
                    }
                    checked={filters?.[filterType] === item?.value}
                  />
                  <span className="checkmark checkmark-customer-list" />
                </label>
              </ModalRadioCheck>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // display checkbox filter for various partner parameter types
  const displayPartnerCheckboxesFilter = (filterLabel, filterType) => {
    const dataArray =
      filterType === 'status'
        ? status
        : filterType === 'customer_account_type'
        ? accountType
        : filterType === 'contract_status'
        ? contractStatus
        : filterType === 'addendum_status'
        ? addendumStatus
        : [];

    return (
      <div key={filterType}>
        <div className="label mt-4 pt-2">{filterLabel}</div>
        <div className="clear-fix" />
        <ul className="check-box-list checkboxes">
          {dataArray?.map((item) => {
            const inputId = `${filterType}-${item?.value}`;
            return (
              <li key={inputId}>
                <CheckBox>
                  <label
                    className="check-container customer-pannel"
                    htmlFor={inputId}
                  >
                    {item?.label === 'Pending Contract'
                      ? 'Pending Agreement'
                      : item?.label}
                    <input
                      type="checkbox"
                      id={inputId}
                      name={item?.value}
                      onChange={(event) =>
                        handleFilters(event, item, filterType)
                      }
                      checked={
                        filters?.[filterType]?.find(
                          (op) => op === item?.value,
                        ) || ''
                      }
                    />
                    <span className="checkmark" />
                  </label>
                </CheckBox>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // get contract names
  const getContractNames = () => {
    const filtereData = contractChoices.filter((op) =>
      filters.contract_type.find((ele) => ele === op.value),
    );
    const formattedData = [];
    for (const type of filtereData) {
      formattedData.push(type.label);
    }
    if (formattedData.length > 1) {
      const addAnd = formattedData.pop();
      formattedData.push(`and ${addAnd}`);
      return formattedData?.join(', ')?.replace(', and', ' and');
    }
    return formattedData;
  };

  // display partner agreement types option
  const displayPartnerAgreementTypesOption = (label, key, view) => {
    return (
      <div key={key}>
        <ul className="check-box-list">
          <li key={key}>
            <ModalRadioCheck>
              <label
                className="checkboxes radio-container customer-list"
                htmlFor={key}
              >
                {label}
                <input
                  type="radio"
                  name={`radio-${key}-${view}`}
                  id={key}
                  value={key}
                  onChange={(event) =>
                    handleFilters(event, key, 'radio', 'agreement_type')
                  }
                  checked={filters?.agreement_type === key}
                />
                <span className="checkmark checkmark-customer-list" />
              </label>
            </ModalRadioCheck>
          </li>
        </ul>
        <div
          className={`ml-4 ${
            filters?.agreement_type === key ? '' : 'disabled'
          }`}
        >
          <ul className="check-box-list checkboxes">
            {contractChoices.map((item) => {
              const checkboxId = `${key}-${item?.value}`;
              return (
                <li key={checkboxId}>
                  <CheckBox>
                    <label
                      className="check-container customer-pannel"
                      htmlFor={checkboxId}
                    >
                      {item?.label}
                      <input
                        type="checkbox"
                        id={checkboxId}
                        name={item?.value}
                        onChange={(event) =>
                          handleFilters(event, item, 'contract_type')
                        }
                        checked={
                          filters?.agreement_type === key &&
                          filters?.contract_type?.find(
                            (op) => op === item?.value,
                          )
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </CheckBox>
                </li>
              );
            })}
          </ul>
          <div className="">
            {key === 'multiple' &&
            filters?.agreement_type === key &&
            filters?.contract_type?.length
              ? ` Displaying partners who hold ${getContractNames()} agreement(s).`
              : ''}
            {key === 'individual' &&
            filters?.agreement_type === key &&
            filters?.contract_type?.length
              ? ` Displaying partners who hold any types of agreement(s) selected.`
              : ''}
          </div>
        </div>
      </div>
    );
  };

  // display partner agreement types filters
  const displayPartnerAgreementTypesFilters = (view) => {
    return (
      <div className={isDesktop ? 'mt-4' : ''}>
        <div className="label pt-2">Partner Agreements</div>
        <div className="clear-fix" />
        {displayPartnerAgreementTypesOption(
          'Multiple Agreements',
          'multiple',
          view,
        )}
        {displayPartnerAgreementTypesOption(
          'Individual Agreements',
          'individual',
          view,
        )}
      </div>
    );
  };

  // display customer list desktop view
  const displayCustomerListRightSidePanel = () => {
    return (
      <CustomerListRightSidePanel
        isCustomTimeFrame={selectedTimeFrame?.daily_facts === 'custom'}
        totalPageCount={count}
        pageNumber={pageNumber}
        orderByFlag={orderByFlag}
        showContractDetails={showContractDetails}
        showPerformance={showPerformance}
        showAdPerformance={showAdPerformance}
        showDspAdPerformance={showDspAdPerformance}
        params={params}
        isLoading={isLoading}
        selectedValue={selectedValue}
        selectedColumns={selectedColumns}
        customerListData={data}
        getCustomer={getCustomer}
        handlePageChange={handlePageChange}
        handleSortFilters={handleSortFilters}
        redirectIfContractExists={redirectIfContractExists}
      />
    );
  };

  return (
    <>
      <GlobalNavbar />
      <CustomerListPage>
        <div className="main-body-container">
          {renderCustomDateModal()}
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-2">
              <CustomerListLeftSidePanel
                loader={isLoading?.loader}
                filters={filters}
                handleFilters={handleFilters}
                displayInputSearchWithRadius={displayInputSearchWithRadius}
                displayManagerFilter={displayManagerFilter}
                displayRadioCheckFilters={displayRadioCheckFilters}
                displayPartnerCheckboxesFilter={displayPartnerCheckboxesFilter}
                displayPartnerAgreementTypesFilters={
                  displayPartnerAgreementTypesFilters
                }
              />
            </div>
            <div className="col-12 col-lg-9 col-xl-10">
              <div className="table-container">
                <div className="fixed-customer-header ">
                  <CustomerListFilters
                    loader={isLoading?.loader}
                    handleFilters={handleFilters}
                    filters={filters}
                    generateDropdown={generateDropdown}
                    showContractDetails={showContractDetails}
                    showPerformance={showPerformance}
                    showAdPerformance={showAdPerformance}
                    showDspAdPerformance={showDspAdPerformance}
                    showOrderOption={showOrderOption}
                    selectedColumns={selectedColumns}
                    setSelectedColumns={setSelectedColumns}
                    showCustomizeColModal={showCustomizeColModal}
                    setShowCustomiseColModal={setShowCustomiseColModal}
                    isCsv={isCsv}
                    setIsCsv={setIsCsv}
                    displayInputSearchWithRadius={displayInputSearchWithRadius}
                    displayManagerFilter={displayManagerFilter}
                    displayRadioCheckFilters={displayRadioCheckFilters}
                    displayPartnerCheckboxesFilter={
                      displayPartnerCheckboxesFilter
                    }
                    displayPartnerAgreementTypesFilters={
                      displayPartnerAgreementTypesFilters
                    }
                    selectedVendorDashboard={selectedVendorDashboard}
                    setSelectedVendorDashboard={setSelectedVendorDashboard}
                    activeVendorTab={activeVendorTab}
                    setActiveVendorTab={setActiveVendorTab}
                  />
                </div>

                {displayCustomerListRightSidePanel()}
              </div>
            </div>
          </div>
        </div>
      </CustomerListPage>
    </>
  );
}
