import React from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import { string, bool, func, shape, arrayOf } from 'prop-types';

import {
  PageLoader,
  Button,
  ModalBox,
  InputFormField,
  InfoMsg,
} from '../../../common';
import { CloseIcon } from '../../../theme/images';
import Theme from '../../../theme/Theme';

function AddedServicesEffectivityDatesModal({
  showEffetivityDatesModal,
  onClickOfUpdatePauseContract,
  isLoading,
  setShowEffetivityDatesModal,
  nextStep,
  updatedFormData,
  setUpdatedFormData,
  formData,
  setFormData,
  originalData,
  additionalMarketplaces,
  setShowDiscardModal,
  showDiscardModal,
  checkConditions,
  getObjectDifference,
  getAddedOrRemovedAMCServices,
}) {
  const customStylesForAlert = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '580px ',
      width: '100% ',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const monthlyServiceEnhancedOrAPlus =
    formData?.channel?.value === 'amazon' ? 'A+ Content' : 'Enhanced Content';

  const feeStructureAddendumChanges = (type) => {
    // variable for fee type change from retainer only to retainer +%REv Share
    const feeTypeChangeFromRetainer =
      originalData?.fee_structure?.[type]?.fee_type === 'Retainer Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share';

    // variable for fee type change from rev share only to retainer +%REv Share
    const feeTypechangeFromRevShare =
      originalData.fee_structure?.[type]?.fee_type === 'Revenue Share Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share';

    // if rev share changed.
    const revShareChanged = checkConditions(
      originalData?.fee_structure?.[type]?.rev_share,
      formData?.fee_structure?.[type]?.rev_share,
    );

    // thresholdtype if none
    const thresholdchangedNone =
      formData?.fee_structure?.[type]?.threshold_type === 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== null;

    // threshold fixed type
    const formDataFixedThresholdType =
      (formData?.fee_structure?.[type]?.threshold_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.threshold_type === null) &&
      originalData?.fee_structure?.[type]?.sales_threshold;

    const fixedThresholdType =
      checkConditions(
        formDataFixedThresholdType,
        formData?.fee_structure?.[type]?.sales_threshold,
      ) && formData?.fee_structure?.[type]?.threshold_type === 'Fixed';

    // threshold querterly and monthly
    const quarterlyandMonthlyThresholdType =
      (formData?.fee_structure?.[type]?.threshold_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_rev_share || {},
          formData?.fee_structure?.[type]?.quarterly_rev_share || {},
        )) ||
      (formData?.fee_structure?.[type]?.threshold_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_rev_share || {},
          formData?.fee_structure?.[type]?.monthly_rev_share || {},
        ));

    // if billing cap changed.

    const billingCapchanged = checkConditions(
      originalData?.fee_structure?.[type]?.billing_cap,
      formData?.fee_structure?.[type]?.billing_cap,
    );

    // if billing minimum changed
    const billingMinimumChanged = checkConditions(
      originalData?.fee_structure?.[type]?.billing_minimum,
      formData?.fee_structure?.[type]?.billing_minimum,
    );

    if (
      feeTypeChangeFromRetainer ||
      feeTypechangeFromRevShare ||
      revShareChanged ||
      thresholdchangedNone ||
      fixedThresholdType ||
      quarterlyandMonthlyThresholdType ||
      billingCapchanged ||
      billingMinimumChanged
    ) {
      return true;
    }
    return false;
  };

  const checkFeeStructureContainsRevShare = () => {
    if (formData?.seller_type?.label === 'Hybrid') {
      const sellerChanges = feeStructureAddendumChanges('seller', 'Seller');
      const vendorChanges = feeStructureAddendumChanges('vendor', 'Vendor');
      return sellerChanges || vendorChanges;
    }
    if (formData?.seller_type?.label === 'Seller') {
      return feeStructureAddendumChanges('seller', 'Seller');
    }
    if (formData?.seller_type?.label === 'Vendor') {
      return feeStructureAddendumChanges('vendor', 'Vendor');
    }
    return false;
  };

  const renderModalHeader = () => {
    return (
      <div className="alert-msg pb-3">
        <span>Added Service Effectivity Dates</span>
        <br />
        <small className="my-2" style={{ color: 'red' }}>
          When effectivity date selected is a past date, invoices might have
          already been issued and paid. Please reach out to Finance Team for any
          appropriate action required.
        </small>
        {checkFeeStructureContainsRevShare() ? (
          <>
            <br />
            <small className="my-2" style={{ color: 'red' }}>
              If Rev share effectivity date is selected between 1-10 of the
              current month, please reach out to Finance team to do the
              necessary invoice adjustments.
            </small>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderModalFooter = () => {
    return (
      <>
        <div className="horizontal-line straight-line mt-3 mb-3 " />
        <div className="text-center ">
          <Button
            onClick={() => {
              nextStep();
              onClickOfUpdatePauseContract({ getPauseAgreement: false });
            }}
            type="button"
            className="btn-primary on-boarding  mr-5 pb-2 mb-1"
          >
            {isLoading.loader && isLoading.type === 'page' ? (
              <PageLoader color="#fff" type="button" />
            ) : (
              'Confirm'
            )}
          </Button>
          <Button
            onClick={() => {
              setShowEffetivityDatesModal({
                ...showEffetivityDatesModal,
                show: false,
              });
              setShowDiscardModal({
                ...showDiscardModal,
                show: true,
                clickedBtn: 'discard',
                openAfterEffectivityDateModal: true,
              });
            }}
            type="button"
            className=" btn-transparent w-50 on-boarding  "
          >
            Discard Changes
          </Button>
        </div>
      </>
    );
  };

  const handleData = (field, dateValue, changedService) => {
    if (field?.label === 'Seller Monthly Service') {
      const updatedMarketplaces =
        updatedFormData?.additional_marketplaces?.create?.map((ele) => {
          if (
            ele.account_type === 'Seller' &&
            ele?.name === changedService?.name
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        });

      const updatedServices =
        updatedFormData?.additional_monthly_services?.create?.map((ele) => {
          if (
            ele.account_type === 'Seller' &&
            ((ele?.name && ele?.name === changedService?.name) ||
              (ele?.id && ele?.service?.name === changedService?.service?.name))
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        });

      setUpdatedFormData({
        ...updatedFormData,
        additional_marketplaces: {
          ...updatedFormData?.additional_marketplaces,
          create: updatedMarketplaces,
        },
        additional_monthly_services: {
          ...updatedFormData?.additional_monthly_services,
          create: updatedServices,
        },
      });

      const updatedMarketplacesFormData =
        formData?.additional_marketplaces?.map((ele) => {
          if (
            ele.account_type === 'Seller' &&
            ele?.name === changedService?.name
          ) {
            return { ...ele, effective_date: dateValue };
          }

          return ele;
        });

      const updatedServiceFormData = formData?.additional_monthly_services?.map(
        (ele) => {
          if (
            ele.account_type === 'Seller' &&
            ((ele?.name && ele?.name === changedService?.name) ||
              (ele?.id && ele?.service?.name === changedService?.service?.name))
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        },
      );

      setFormData({
        ...formData,
        additional_marketplaces: updatedMarketplacesFormData,
        additional_monthly_services: updatedServiceFormData,
      });
    }
    if (field?.label === 'Vendor Monthly Service') {
      const updatedMarketplaces =
        updatedFormData?.additional_marketplaces?.create?.map((ele) => {
          if (
            ele.account_type === 'Vendor' &&
            ele?.name === changedService?.name
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        });

      const updatedServices =
        updatedFormData?.additional_monthly_services?.create?.map((ele) => {
          if (
            ele.account_type === 'Vendor' &&
            ((ele?.name && ele?.name === changedService?.name) ||
              (ele?.id && ele?.service?.name === changedService?.service?.name))
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        });

      setUpdatedFormData({
        ...updatedFormData,
        additional_marketplaces: {
          ...updatedFormData?.additional_marketplaces,
          create: updatedMarketplaces,
        },
        additional_monthly_services: {
          ...updatedFormData?.additional_monthly_services,
          create: updatedServices,
        },
      });

      const updatedMarketplacesFormData =
        formData?.additional_marketplaces?.map((ele) => {
          if (
            ele.account_type === 'Vendor' &&
            ele?.name === changedService?.name
          ) {
            return { ...ele, effective_date: dateValue };
          }

          return ele;
        });

      const updatedServiceFormData = formData?.additional_monthly_services?.map(
        (ele) => {
          if (
            ele.account_type === 'Vendor' &&
            ((ele?.name && ele?.name === changedService?.name) ||
              (ele?.id && ele?.service?.name === changedService?.service?.name))
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        },
      );

      setFormData({
        ...formData,
        additional_marketplaces: updatedMarketplacesFormData,
        additional_monthly_services: updatedServiceFormData,
      });
    }

    if (field?.label === 'Listing Optimization') {
      setUpdatedFormData({
        ...updatedFormData,
        listing_optimization_effective_date: dateValue,
      });

      setFormData({
        ...formData,
        listing_optimization_effective_date: dateValue,
      });
    }

    if (field?.label === 'Listing Translation') {
      setUpdatedFormData({
        ...updatedFormData,
        listing_translation_effective_date: dateValue,
      });

      setFormData({
        ...formData,
        listing_translation_effective_date: dateValue,
      });
    }

    if (field?.label === 'Advertising Services') {
      const addedAMCServices = getAddedOrRemovedAMCServices(
        formData?.amc_services,
        originalData?.amc_services,
      );

      if (addedAMCServices?.length) {
        const updatedService = updatedFormData?.amc_services?.create?.map(
          (ele) => {
            if (ele?.name === changedService?.name) {
              return { ...ele, effective_date: dateValue };
            }
            return ele;
          },
        );

        setUpdatedFormData({
          ...updatedFormData,
          amc_services: {
            ...updatedFormData?.amc_services,
            create: updatedService,
          },
        });

        const updatedServiceFormData = formData?.amc_services?.map((ele) => {
          if (ele?.name === changedService?.name) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        });

        setFormData({
          ...formData,
          amc_services: updatedServiceFormData,
        });
      }
    }

    if (field?.label === 'Standard Service') {
      const standardUsServicesUniqueResult =
        formData?.standard_us_services?.filter((obj) => {
          return !originalData?.standard_us_services?.some((obj2) => {
            return obj.name === obj2.name;
          });
        });

      if (standardUsServicesUniqueResult?.length) {
        const updatedService =
          updatedFormData?.standard_us_services?.create?.map((ele) => {
            if (ele?.name === changedService?.name) {
              return { ...ele, effective_date: dateValue };
            }
            return ele;
          });

        setUpdatedFormData({
          ...updatedFormData,
          standard_us_services: {
            ...updatedFormData?.standard_us_services,
            create: updatedService,
          },
        });

        const updatedServiceFormData = formData?.standard_us_services?.map(
          (ele) => {
            if (ele?.name === changedService?.name) {
              return { ...ele, effective_date: dateValue };
            }
            return ele;
          },
        );

        setFormData({
          ...formData,
          standard_us_services: updatedServiceFormData,
        });
      }
    }

    if (field?.label === 'Standard Non-US Service') {
      const updatedService =
        updatedFormData?.standard_non_us_services?.create?.map((ele) => {
          if (
            ele?.name === changedService?.name &&
            ele?.marketplace === changedService?.marketplace
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        });

      setUpdatedFormData({
        ...updatedFormData,
        standard_non_us_services: {
          ...updatedFormData?.standard_non_us_services,
          create: updatedService,
        },
      });

      const updatedServiceFormData = formData?.standard_non_us_services?.map(
        (ele) => {
          if (
            ele?.name === changedService?.name &&
            ele?.marketplace === changedService?.marketplace
          ) {
            return { ...ele, effective_date: dateValue };
          }
          return ele;
        },
      );

      setFormData({
        ...formData,
        standard_non_us_services: updatedServiceFormData,
      });
    }

    if (field?.label === 'Seller Fee Structure') {
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          seller: {
            ...updatedFormData?.fee_structure?.seller,
            effective_date: dateValue,
          },
        },
      });

      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          seller: {
            ...formData?.fee_structure?.seller,
            effective_date: dateValue,
          },
        },
      });
    }

    if (field?.label === 'Vendor Fee Structure') {
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          vendor: {
            ...updatedFormData?.fee_structure?.vendor,
            effective_date: dateValue,
          },
        },
      });

      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          vendor: {
            ...formData?.fee_structure?.vendor,
            effective_date: dateValue,
          },
        },
      });
    }
  };

  const setFieldDate = (dateValue, field, index, changedService) => {
    const formattedDate = dayjs(dateValue).format('YYYY-MM-DD');
    handleData(field, formattedDate, changedService);
  };

  const setDate = (field, changedService) => {
    if (field?.label === 'Listing Optimization') {
      return formData?.listing_optimization_effective_date
        ? new Date(formData?.listing_optimization_effective_date)
        : '';
    }

    if (field?.label === 'Listing Translation') {
      return formData?.listing_translation_effective_date
        ? new Date(formData?.listing_translation_effective_date)
        : new Date();
    }

    if (field?.label === 'DSP Advertising') {
      return formData?.dsp_start_date ? new Date(formData?.dsp_start_date) : '';
    }

    if (field?.label === 'Standard Service') {
      return formData?.standard_us_services?.find(
        (item) => item?.name === changedService?.name,
      )?.effective_date
        ? new Date(
            formData?.standard_us_services?.find(
              (item) => item?.name === changedService?.name,
            )?.effective_date,
          )
        : new Date();
    }
    if (field?.label === 'Advertising Services') {
      return formData?.amc_services?.find(
        (item) => item?.name === changedService?.name,
      )?.effective_date
        ? new Date(
            formData?.amc_services?.find(
              (item) => item?.name === changedService?.name,
            )?.effective_date,
          )
        : new Date();
    }

    if (field?.label === 'Standard Non-US Service') {
      return formData?.standard_non_us_services?.find((item) => {
        if (item?.name === changedService?.name) {
          if (item?.marketplace === changedService?.marketplace) {
            return item;
          }
        }
        return null;
      })?.effective_date
        ? new Date(
            formData?.standard_non_us_services?.find((item) => {
              if (item?.name === changedService?.name) {
                if (item?.marketplace === changedService?.marketplace) {
                  return item;
                }
              }
              return null;
            })?.effective_date,
          )
        : new Date();
    }
    if (field?.label === 'Seller Fee Structure') {
      return formData?.fee_structure?.seller?.effective_date
        ? new Date(formData?.fee_structure?.seller?.effective_date)
        : new Date();
    }
    if (field?.label === 'Vendor Fee Structure') {
      return formData?.fee_structure?.vendor?.effective_date
        ? new Date(formData?.fee_structure?.vendor?.effective_date)
        : new Date();
    }

    if (field?.label === 'Seller Monthly Service') {
      const monthlyMarketplaceService =
        formData?.additional_marketplaces?.filter(
          (item) =>
            item?.account_type === 'Seller' &&
            item?.name === changedService?.name,
        );

      const monthlyService =
        updatedFormData?.additional_monthly_services?.create?.filter((item) => {
          if (item?.account_type === 'Seller') {
            if (item?.id) {
              if (item?.service?.name === changedService?.service?.name) {
                return item;
              }
            } else if (item?.name && item?.name === changedService?.name) {
              return item;
            }
          }
          return null;
        });

      if (monthlyMarketplaceService?.length) {
        return monthlyMarketplaceService?.[0]?.effective_date
          ? new Date(monthlyMarketplaceService?.[0]?.effective_date)
          : new Date();
      }

      if (monthlyService?.length) {
        return monthlyService?.[0]?.effective_date
          ? new Date(monthlyService?.[0]?.effective_date)
          : new Date();
      }
    }

    if (field?.label === 'Vendor Monthly Service') {
      const monthlyMarketplaceService =
        formData?.additional_marketplaces?.filter(
          (item) =>
            item?.account_type === 'Vendor' &&
            item?.name === changedService?.name,
        );

      const monthlyService =
        updatedFormData?.additional_monthly_services?.create?.filter((item) => {
          if (item?.account_type === 'Vendor') {
            if (item?.id) {
              if (item?.service?.name === changedService?.service?.name) {
                return item;
              }
            } else if (item?.name === changedService?.name) {
              return item;
            }
          }
          return null;
        });

      if (monthlyMarketplaceService?.length) {
        return monthlyMarketplaceService?.[0]?.effective_date
          ? new Date(monthlyMarketplaceService?.[0]?.effective_date)
          : new Date();
      }

      if (monthlyService?.length) {
        return monthlyService?.[0]?.effective_date
          ? new Date(monthlyService?.[0]?.effective_date)
          : new Date();
      }
    }
    return null;
  };

  const setMinDate = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
  };

  const displayDateInputBox = (field, index, changedService) => {
    return (
      <InputFormField>
        <EffectivityDateModalDatePicker>
          <DatePicker
            disabled={field?.label === 'DSP Advertising'}
            className="form-control zero-height-padding"
            value={setDate(field, changedService)}
            onChange={(event) => {
              setFieldDate(event, field, index, changedService);
            }}
            format="MM/dd/yyyy"
            minDate={setMinDate()}
            clearIcon={null}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            placeholderText="Select Date"
          />
        </EffectivityDateModalDatePicker>
      </InputFormField>
    );
  };

  const displayMarketplaceName = (value, type, side) => {
    const marketplaceArray = additionalMarketplaces;

    const filteredMarketplace = marketplaceArray?.find(
      (op) => value === op.value,
    )?.label;
    return side === 'lhs'
      ? filteredMarketplace
        ? `${filteredMarketplace} Marketplace Management`
        : ''
      : filteredMarketplace;
  };

  const displayServiceLabel = (field) => {
    return (
      <>
        <div
          className={`${
            field?.data?.length ? '' : 'col-6'
          }  liner-titles spacing normal-text-black mt-4 mb-3`}
          style={{ color: Theme.gray60 }}
        >
          {field?.label}
        </div>
      </>
    );
  };
  const displayServiceName = (key, field) => {
    if (key?.name === 'VAT Registration' || key?.name === 'VAT Filing') {
      return `${key?.name} (${displayMarketplaceName(
        key?.marketplace,
        'vat',
        '',
      )})`;
    }
    if (
      field?.label === 'Seller Monthly Service' ||
      field?.label === 'Vendor Monthly Service'
    ) {
      return `${
        key?.name?.includes('.')
          ? displayMarketplaceName(key?.name, 'vat', '')
          : key?.name
          ? key?.name
          : key?.service?.name
      } ${
        key?.name === monthlyServiceEnhancedOrAPlus ||
        key?.service?.name === monthlyServiceEnhancedOrAPlus
          ? `(${key?.quantity})`
          : ''
      }
      `;
    }
    return `${key?.name ? key?.name : key?.service?.name}`;
  };

  const displayServices = (field) => {
    return field?.data?.map((key, index) => {
      return (
        <>
          <div className="col-6  ">{displayServiceName(key, field)}</div>
          <div className="col-6 text-right ">
            {' '}
            {displayDateInputBox(field, index, key)}
          </div>
        </>
      );
    });
  };

  const displayListingServices = (field) => {
    return field?.fieldSet?.map((item) => {
      return (
        <div className="col-12 mb-3">
          {item?.name}({item?.asin})
        </div>
      );
    });
  };

  const renderMainSetOfServices = () => {
    return showEffetivityDatesModal?.data?.length ? (
      showEffetivityDatesModal?.data?.map((field, index) => {
        return (
          <div key={field?.label}>
            {' '}
            <div className={`${field?.data?.length ? '' : 'row'}`}>
              {displayServiceLabel(field)}

              {field?.data?.length ? (
                ''
              ) : (
                <div className="col-6 text-right ">
                  {' '}
                  {displayDateInputBox(field, index, '')}
                </div>
              )}
              {field?.fieldSet?.length
                ? field?.label === 'Listing Optimization' ||
                  field?.label === 'Listing Translation'
                  ? displayListingServices(field)
                  : ''
                : ''}
            </div>
            {field?.data?.length ? (
              <div className="row"> {displayServices(field, index)}</div>
            ) : (
              ''
            )}
          </div>
        );
      })
    ) : (
      <InfoMsg className="mt-2 mb-2 text-center">
        You have not added any new service but removed
      </InfoMsg>
    );
  };

  return (
    <Modal
      isOpen={showEffetivityDatesModal.show}
      style={customStylesForAlert}
      ariaHideApp={false}
      contentLabel="Edit modal"
    >
      <img
        src={CloseIcon}
        alt="close"
        className="float-right cursor cross-icon"
        onClick={() =>
          setShowEffetivityDatesModal({
            ...showEffetivityDatesModal,
            show: false,
          })
        }
        role="presentation"
      />
      <ModalBox>
        <div className="modal-body ">
          {renderModalHeader()}
          <div className="addendum-service-modal">
            {renderMainSetOfServices()}
          </div>
          {renderModalFooter()}
        </div>
      </ModalBox>
    </Modal>
  );
}

export default AddedServicesEffectivityDatesModal;

AddedServicesEffectivityDatesModal.defaultProps = {
  showEffetivityDatesModal: {},
  isLoading: {},
  updatedFormData: {},
  formData: {},
  setShowEffetivityDatesModal: () => {},
  onClickOfUpdatePauseContract: () => {},
  nextStep: () => {},
  setUpdatedFormData: () => {},
  setFormData: () => {},
  setShowDiscardModal: () => {},
  checkConditions: () => {},
  getObjectDifference: () => {},
  showDiscardModal: {},
  originalData: {},
  additionalMarketplaces: [],
  getAddedOrRemovedAMCServices: () => {},
};

AddedServicesEffectivityDatesModal.propTypes = {
  updatedFormData: shape({}),
  formData: shape({}),
  showEffetivityDatesModal: shape({
    show: bool,
  }),
  isLoading: shape({
    loader: bool,
    type: string,
  }),
  onClickOfUpdatePauseContract: func,
  setShowEffetivityDatesModal: func,
  nextStep: func,
  setUpdatedFormData: func,
  setFormData: func,
  additionalMarketplaces: arrayOf(shape({})),
  setShowDiscardModal: func,
  showDiscardModal: shape({}),
  checkConditions: func,
  getObjectDifference: func,
  originalData: shape({}),
  getAddedOrRemovedAMCServices: func,
};

const EffectivityDateModalDatePicker = styled.div`
  .react-calendar {
    position: fixed;
    left: 23%;
    top: 30%;
  }
`;
