import axiosInstance from '../axios';
import {
  API_BGS,
  API_CHOICES,
  API_SERVICE_TYPE,
  API_MARKETPLACES,
  API_ALL_MARKETPLACES,
  API_ADM,
  API_THRESHOLD_TYPE,
  API_YOY_PERCENTAGE,
  API_PAYMENT_TERMS_OPTIONS,
  API_STANDARD_NON_US_SERVICES_OPTIONS,
  API_NON_US_MARKETPLACES,
  API_STANDARD_NON_US_SERVICES_FREQUENCY,
  API_GOAL_ALL_MARKETPLACES,
  API_MIN_DATE_DASHBOARD,
  API_DSP_MARKETPLACES,
} from '../constants/ApiConstants';

export async function getStatus() {
  const result = await axiosInstance
    .get(`${API_CHOICES}status`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCountry() {
  const result = await axiosInstance
    .get(`${API_CHOICES}country`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getState(country) {
  const result = await axiosInstance
    .get(`${API_CHOICES}country/${country}/state`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCategories() {
  const result = await axiosInstance
    .get(`${API_CHOICES}category`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getChannels() {
  const result = await axiosInstance
    .get(`${API_CHOICES}channel`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getSellerType() {
  const result = await axiosInstance
    .get(`${API_CHOICES}seller-type`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getRoles() {
  const result = await axiosInstance
    .get(`${API_CHOICES}role`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getContactRoles() {
  const result = await axiosInstance
    .get(`${API_CHOICES}contact-role`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get api data for advertising users
export async function getManagersList(role, dashboard, id = null) {
  let params = {
    'order-by': 'first_name',
    dashboard,
  };
  if (role === 'Ad Manager' && id !== null) {
    params = {
      ...params,
      ad_team_manager: id,
    };
  }

  if (role === 'Ad Team Manager') {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }

  const result = await axiosInstance
    .get(`${API_ADM}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get api data for bgs users
export async function getBgsUserList(role, id = null) {
  let params = {
    'order-by': 'first_name',
  };

  if (role === 'BGS' && id !== null) {
    params = {
      ...params,
      bgs_manager: id,
    };
  }
  if (role === 'BGS Manager') {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }

  const result = await axiosInstance
    .get(`${API_BGS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getGrowthStrategist() {
  const result = await axiosInstance
    .get(`${API_BGS}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getOneTimeService(channel) {
  const apiUrl =
    channel === 'amazon'
      ? `${API_CHOICES}onetime-services`
      : `${API_CHOICES}onetime-services-walmart`;
  const result = await axiosInstance
    .get(`${apiUrl}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getMonthlyService() {
  const result = await axiosInstance
    .get(`${API_CHOICES}monthly-services`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getRevenueShare() {
  const result = await axiosInstance
    .get(`${API_CHOICES}rev-share`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getLength() {
  const result = await axiosInstance
    .get(`${API_CHOICES}contract-length`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getMarketplaces(channel) {
  const apiUrl =
    channel === 'amazon'
      ? `${API_CHOICES}marketplace-types`
      : `${API_CHOICES}marketplace-types-walmart`;
  const result = await axiosInstance
    .get(`${apiUrl}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAdditionalServices(type) {
  const params = { service_type: type };
  const result = await axiosInstance
    .get(API_SERVICE_TYPE, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getContractStatus() {
  const result = await axiosInstance
    .get(`${API_CHOICES}contract-status`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export const getMarketPlaceList = async (id, goalFlag) => {
  let response;
  const params = {};
  if (id) {
    params.customer_id = id;
  }
  if (goalFlag) {
    params.goal = true;
  }

  if (id === undefined) {
    response = await axiosInstance.get(API_ALL_MARKETPLACES);
  } else {
    response = await axiosInstance.get(API_MARKETPLACES, {
      params: { ...params },
    });
  }
  return response;
};

// async function for dsp marketplaces
export const getDspMarketPlacesList = async (id) => {
  const params = {
    customer_id: id,
  };

  const result = await axiosInstance
    .get(API_DSP_MARKETPLACES, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
  return result;
};

export const getGoalsMarketPlaceList = async (customerId, accountType) => {
  const params = { customer_id: customerId, account_type: accountType };

  const response = await axiosInstance.get(API_GOAL_ALL_MARKETPLACES, {
    params: { ...params },
  });
  return response;
};

// PDV-7522 and PDV-7818
// get BPs all marketplace active and inactive
export const getBPsAllMArketplaceList = async (customerId) => {
  const params = { customer_id: customerId, show_all: true };

  const result = await axiosInstance
    .get(API_MARKETPLACES, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
  return result;
};

export async function getAdManagers() {
  const result = await axiosInstance
    .get(`${API_ADM}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getThresholdType() {
  const result = await axiosInstance
    .get(`${API_CHOICES + API_THRESHOLD_TYPE}`)
    .then((response) => {
      return response && response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getYoyPercentage() {
  const result = await axiosInstance
    .get(`${API_CHOICES + API_YOY_PERCENTAGE}`)
    .then((response) => {
      return response && response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getPaymentTermsOptions() {
  const result = await axiosInstance
    .get(`${API_PAYMENT_TERMS_OPTIONS}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCurrencyOptions() {
  const result = await axiosInstance
    .get(`${API_CHOICES}currency`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getStandardServices(channel) {
  const apiUrl =
    channel === 'amazon'
      ? `${API_CHOICES}standard-services`
      : `${API_CHOICES}standard-services-walmart`;
  const result = await axiosInstance
    .get(`${apiUrl}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getNonUSStandardServicesOptions() {
  const result = await axiosInstance
    .get(`${API_STANDARD_NON_US_SERVICES_OPTIONS}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getNonUSStandardServicesFrequency() {
  const result = await axiosInstance
    .get(`${API_STANDARD_NON_US_SERVICES_FREQUENCY}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getNonUSStandardServicesMarketplaces() {
  const result = await axiosInstance
    .get(`${API_NON_US_MARKETPLACES}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAgencyFee() {
  const result = await axiosInstance
    .get(`${API_CHOICES}agency-fee`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAddendumStatus() {
  const result = await axiosInstance
    .get(`${API_CHOICES}addendum-status`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
}

export async function getMinDateForDashboard(
  marketplace,
  manager,
  user,
  sponsoredType,
  dashboardType,
) {
  let params = {
    marketplace,
    user,
    dashboard_type: dashboardType,
  };

  if (sponsoredType !== null) {
    params = {
      ...params,
      sponsored_type: sponsoredType,
    };
  }

  if (manager !== null) {
    params = {
      ...params,
      bgs_manager: manager,
    };
  }

  const result = await axiosInstance
    .get(`${API_MIN_DATE_DASHBOARD}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
