import React, { useState, useEffect, useCallback } from 'react';

import Modal from 'react-modal';
import ReadMoreAndLess from 'react-read-more-less';
import { useSelector } from 'react-redux';
import { shape, arrayOf, string, bool, func } from 'prop-types';

import EditCompanyDetails from './EditCompanyDetails';
import AmazonAccount from './AmazonAccount';
import { GroupUser } from '../../../theme/Global';
import { SpreetailRole, socialIcons } from '../../../constants';
import { EditOrangeIcon, CloseIcon } from '../../../theme/images';
import {
  getCustomerDetails,
  getCustomerContactDetails,
  getMarketplaces,
  getAccountMarketplace,
  getSPData,
} from '../../../api';
import {
  GetInitialName,
  PageLoader,
  WhiteCard,
  DropdownIndicator,
  ViewData,
  SingleSelectDropdown,
} from '../../../common';

export default function CompanyDetail({
  id,
  isCustomer,
  isAuthorized,
  childBPList,
  setIsAuthorized,
}) {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      minHeight: '200px',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const [detail, setDetail] = useState();
  const [contactInfo, setContactInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceData, setMarketplaceData] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );

  useEffect(() => {
    if (childBPList?.length) setSelectedCustomerId(childBPList?.[0]?.value);
  }, [childBPList]);

  const getContactData = useCallback(() => {
    getCustomerContactDetails(selectedCustomerId).then((res) => {
      setContactInfo(res?.data);
      setIsLoading(false);
    });
  }, [selectedCustomerId]);

  const customerDetails = useCallback(() => {
    setIsLoading(true);
    getCustomerDetails(selectedCustomerId).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data);
        getContactData();
        getSPData(selectedCustomerId).then((response) => {
          if (response?.status === 200) setIsAuthorized(response?.data);
        });
      }
    });
  }, [selectedCustomerId, getContactData, setIsAuthorized]);

  useEffect(() => {
    getAccountMarketplace(selectedCustomerId).then((response) => {
      setMarketplaceData(response?.data);
      setIsLoading({ loader: false, type: 'page' });
    });
  }, [selectedCustomerId]);

  useEffect(() => {
    getMarketplaces('amazon').then((market) => {
      setMarketplaceList(market.data);
    });
  }, []);

  useEffect(() => {
    customerDetails();
  }, [selectedCustomerId, getContactData, customerDetails]);

  const generateSocialIcon = (item) => {
    const fields = [];
    if (detail && detail[item.key]) {
      fields.push(
        <li key={detail && detail[item.key]}>
          <a
            href={detail && detail[item.key]}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={item.value}
              alt="brand"
              className={
                item.key === 'instagram' || item.key === 'pinterest'
                  ? 'social-icon-width'
                  : 'social-media'
              }
            />
          </a>
        </li>,
      );
    }
    if (fields && fields.length === 0) {
      return '';
    }
    return fields;
  };

  const getContactCard = () => {
    return (
      <WhiteCard className=" mt-3">
        <p className="black-heading-title card-title  mt-0 ">
          Company Contacts
        </p>
        {!isSpreetailUser ? (
          <div
            className="edit-details"
            onClick={() => {
              setShowModal(true);
              setScrollDown(true);
            }}
            role="presentation"
          >
            <img src={EditOrangeIcon} alt="" />
            Edit
          </div>
        ) : null}
        <div className="company-contract-height">
          {contactInfo && contactInfo.length === 0
            ? 'No Record found.'
            : contactInfo.map((item) => (
                <GroupUser key={item.id} className="mb-3">
                  <GetInitialName userInfo={item} property="float-left mr-3 " />
                  <div className="activity-user">
                    {item.first_name || ' '} {item.last_name || ' '}{' '}
                    {item.role ? <>({item.role})</> : ''}
                    <br />
                    <div className="user-email-address">
                      {item.email || ' '}
                    </div>
                  </div>
                  <div className="clear-fix" />
                </GroupUser>
              ))}
        </div>
      </WhiteCard>
    );
  };

  const getSocialIcons = () => {
    return (
      <WhiteCard className="mt-3">
        <p className="black-heading-title card-title  mt-0">Contact Info</p>
        {!isSpreetailUser ? (
          <div
            className="edit-details"
            onClick={() => setShowModal(true)}
            role="presentation"
          >
            <img src={EditOrangeIcon} alt="" />
            Edit
          </div>
        ) : null}

        <div className="label">Phone Number</div>
        <div className="label-info">{detail?.phone_number}</div>

        <div className="label mt-3">Social Accounts</div>

        <ul className="social-media-icons">
          {socialIcons.map((item) => generateSocialIcon(item))}
        </ul>
      </WhiteCard>
    );
  };

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      customerDetails();
      setSelectedCustomerId(event?.value);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        // isApiCall={isApiCall}
        isSearchable={false}
      />
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="col-12">
          <WhiteCard className="my-3">
            <PageLoader
              component="performance-graph"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />{' '}
          </WhiteCard>
        </div>
      ) : (
        <div className="col-12 mb-3">
          {childBPList?.length ? (
            <WhiteCard className="mb-3">
              <ViewData>
                <div className="row">
                  <div className="col-md-4  col-sm-12 ">
                    <div className="view-data-for mt-2 pt-1 ">
                      View data for:
                    </div>{' '}
                  </div>
                  <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
                  <div className="col-md-4 col-12 pl-0">
                    {displayChildBPFilter()}
                  </div>
                </div>
              </ViewData>
            </WhiteCard>
          ) : null}
          <WhiteCard>
            <div className="row">
              <div className="col-10">
                <span className="black-heading-title  ">
                  Company Description
                </span>
                <br />
                <br />

                <span className="normal-text">
                  {' '}
                  <ReadMoreAndLess
                    className="read-more-content"
                    charLimit={150}
                    readMoreText="Read more"
                    readLessText="Read less"
                  >
                    {`${
                      detail?.description === null ? '' : detail?.description
                    }${' '}` || ''}
                  </ReadMoreAndLess>
                </span>
              </div>
            </div>
            {!isSpreetailUser ? (
              <div
                className=" edit-details"
                onClick={() => {
                  setShowModal(true);
                }}
                role="presentation"
              >
                <img src={EditOrangeIcon} alt="" />
                Edit
              </div>
            ) : null}
          </WhiteCard>
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-12 mt-3">
              <>{getContactCard()}</>
              <>{getSocialIcons()}</>
            </div>
            {isCustomer ? null : (
              <div className="col-lg-6 col-md-6 col-12 mt-3">
                <AmazonAccount
                  marketplaceData={marketplaceData}
                  customStyles={customStyles}
                  isAuthorized={isAuthorized}
                  marketplaceList={marketplaceList}
                  id={selectedCustomerId}
                />
              </div>
            )}
          </div>
          <Modal
            isOpen={showModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add team modal"
          >
            <img
              src={CloseIcon}
              alt="close"
              className="float-right cursor cross-icon"
              onClick={() => setShowModal(false)}
              role="presentation"
            />
            <EditCompanyDetails
              setShowModal={setShowModal}
              id={selectedCustomerId}
              detail={detail}
              showModal={showModal}
              scrollDown={scrollDown}
              setScrollDown={setScrollDown}
              setDetail={setDetail}
              getContactData={getContactData}
              contactInfo={contactInfo}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

CompanyDetail.defaultProps = {
  id: '',
  isAuthorized: {},
  childBPList: [],
  setIsAuthorized: () => {},
};

CompanyDetail.propTypes = {
  id: string,
  isCustomer: bool.isRequired,
  isAuthorized: shape({}),
  childBPList: arrayOf(shape({})),
  setIsAuthorized: func,
};
