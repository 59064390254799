import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { shape, string, arrayOf } from 'prop-types';

import Invoice from './Invoice/Invoice';
import BillingDetails from './BillingDetails/BillingDetails';
import {
  Tabs,
  ViewData,
  WhiteCard,
  DropdownIndicator,
  SingleSelectDropdown,
  GrayMessageBar,
} from '../../../../common';
import {
  billingNavigationOptions,
  channelInvoiceTypeOptions,
  financeTabsOptions,
  PATH_CUSTOMER_DETAILS,
} from '../../../../constants';

const BillingContainer = ({
  id,
  userInfo,
  onBoardingId,
  customerStatus,
  redirectType,
  bpName,
  memberData,
  isBBEInternalUser,
  childBPList,
}) => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in billing section
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const billingDynamicMessage =
    params?.nav === 'retainer'
      ? dynamicMessages?.billing_section_monthly_retainer?.placeholder
      : params?.nav === 'rev share'
      ? dynamicMessages?.billing_section_rev_share?.placeholder
      : params?.nav === 'upsell'
      ? dynamicMessages?.billing_section_upsell?.placeholder
      : params?.nav === 'dsp service'
      ? dynamicMessages?.billing_section_dsp?.placeholder
      : params?.nav === 'amc service'
      ? dynamicMessages?.billing_section_amc?.placeholder
      : params?.nav === 'billing-details'
      ? dynamicMessages?.billing_section_billing_details?.placeholder
      : null;

  // https://bbe.atlassian.net/browse/PDV-9667
  // As an internal user, I should be able to view and identify the type of invoices issued to the BP under the billing section
  const showChannelInvoiceTypeFilter =
    params?.nav === 'retainer' ||
    params?.nav === 'rev share' ||
    params?.nav === 'upsell';

  const [channelInvoiceTypes, setChannelInvoiceTypes] = useState(
    params?.nav === 'retainer'
      ? channelInvoiceTypeOptions?.monthlyRetainer
      : params?.nav === 'rev share'
      ? channelInvoiceTypeOptions?.revShare
      : params?.nav === 'upsell'
      ? channelInvoiceTypeOptions?.upsell
      : [],
  );

  const [viewComponent, setViewComponent] = useState(redirectType);
  const [loader, setLoader] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );
  const [selectedChannelInvoiceType, setSelectedChannelInvoiceType] = useState(
    channelInvoiceTypes?.[0],
  );

  useEffect(() => {
    if (childBPList.length) {
      setSelectedCustomerId(childBPList[0]?.value);
    }
  }, [childBPList]);

  const onLoading = (value) => {
    setLoader(value);
  };

  // handle billing tab change events
  const handleBillingTabClickEvents = (item) => {
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(':id', id)}?tab=billing&nav=${
        item?.key || params?.nav
      }&access=${isBBEInternalUser}`,
      item.key,
    );

    setViewComponent(item.key);
    // logic - change the dropdwon options as per the billing tab when user click on it.
    const dropdownOptions =
      item?.key === 'retainer'
        ? channelInvoiceTypeOptions?.monthlyRetainer
        : item?.key === 'rev share'
        ? channelInvoiceTypeOptions?.revShare
        : item?.key === 'upsell'
        ? channelInvoiceTypeOptions?.upsell
        : [];
    setChannelInvoiceTypes(dropdownOptions);
    setSelectedChannelInvoiceType(dropdownOptions?.[0]);
  };

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      setSelectedCustomerId(event?.value);
    }
  };

  // handle channel invoice type filter events
  const handleChannelInvoiceTypeFilterEvents = (event) => {
    if (event?.value !== selectedChannelInvoiceType?.value) {
      setSelectedChannelInvoiceType(event);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display single select dropdown filter
  const displaySingleSelectDropdownfilter = (
    filterId,
    dropdownOptions,
    selectedValue,
    onChangeHandler,
    dropdownComponents,
    isApiCall,
  ) => {
    return (
      <SingleSelectDropdown
        filterId={filterId}
        dropdownOptions={dropdownOptions}
        selectedValue={selectedValue}
        onChangeHandler={onChangeHandler}
        dropdownComponents={dropdownComponents}
        isApiCall={isApiCall}
        isSearchable={false}
      />
    );
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        isApiCall={loader}
        isSearchable={false}
      />
    );
  };

  // display billing filters
  const displayBillingFilters = () => {
    return (
      <WhiteCard className="mb-3 mt-3">
        <ViewData>
          <div className="row">
            <div
              className={`col-sm-12 ${
                showChannelInvoiceTypeFilter
                  ? 'col-xl-6 col-md-4'
                  : 'col-xl-9 col-md-8'
              }`}
            >
              <div className="view-data-for mt-2 pt-1">View data for:</div>
            </div>
            <div className="col-xl-3 col-md-4 col-12 pl-md-0">
              {childBPList?.length ? displayChildBPFilter() : null}
            </div>
            {showChannelInvoiceTypeFilter ? (
              <div className="col-xl-3 col-md-4 col-12 pl-md-0 mt-3 mt-md-0">
                {displaySingleSelectDropdownfilter(
                  'BT-billingChannelInvoiceTypeFilter',
                  channelInvoiceTypes,
                  selectedChannelInvoiceType,
                  handleChannelInvoiceTypeFilterEvents,
                  getSingleSelectComponents,
                  loader,
                )}
              </div>
            ) : null}
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  return (
    <div className="col-12">
      <Tabs>
        <ul className="tabs">
          {financeTabsOptions.map((item) => {
            return (
              <li
                key={item.key}
                className={
                  viewComponent === item.key
                    ? 'active'
                    : loader
                    ? 'disabled'
                    : ''
                }
                onClick={() => {
                  handleBillingTabClickEvents(item);
                }}
                role="presentation"
              >
                {item.value}
              </li>
            );
          })}

          <li
            className={
              viewComponent === 'Billing' || params.nav === 'billing-details'
                ? 'active'
                : ''
            }
            onClick={() => {
              history.push(
                `${PATH_CUSTOMER_DETAILS.replace(
                  ':id',
                  id,
                )}?tab=billing&nav=billing-details&access=${isBBEInternalUser}`,
                'Billing',
              );
              setViewComponent('Billing');
            }}
            role="presentation"
          >
            Billing Details
          </li>
        </ul>
      </Tabs>
      {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in billing section */}
      {billingDynamicMessage ? (
        <GrayMessageBar message={billingDynamicMessage} className="my-3" />
      ) : null}
      {/* billing filters row */}
      {childBPList?.length || showChannelInvoiceTypeFilter
        ? displayBillingFilters()
        : null}
      {billingNavigationOptions.includes(viewComponent) ? (
        <Invoice
          onLoading={onLoading}
          invoiceType={viewComponent}
          id={selectedCustomerId}
          channelInvoiceType={selectedChannelInvoiceType?.value}
          bpName={
            childBPList?.find((op) => op?.value === selectedCustomerId)
              ?.label || bpName
          }
          memberData={memberData}
        />
      ) : (
        <BillingDetails
          id={selectedCustomerId}
          userInfo={userInfo}
          onBoardingId={onBoardingId}
          customerStatus={customerStatus}
          memberData={memberData}
        />
      )}
    </div>
  );
};

export default BillingContainer;

BillingContainer.defaultProps = {
  onBoardingId: null,
  customerStatus: {},
  redirectType: 'retainer',
  bpName: '',
  isBBEInternalUser: 'internal',
  childBPList: [],
};

BillingContainer.propTypes = {
  id: string.isRequired,
  userInfo: shape({
    customer_onboarding: string,
  }).isRequired,
  onBoardingId: string,
  customerStatus: shape({}),
  redirectType: string,
  bpName: string,
  memberData: arrayOf(shape({})).isRequired,
  isBBEInternalUser: string,
  childBPList: arrayOf(shape({})),
};
