/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';

import NumberFormat from 'react-number-format';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import Select from 'react-select';
import { toast } from 'react-toastify';

import ContractInputSelect from '../../../common/ContractInputSelect';
import ContractActivityLog from './ContractActivityLog';
import { SidePanel } from '../../../theme/AgreementStyle';
import { GreenCheck, RedCross } from '../../../theme/images';
import { ErrorMsg, DropdownIndicator, CheckBox } from '../../../common';
import {
  agreementDefaultProptypes,
  agreementSidePanelPropTypes,
} from '../PropTypesConstants/AgreementSidePanelProptypes';
import {
  AgreementDetails,
  StatementDetails,
  DSPAddendumDetails,
  channelOptions,
} from '../../../constants';

import {
  getLength,
  getRevenueShare,
  createAddendum,
  getCurrencyOptions,
} from '../../../api';
import {
  ServiceAgreementSection,
  StatementOfWork,
  DspAdvertising,
  AddendumSection,
  FeeStructureContainer,
} from '.';

export default function AgreementSidePanelContainer({
  id,
  setFormData,
  formData,
  agreementData,
  loader,
  newAddendumData,
  onEditAddendum,
  showEditor,
  setShowEditor,
  setNewAddendum,
  setNotIncludedOneTimeServices,
  apiError,
  showFooter,
  setApiError,
  executeScroll,
  showSection,
  setShowCollpase,
  updatedFormData,
  setUpdatedFormData,
  additionalMonthlyServices,
  setMonthlyAdditionalServices,
  originalData,
  additionalMarketplacesData,
  setAdditionalMarketplace,
  additionalOnetimeServices,
  setAdditionalOnetimeServices,
  additionalMarketplaceError,
  setAdditionalMarketplaceError,
  additionalMonthlySerError,
  setAdditionalMonthlySerError,
  additionalOnetimeSerError,
  setAdditionalOnetimeSerError,
  contractError,
  setContractError,
  setOriginalAddendumData,
  openCollapse,
  setOpenCollapse,
  notIncludedOneTimeServices,
  amazonStoreCustom,
  setAmazonStoreCustom,
  showAmazonPlanDropdown,
  setShowAmazonPlanDropdown,
  showAdditionalMarketplace,
  setShowAdditionalMarketplace,
  startDate,
  setStartDate,
  setShowDiscountModal,
  sectionError,
  setSectionError,
  setDiscountFlag,
  isEditContract,
  marketplacesResult,
  marketPlaces,
  setMarketPlaces,
  additionalMarketplaces,
  setAdditionalMarketplaces,
  firstMonthDate,
  setPageNumber,
  getContractActivityLogInfo,
  activityLoader,
  activityData,
  images,
  activityCount,
  pageNumber,
  isApicalled,
  getContractDetails,
  setIsEditContract,
  setContractLoading,
  customerError,
  setCustomerErrors,
  isDocRendered,
  oneTimeService,
  monthlyService,
  AmazonStoreOptions,
  fetchUncommonOptions,
  originalAddendumData,
  thresholdTypeOptions,
  yoyPercentageOptions,
  checkContractStatus,
  setMarketplaceDropdownData,
  discountData,
  setSelectedDiscount,
  feeStructureErrors,
  setFeeStructureErrors,
  getMonthlyServices,
  showRightTick,
  getFeeStructureDetails,
  manageErrorCount,
  checkMandatoryFieldsOfFeeType,
  servicesFees,
  getData,
  selectedCurrency,
  setSelectedCurrency,
  stdServices,
  setNonUSserviceError,
  nonUsServiceError,
  agencyFeeOptions,
  dspAdditionalMarketplaces,
  showAmazonStorefrontDropdown,
  setShowAmazonStorefrontDropdown,
  details,
  disableCombineRevShare,
  contractAddendumDetails,
  addendumDetails,
  setCalculatedDate,
  setFirstMonthDate,
  setEndDate,
  checkAddendumData,
  setCheckAddendumData,
  role,
  dspAdvertisingMaxDate,
  setDspAdvertisingMaxDate,
  changedDspStartDateFlag,
  setChangedDspStartDateFlag,
  dspMarketpalcesError,
  setDspMarketpalcesError,
  displayMarketplaceName,
  setVatMarketplaces,
  combinedOneTimeServices,
  filterNotIncludedOneTimeServices,
  savedMarketplaces,
  handleAddendumModal,
  returnDspMonthlyDates,
  secondMonthDate,
  thirdMonthDate,
  advertisingServices,
  amcServiceError,
  setAmcServiceError,
  getAddedOrRemovedAMCServices,
}) {
  const [accountLength, setAccountLength] = useState([]);
  const [revShare, setRevShare] = useState([]);
  const [amazonService, setSelectedAmazonStorePackService] = useState({});
  const [selectedThreshold, setSelectedThreshold] = useState('');
  const [agreementCurrency, setAgreementCurrency] = useState([]);
  const d = new Date();
  const currentMonthsFirstDate = new Date(d.getFullYear(), d.getMonth(), 1);
  const customer = formData?.customer_id;
  const additionalOneTimeServicesLength =
    formData?.additional_one_time_services?.length;

  const formDataAdditionalMarketplacesLength =
    formData?.additional_marketplaces?.length;

  const originalDataAdditionalOneTimeServicesLength =
    originalData?.additional_one_time_services?.length;
  const additionalOneTimeServicesCreateLength =
    additionalOnetimeServices?.create?.length;
  const contractType = formData?.contract_type;
  const agreementDataContractStatusValue =
    agreementData?.contract_status?.value;
  const contractErrorSalesThreshold = contractError?.sales_threshold;
  const agreementErrCount = sectionError?.agreement;
  const statementErrCount = sectionError?.statement;
  const dspErrCount = sectionError?.dsp;
  const [runValidateBudget, setrunValidateBudget] = useState({});

  const updateAgreementErrorValue = (value) => {
    setSectionError({
      ...sectionError,
      agreement: value,
    });
  };
  const updateAgreementErrorMinusValue = (value) => {
    const agreementErrvalue = sectionError.agreement ? value : 0;
    updateAgreementErrorValue(agreementErrvalue);
  };

  const updateStatementErrorValue = (value) => {
    setSectionError({
      ...sectionError,
      statement: value,
    });
  };
  const updateStatementErrorMinusValue = (value) => {
    const statementErrValue = sectionError.statement ? value : 0;
    updateStatementErrorValue(statementErrValue);
  };

  const updateDspErrorValue = (value) => {
    setSectionError({
      ...sectionError,
      dsp: value,
    });
  };
  const updateDspErrorMinusValue = (value) => {
    const dspErrorValue = sectionError.dsp ? value : 0;
    updateDspErrorValue(dspErrorValue);
  };

  const updateAdditionalOnetimeServices = (isCreate, isArray) => {
    if (isCreate) {
      setFormData({
        ...formData,
        additional_one_time_services: additionalOnetimeServices.create,
      });
    }
    if (isArray) {
      setUpdatedFormData({
        ...updatedFormData,
        additional_one_time_services: {
          ...additionalOnetimeServices,
        },
      });
      setAdditionalOnetimeServices({
        ...additionalOnetimeServices,
      });
    } else {
      setUpdatedFormData({
        ...updatedFormData,
        additional_one_time_services: additionalOnetimeServices,
      });
    }
  };

  const updateAdditionalOnetimeServicesSelectedData = (selectedData) => {
    setFormData({
      ...formData,
      additional_one_time_services: selectedData,
    });
    setAdditionalOnetimeServices({
      ...additionalOnetimeServices,
      create: selectedData,
    });
    setUpdatedFormData({
      ...updatedFormData,
      additional_one_time_services: {
        ...additionalOnetimeServices,
        create: selectedData,
      },
    });
  };

  const updateAdditionalMarketplaces = () => {
    setUpdatedFormData({
      ...updatedFormData,
      additional_marketplaces: additionalMarketplacesData,
    });
  };
  const updateAdditionalMonthlyServices = () => {
    setUpdatedFormData({
      ...updatedFormData,
      additional_monthly_services: additionalMonthlyServices,
    });
  };

  const arrayMax = (arr) => {
    return arr.reduce((p, v) => {
      return p > v ? p : v;
    });
  };

  const getDSPMonthlyAdBudget = (eventName, dspFee) => {
    if (formData?.is_dynamic_dsp_fee) {
      let dspFeeArray = [];
      const dspFeeFirst = Number(formData?.dsp_fee);
      const dspFeeSecond = Number(formData?.dsp_fee_second);
      const dspFeeThird = Number(formData?.dsp_fee_third);
      if (eventName === 'dsp_fee') {
        dspFeeArray = [dspFee, dspFeeSecond, dspFeeThird];
      }
      if (eventName === 'dsp_fee_second') {
        dspFeeArray = [dspFeeFirst, dspFee, dspFeeThird];
      }
      if (eventName === 'dsp_fee_third') {
        dspFeeArray = [dspFeeFirst, dspFeeSecond, dspFee];
      }
      return arrayMax([...dspFeeArray]);
    }
    return dspFee;
  };

  const handleAMCServices = (eventName, eventValue) => {
    const monthlyAdBudget = getDSPMonthlyAdBudget(
      eventName,
      Number(eventValue),
    );

    const dataToBeSearchOn = contractAddendumDetails.notActive
      ? formData
      : details;

    const standardDashboardObj = dataToBeSearchOn?.amc_services?.find((item) =>
      item?.name.includes('Standard Dashboard'),
    )
      ? [
          dataToBeSearchOn?.amc_services?.find((item) =>
            item?.name.includes('Standard Dashboard'),
          ),
        ]
      : [
          {
            name: 'Standard Dashboard',
            contract: contractAddendumDetails.notActive ? null : details?.id,
            contract_addendum: contractAddendumDetails.notActive
              ? contractAddendumDetails?.id
              : null,
            fee: 0,
          },
        ];

    const advancedDashboardObj = dataToBeSearchOn?.amc_services?.find((item) =>
      item?.name.includes('Advanced Dashboard'),
    )
      ? [
          dataToBeSearchOn?.amc_services?.find((item) =>
            item?.name.includes('Advanced Dashboard'),
          ),
        ]
      : [
          {
            name: 'Advanced Dashboard',
            contract: contractAddendumDetails.notActive ? null : details?.id,
            contract_addendum: contractAddendumDetails.notActive
              ? contractAddendumDetails?.id
              : null,
            fee: monthlyAdBudget && Number(monthlyAdBudget) < 50000 ? 425 : 0,
          },
        ];

    const CustomizedDashboardObj = dataToBeSearchOn?.amc_services?.find(
      (item) => item?.name.includes('Customized Dashboard'),
    )
      ? [
          dataToBeSearchOn?.amc_services?.find((item) =>
            item?.name.includes('Customized Dashboard'),
          ),
        ]
      : [
          {
            name: 'Customized Dashboard',
            contract: contractAddendumDetails.notActive ? null : details?.id,
            contract_addendum: contractAddendumDetails.notActive
              ? contractAddendumDetails?.id
              : null,
          },
        ];

    let amcData = {
      create: formData?.amc_services ? [...formData?.amc_services] : [],
      delete: [],
    };

    if (monthlyAdBudget && Number(monthlyAdBudget) < 50000) {
      let list = [];
      if (details?.amc_services?.length) {
        const nonStandardServices = dataToBeSearchOn?.amc_services?.filter(
          (item) => item?.name === 'Advanced Dashboard',
        );
        list = nonStandardServices?.map((item) => item?.id);
      }
      let customizedData = [];
      if (CustomizedDashboardObj?.[0]?.id) {
        customizedData = CustomizedDashboardObj;
      }

      amcData = {
        ...amcData,
        create: [...standardDashboardObj, ...customizedData],
        delete: list?.length ? [...list] : [],
      };
    }
    if (monthlyAdBudget && Number(monthlyAdBudget) >= 50000) {
      let customizedData = [];
      if (CustomizedDashboardObj?.[0]?.id) {
        customizedData = CustomizedDashboardObj;
      }
      let advancedData = [];
      let deletedIdOfAdvancedData = [];
      let advancedDashboardUpdatedData = [];

      if (advancedDashboardObj?.[0]?.fee === 425) {
        advancedData = {
          name: 'Advanced Dashboard',
          contract: contractAddendumDetails.notActive ? null : details?.id,
          contract_addendum: contractAddendumDetails.notActive
            ? contractAddendumDetails?.id
            : null,
          fee: monthlyAdBudget && Number(monthlyAdBudget) < 50000 ? 425 : 0,
        };
        advancedDashboardUpdatedData = [advancedData];
        deletedIdOfAdvancedData = advancedDashboardObj?.map((item) => item?.id);
      } else {
        advancedDashboardUpdatedData = advancedDashboardObj;
      }

      amcData = {
        ...amcData,
        create: [
          ...standardDashboardObj,
          ...advancedDashboardUpdatedData,
          ...customizedData,
        ],
        delete: deletedIdOfAdvancedData?.length
          ? [...deletedIdOfAdvancedData]
          : [],
      };
    }

    setFormData({
      ...formData,
      amc_services: [...amcData?.create],
      is_amc_management: true,
      [eventName]: eventValue,
    });
    setUpdatedFormData({
      ...updatedFormData,
      [eventName]: eventValue,
      is_amc_management: true,
      amc_services: { ...amcData },
    });
  };

  const handleUpdatedFormDataEvent = (isCustomerId, eventName, eventValue) => {
    if (isCustomerId) {
      const customerData = formData.customer_id;
      setFormData({
        ...formData,
        customer_id: {
          ...customerData,
          [eventName]: eventValue,
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        [eventName]: eventValue,
        dsp_marketplaces: {
          create: formData?.dsp_marketplaces ? formData?.dsp_marketplaces : [],
        },
      });
    } else if (eventName === 'change_agreement_start_date' && !eventValue) {
      const tempStartDate = new Date();
      setStartDate(tempStartDate);
      setFormData({
        ...formData,
        [eventName]: eventValue,
        start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
      });
      setUpdatedFormData({
        ...updatedFormData,
        [eventName]: eventValue,
        start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
        dsp_marketplaces: {
          create: formData?.dsp_marketplaces ? formData?.dsp_marketplaces : [],
        },
      });
    } else if (eventName === 'change_addendum_start_date') {
      let tempStartDate = new Date();
      if (new Date(agreementData?.start_date) > new Date()) {
        tempStartDate = new Date(agreementData?.start_date);
      }

      if (eventValue) {
        setFormData({
          ...formData,
          [eventName]: eventValue,
        });
        setUpdatedFormData({
          ...updatedFormData,
          [eventName]: eventValue,
        });
      } else {
        setFormData({
          ...formData,
          [eventName]: eventValue,
          addendum_start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
        });
        setUpdatedFormData({
          ...updatedFormData,
          [eventName]: eventValue,
          addendum_start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
        });
      }
    } else {
      setFormData({ ...formData, [eventName]: eventValue });
      setUpdatedFormData({
        ...updatedFormData,
        [eventName]: eventValue,
        dsp_marketplaces: {
          create: formData?.dsp_marketplaces ? formData?.dsp_marketplaces : [],
        },
      });
    }
  };

  useEffect(() => {
    getLength().then((len) => {
      setAccountLength(len?.data);
    });
    getRevenueShare().then((rev) => {
      setRevShare(rev?.data);
    });
    getCurrencyOptions().then((currency) => {
      setAgreementCurrency(currency?.data);
    });
  }, []);

  useEffect(() => {
    const serviceData =
      additionalOneTimeServicesLength &&
      formData.additional_one_time_services.find((item) =>
        item.service?.name?.includes('Amazon Store Package'),
      );
    if (serviceData) {
      setShowAmazonPlanDropdown(true);
      const serviceName = serviceData.service.name.split(' ')[3];
      if (serviceName === 'Custom') {
        setAmazonStoreCustom(true);
      }
    }

    setSelectedAmazonStorePackService(serviceData);

    if (formDataAdditionalMarketplacesLength) {
      setMarketplaceDropdownData();
    }
    // Enable flag to show amazon storefront services dropdown
    const amazonStorefrontData =
      additionalOneTimeServicesLength &&
      formData.additional_one_time_services.find((item) =>
        item.service?.name?.includes('Amazon Storefront'),
      );
    if (amazonStorefrontData) {
      setShowAmazonStorefrontDropdown(true);
    }
  }, [agreementData, isDocRendered]);

  useEffect(() => {
    if (typeof formData?.currency === 'string') {
      setFormData({
        ...formData,
        currency: agreementCurrency.filter(
          (op) => op.value === formData?.currency,
        )?.[0],
      });
    }
  }, [formData.currency]);

  // use effect for agreement start date logic
  useEffect(() => {
    if (agreementData?.contract_status?.value !== 'active') {
      if (
        agreementData?.start_date !== null &&
        new Date(agreementData?.start_date) < currentMonthsFirstDate
      ) {
        let tempStartDate = new Date();
        if (agreementData?.change_agreement_start_date) {
          tempStartDate = new Date(agreementData?.start_date);
        }
        setStartDate(tempStartDate);
        setUpdatedFormData({
          ...updatedFormData,
          start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
        });
        setFormData({
          ...formData,
          start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [agreementData]);

  // use effect for addendum start date logic
  useEffect(() => {
    const contractStartDate = new Date(agreementData?.start_date);
    let tempStartDate = new Date();

    if (contractAddendumDetails?.notActive) {
      if (contractAddendumDetails?.change_addendum_start_date) {
        tempStartDate = contractAddendumDetails?.addendum_start_date;
      } else if (contractStartDate <= new Date()) {
        tempStartDate = new Date();
      } else {
        tempStartDate = agreementData?.start_date;
      }

      setFormData({
        ...formData,
        addendum_start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
      });
      setUpdatedFormData({
        ...updatedFormData,
        addendum_start_date: dayjs(tempStartDate).format('YYYY-MM-DD'),
      });
    }
  }, [contractAddendumDetails]);

  const clearOneTimeQntyError = (val) => {
    const itemFound =
      additionalOnetimeSerError?.quantity?.length &&
      additionalOnetimeSerError.quantity.find((item) =>
        item.service_id === val.value
          ? val.value
          : val?.service
          ? val.service.id
          : '',
      );

    if (itemFound) {
      const updatedQntyError = additionalOnetimeSerError.quantity;

      const list = updatedQntyError.filter(
        (item) => item.service_id !== itemFound.service_id,
      );

      setAdditionalOnetimeSerError({
        ...additionalOnetimeSerError,
        quantity: list,
      });
    }
  };

  const clearError = (event, key, type) => {
    if (type === 'date') {
      if (
        AgreementDetails.find((item) => item.key === key && item.isMandatory) ||
        key === 'address' ||
        key === 'state' ||
        key === 'city' ||
        key === 'zip_code'
      ) {
        AgreementDetails.forEach((item) => {
          if (item.key !== 'contract_address') {
            if (event) {
              if (item.key === key && !(formData && formData[item.key])) {
                item.error = false;
                const dspErrorCount =
                  sectionError.dsp > 0 ? sectionError.dsp - 1 : 0;
                setSectionError({
                  ...sectionError,
                  agreement: sectionError.agreement - 1,
                  dsp: dspErrorCount,
                });
              }
            } else if (formData && formData[key]) {
              item.error = true;
              setSectionError({
                ...sectionError,
                agreement: sectionError.agreement + 1,
                dsp: sectionError.dsp + 1,
              });
            }
          }
          return null;
        });
      }
    }

    if (type === 'choice') {
      if (
        StatementDetails.find((item) => item.key === key && item.isMandatory)
      ) {
        StatementDetails.forEach((item) => {
          if (event && event.value) {
            if (item.key === key && !(formData && formData[item.key])) {
              item.error = false;
              updateStatementErrorMinusValue(sectionError.statement - 1);
            }
          } else {
            item.error = true;

            if (formData && formData[key]) {
              updateStatementErrorValue(sectionError.statement + 1);
            }
          }
        });
      } else if (
        DSPAddendumDetails.find((item) => item.key === key && item.isMandatory)
      ) {
        DSPAddendumDetails.forEach((item) => {
          if (event && event.value) {
            if (item.key === key && !(formData && formData[item.key])) {
              item.error = false;
              if (sectionError.dsp > 0) {
                updateDspErrorMinusValue(sectionError.dsp - 1);
              }
            }
          } else if (formData && formData[key]) {
            item.error = true;
            updateDspErrorValue(sectionError.dsp + 1);
          }
        });
      } else if (
        AgreementDetails.find((item) => item.key === key && item.isMandatory) ||
        key === 'address' ||
        key === 'state' ||
        key === 'city' ||
        key === 'zip_code'
      ) {
        AgreementDetails.forEach((item) => {
          if (item.key !== 'contract_address') {
            if (event?.value) {
              if (item.key === key && !(formData && formData[item.key])) {
                item.error = false;
                updateAgreementErrorMinusValue(sectionError.agreement - 1);
              }
            } else if (formData && formData[key]) {
              item.error = true;
              updateAgreementErrorValue(sectionError.agreement + 1);
            }
          }
        });
      }
    }

    if (event?.target?.name) {
      if (
        StatementDetails.find(
          (item) => item.key === event.target.name && item.isMandatory,
        )
      ) {
        StatementDetails.forEach((item) => {
          if (event?.target?.value) {
            if (
              item.key === event.target.name &&
              !(formData && formData[item.key])
            ) {
              item.error = false;
              updateStatementErrorMinusValue(sectionError.statement - 1);
            }
          } else {
            item.error = true;
            if (formData && formData[event.target.name]) {
              updateStatementErrorValue(sectionError.statement + 1);
            }
          }
        });
      } else if (
        DSPAddendumDetails.find(
          (item) => item.key === event.target.name && item.isMandatory,
        )
      ) {
        DSPAddendumDetails.forEach((item) => {
          if (event?.target?.value) {
            if (
              item.key === event.target.name &&
              !(formData && formData[item.key])
            ) {
              item.error = false;
              if (sectionError.dsp > 0) {
                updateDspErrorMinusValue(sectionError.dsp - 1);
              }
            }
          } else if (formData && formData[event.target.name]) {
            item.error = true;
            updateDspErrorValue(sectionError.dsp + 1);
          }
        });
      } else if (
        AgreementDetails.find(
          (item) => item.key === event.target.name && item.isMandatory,
        ) ||
        event.target.name === 'address' ||
        event.target.name === 'state' ||
        event.target.name === 'city' ||
        event.target.name === 'zip_code'
      ) {
        return AgreementDetails.forEach((item) => {
          if (item.key !== 'contract_address') {
            if (event?.target?.value) {
              if (
                item.key === event.target.name &&
                item.field === 'customer' &&
                !customer[item.key]
              ) {
                item.error = false;
                updateAgreementErrorMinusValue(sectionError.agreement - 1);
              }

              if (
                item.key === event.target.name &&
                item.field !== 'customer' &&
                !(formData && formData[item.key])
              ) {
                item.error = false;
                updateAgreementErrorMinusValue(sectionError.agreement - 1);
              }
            } else if (
              formData &&
              formData[event.target.name] &&
              item.field !== 'customer'
            ) {
              item.error = true;
              updateAgreementErrorValue(sectionError.agreement + 1);
            } else if (
              customer[event.target.name] &&
              item.field === 'customer'
            ) {
              item.error = true;
              updateAgreementErrorValue(sectionError.agreement + 1);
            }
          } else {
            return (
              item &&
              item.sections.forEach((subItem) => {
                if (event?.target?.value) {
                  if (
                    subItem.key === event.target.name &&
                    !customer[subItem.key] &&
                    event.target.value
                  ) {
                    subItem.error = false;
                    updateAgreementErrorMinusValue(sectionError.agreement - 1);
                  }
                } else if (customer[event.target.name]) {
                  subItem.error = true;
                  updateAgreementErrorValue(sectionError.agreement + 1);
                }
              })
            );
          }
          return null;
        });
      }
    }
    return null;
  };

  const handleChange = (event, key, type, val) => {
    if (
      key === 'dsp_fee' ||
      key === 'dsp_fee_second' ||
      key === 'dsp_fee_third' ||
      key === 'dsp_one_time_fee'
    ) {
      setrunValidateBudget({ ...runValidateBudget, [key]: true });
    }

    showFooter(true);
    clearError(event, key, type);
    if (
      additionalOnetimeSerError.custom_amazon_store_price &&
      key === 'amazon_store_package'
    ) {
      updateStatementErrorMinusValue(sectionError.statement - 1);
      setAdditionalOnetimeSerError({
        ...additionalOnetimeSerError,
        custom_amazon_store_price: '',
      });
    }
    if (apiError.non_field_errors && key === 'primary_marketplace') {
      updateStatementErrorMinusValue(sectionError.statement - 1);

      setApiError({
        ...apiError,
        non_field_errors: '',
      });
    }

    if (type === 'quantity') {
      if (contractType?.toLowerCase()?.includes('one')) {
        //
      }
      clearOneTimeQntyError(val);
    }

    if (type === 'date') {
      if (key === 'start_date') {
        setStartDate(event);
      }
      setFormData({ ...formData, [key]: dayjs(event).format('YYYY-MM-DD') });
      setUpdatedFormData({
        ...updatedFormData,
        [key]: dayjs(event).format('YYYY-MM-DD'),
      });
    } else if (type === 'choice') {
      if (key === 'primary_marketplace') {
        setAdditionalMarketplaces(
          marketPlaces.filter((op) => op.value !== event.value),
        );
      }
      if (key === 'yoy_percentage') {
        setContractError({
          ...contractError,
          [key]: '',
        });
        updateStatementErrorMinusValue(sectionError.statement - 1);
      }

      if (key === 'currency') {
        setFormData({ ...formData, [key]: event.label?.split(' ')?.[0] });
        setUpdatedFormData({
          ...updatedFormData,
          [key]: event.value,
        });
        setSelectedCurrency(event.label?.split(' ')?.[0]);
      }
      setFormData({ ...formData, [key]: event.value });
      setUpdatedFormData({ ...updatedFormData, [key]: event.value });
    } else if (type === 'qty') {
      setFormData({ ...formData, quantity: event.target.value });
      setUpdatedFormData({
        ...updatedFormData,
        quantity: event.target.value,
      });
    } else if (key === 'amazon_store_package') {
      if (type === 'quantity') {
        const selectedData =
          additionalOneTimeServicesCreateLength &&
          additionalOnetimeServices.create.filter((item) => {
            if (
              item.name
                ? item.name?.includes('Amazon Store Package')
                : item.service.name?.includes('Amazon Store Package')
            ) {
              if (event.target.value) {
                item.quantity = parseInt(event.target.value, 10);
              } else {
                item.quantity = 1;
              }
            }
            return item;
          });

        if (selectedData) {
          updateAdditionalOnetimeServicesSelectedData(selectedData);
        }
      }

      if (type === 'dropdown') {
        let selectedData = [];
        selectedData =
          additionalOneTimeServicesCreateLength &&
          additionalOnetimeServices.create.filter((item) => {
            if (
              !(item.name
                ? item.name?.includes('Amazon Store Package')
                : item.service.name?.includes('Amazon Store Package'))
            ) {
              return item;
            }
            return false;
          });

        const itemInOriginalData =
          originalDataAdditionalOneTimeServicesLength &&
          originalData.additional_one_time_services.find((item) =>
            item.name
              ? item.name?.includes('Amazon Store Package')
              : item.service.name?.includes('Amazon Store Package'),
          );

        if (itemInOriginalData?.service?.name?.includes(event.label)) {
          selectedData.push(itemInOriginalData);
        } else if (event.label === 'Custom') {
          if (selectedData === 0) {
            selectedData = [];
          }
          selectedData.push({
            name: `Amazon Store Package ${event.label}`,
            quantity: 1,
            service_id: event.value,
            contract_id: originalData && originalData.id,
          });
        } else {
          if (selectedData === 0) {
            selectedData = [];
          }
          selectedData.push({
            name: `Amazon Store Package ${event.label}`,
            quantity: 1,
            service_id: event.value,
            contract_id: originalData?.id,
          });
        }

        setFormData({
          ...formData,
          additional_one_time_services: selectedData,
        });

        additionalOnetimeServices.create = selectedData;

        setAdditionalOnetimeServices({
          ...additionalOnetimeServices,
        });

        setUpdatedFormData({
          ...updatedFormData,
          additional_one_time_services: {
            ...additionalOnetimeServices,
            create: selectedData,
          },
        });

        if (itemInOriginalData?.service?.name?.includes(event.label)) {
          if (
            additionalOnetimeServices.delete.find(
              (item) => item === itemInOriginalData.id,
            )
          ) {
            const list = additionalOnetimeServices.delete.filter(
              (item) => item !== itemInOriginalData.id,
            );
            additionalOnetimeServices.delete = list;
          }
        } else if (itemInOriginalData) {
          if (
            additionalOnetimeServices.delete.find(
              (item) => item === itemInOriginalData.id,
            )
          ) {
            // console
          } else {
            additionalOnetimeServices.delete.push(itemInOriginalData.id);
          }
        }
        updateAdditionalOnetimeServices(false, 'isArray');
        // remove Amazon Store Package entries from notincludedService
        const listWithoutAmazonStorePack = notIncludedOneTimeServices.filter(
          (item) => !item.label?.includes('Amazon Store Package'),
        );
        setNotIncludedOneTimeServices(listWithoutAmazonStorePack);
      }

      if (type === 'custom_amazon_store_price') {
        const itemInList =
          additionalOneTimeServicesCreateLength &&
          additionalOnetimeServices.create.find((item) =>
            item.name
              ? item.name?.includes('Amazon Store Package')
              : item.service.name?.includes('Amazon Store Package'),
          );

        if (itemInList) {
          if (
            event.target.value &&
            event.target.value?.includes(selectedCurrency)
          ) {
            let value = event.target.value.slice(1);
            value = value.replace(/,/g, '');
            itemInList[event.target.name] = value;
          } else {
            itemInList[event.target.name] = event.target.value;
          }
        }
        updateAdditionalOnetimeServices('isCreate', 'isArray');
      }

      if (type === 'checkbox') {
        if (event.target.checked) {
          const itemInOriginalData =
            originalDataAdditionalOneTimeServicesLength &&
            originalData.additional_one_time_services.find((item) =>
              item.name
                ? item.name?.includes('Amazon Store Package')
                : item.service.name?.includes('Amazon Store Package'),
            );

          if (itemInOriginalData) {
            additionalOnetimeServices.create.push(itemInOriginalData);

            const list = additionalOnetimeServices.delete.filter(
              (item) => item !== itemInOriginalData.id,
            );
            additionalOnetimeServices.delete = list;
            updateAdditionalOnetimeServices('isCreate', 'isArray');
            // if item selected and if it is custom then show price input field else not
            if (
              itemInOriginalData?.name
                ? itemInOriginalData?.name?.includes(
                    'Amazon Store Package Custom',
                  )
                : itemInOriginalData?.service?.name?.includes(
                    'Amazon Store Package Custom',
                  )
            ) {
              setAmazonStoreCustom(true);
            } else {
              setAmazonStoreCustom(false);
            }
          } else {
            additionalOnetimeServices.create.push({
              name: `Amazon Store Package`,
              quantity: 1,
              contract_id: originalData?.id,
            });
            updateAdditionalOnetimeServices('isCreate', 'isArray');
            // if item not already exist in orginal data then it will be new so dont show custom price input if you choose any amazon service
            // then custom price input condition will get handle in onchange of drop down of basic ,plus, and custom
            setAmazonStoreCustom(false);
          }
          // remove Amazon Store Package entries from notincludedService
          const listWithoutAmazonStorePack = notIncludedOneTimeServices.filter(
            (item) => !item.label?.includes('Amazon Store Package'),
          );
          setNotIncludedOneTimeServices(listWithoutAmazonStorePack);

          if (
            contractType?.toLowerCase()?.includes('one') &&
            (additionalOnetimeServices.create !== null ||
              additionalOneTimeServicesCreateLength)
          ) {
            setAdditionalMonthlySerError({
              ...additionalMonthlySerError,
              required: '',
            });
            updateAgreementErrorMinusValue(sectionError.agreement - 1);
          }
        } else {
          const itemInList =
            additionalOneTimeServicesCreateLength &&
            additionalOnetimeServices.create.find((item) =>
              item.name
                ? item.name?.includes('Amazon Store Package')
                : item.service.name?.includes('Amazon Store Package'),
            );
          if (itemInList) {
            const removedEle =
              additionalOneTimeServicesCreateLength &&
              additionalOnetimeServices.create.filter((item) =>
                item.name
                  ? item.name !==
                    (itemInList.name
                      ? itemInList.name
                      : itemInList.service.name)
                  : item.service.name !==
                    (itemInList.name
                      ? itemInList.name
                      : itemInList.service.name),
              );

            additionalOnetimeServices.create = removedEle;

            if (itemInList.id) {
              additionalOnetimeServices.delete.push(itemInList.id);
            }
            updateAdditionalOnetimeServices('isCreate', 'isArray');
          }
          // if previously custom service is selected then when we uncheck this Amazon Store PAckage then we need to disable 'custom store price' input
          if (
            itemInList?.name
              ? itemInList?.name?.includes('Amazon Store Package Custom')
              : itemInList?.service?.name?.includes(
                  'Amazon Store Package Custom',
                )
          ) {
            //
          } else {
            setAmazonStoreCustom(false);
          }
          // add amazon Store package to notIncludedService array
          // const listWithAmazonStorePAck = oneTimeService.filter((item) =>
          //   item.label.includes('Amazon Store Package'),
          // );
          if (
            notIncludedOneTimeServices.find((item) =>
              item.label?.includes('Amazon Store Package'),
            )
          ) {
            // don't do anything
          } else {
            setNotIncludedOneTimeServices(
              notIncludedOneTimeServices.concat([
                {
                  value: 'Amazon Store Package',
                  label: 'Amazon Store Package',
                },
              ]),
            );
          }

          if (
            contractType?.toLowerCase()?.includes('one') &&
            (additionalOnetimeServices.create === null ||
              !additionalOneTimeServicesCreateLength)
          ) {
            setAdditionalMonthlySerError({
              ...additionalMonthlySerError,
              required: 'At least 1 one time service required',
            });
            updateAgreementErrorValue(sectionError.agreement + 1);
          }
        }
      }
    } else if (key === 'additional_one_time_services') {
      let itemInFormData = {};
      itemInFormData =
        originalDataAdditionalOneTimeServicesLength &&
        originalData.additional_one_time_services.find(
          (item) => item?.service?.name === event.target.name,
        );

      if (type === 'quantity') {
        const selectedItem =
          additionalOneTimeServicesCreateLength &&
          additionalOnetimeServices.create.filter((item) => {
            if (
              item.name
                ? item.name === event.target.name
                : item.service.name === event.target.name
            ) {
              if (event.target.value) {
                item.quantity = parseInt(event.target.value, 10);
              } else {
                item.quantity = 1;
              }
            }
            return item;
          });

        // eslint-disable-next-line no-unused-vars
        const selectedItemTemp =
          additionalOneTimeServicesCreateLength &&
          // eslint-disable-next-line array-callback-return
          additionalOnetimeServices.create.filter((item, index) => {
            if (
              (item.name
                ? item.name === event.target.name
                : item.service.name === event.target.name) &&
              item.quantity === 0
            ) {
              selectedItem[index].quantity = 1;
            }
          });
        if (selectedItem) {
          setFormData({
            ...formData,
            additional_one_time_services: selectedItem,
          });
          updateAdditionalOnetimeServices();
        }
      }

      // if item checked
      else if (event.target.checked) {
        // checked whether checked item present in newly created list
        if (
          additionalOneTimeServicesCreateLength &&
          additionalOnetimeServices.create.find((item) =>
            item.name
              ? item.name === event.target.name
              : item.service.name === event.target.name,
          )
        ) {
          // if checked item is already present in newly created list then don't do anything
        } else {
          // if checked item not found in newly created list then  again check whether it is present in original formData variable because if it is found in formData then we need to add that found item in newly created list bcoz we need id and all of that item to push in newly created list.
          // here we check whether checked item present in orginal formDAta list then add that found item in newly created list
          if (itemInFormData) {
            if (
              additionalOneTimeServicesCreateLength &&
              additionalOnetimeServices.create.find((item) =>
                item.name
                  ? item.name === itemInFormData.service.name
                  : item.service.name === itemInFormData.service.name,
              )
            ) {
              // !!!
            } else {
              additionalOnetimeServices.create.push(itemInFormData);
              updateAdditionalOnetimeServices('isCreate', false);
            }
          }
          // else we create dict as BE required for new item and we push that in newly created list
          else if (
            additionalOneTimeServicesCreateLength &&
            additionalOnetimeServices.create.find((item) =>
              item.service_id
                ? item.service_id === val.value
                : item.service?.id === val.value,
            )
          ) {
            //
          } else {
            additionalOnetimeServices.create.push({
              name: event.target.name,
              service_id: val.value,
              contract_id: originalData?.id,
              quantity: 1,
            });
            updateAdditionalOnetimeServices('isCreate', false);
          }
          // here we fnally update state variable
          setAdditionalOnetimeServices({
            ...additionalOnetimeServices,
          });
        }
        // suppose checked item present in original formData then we have to remove its id from newly created delete list.
        if (itemInFormData) {
          const updatedDeleteList = additionalOnetimeServices.delete.filter(
            (item) => item !== itemInFormData.id,
          );
          additionalOnetimeServices.delete = updatedDeleteList;
        }

        setAdditionalOnetimeServices({
          ...additionalOnetimeServices,
        });

        fetchUncommonOptions(
          oneTimeService,
          additionalOnetimeServices.create,
          'one_time_service',
        );

        if (
          contractType?.toLowerCase()?.includes('one') &&
          (additionalOnetimeServices.create !== null ||
            additionalOneTimeServicesCreateLength)
        ) {
          setAdditionalMonthlySerError({
            ...additionalMonthlySerError,
            required: '',
          });
          updateAgreementErrorMinusValue(sectionError.agreement - 1);
        }
      }
      // if item unchecked or removed
      else {
        // if unchecked item found in original list then add its id to newly created delte list
        if (itemInFormData) {
          additionalOnetimeServices.delete.push(itemInFormData.id);
        }
        // now we filter newly created list with removed unchecked item from it
        const updatedCreateList = additionalOnetimeServices.create.filter(
          (item) =>
            item.name
              ? item.name !== event.target.name
              : item.service.name !== event.target.name,
        );

        additionalOnetimeServices.create = updatedCreateList;

        const list = formData.additional_one_time_services;
        const deletedUncheckedItemList = list.filter((item) =>
          item.name
            ? item.name !== event.target.name
            : item.service.name !== event.target.name,
        );

        setFormData({
          ...formData,
          additional_one_time_services: deletedUncheckedItemList,
        });
        updateAdditionalOnetimeServices();
        setAdditionalOnetimeServices({
          ...additionalOnetimeServices,
        });

        if (
          contractType?.toLowerCase()?.includes('one') &&
          (deletedUncheckedItemList === null ||
            !deletedUncheckedItemList?.length)
        ) {
          setAdditionalMonthlySerError({
            ...additionalMonthlySerError,
            required: 'At least 1 one time service required',
          });
          updateAgreementErrorValue(sectionError.agreement + 1);
        }
      }

      fetchUncommonOptions(
        oneTimeService,
        additionalOnetimeServices.create,
        'one_time_service',
      );
    } else {
      if (event.target.name === 'zip_code') {
        handleUpdatedFormDataEvent(
          'isCustomerId',
          event.target.name,
          event.target.value.trim(),
          null,
        );
      } else if (event.target.value?.includes(selectedCurrency)) {
        let value = event.target.value.slice(1);

        if (
          event.target.name === 'dsp_fee' ||
          event.target.name === 'dsp_fee_second' ||
          event.target.name === 'dsp_fee_third'
        ) {
          value = value?.replace(/,/g, '')?.replace(/^0+/, '');
          handleAMCServices(event.target.name, value);
        }
        if (event.target.name === 'dsp_one_time_fee') {
          value = value?.replace(/,/g, '');
          handleUpdatedFormDataEvent(false, event.target.name, value, null);
        }
      } else if (
        event.target.name === 'monthly_retainer' &&
        event.target.value === ''
      ) {
        handleUpdatedFormDataEvent(false, event.target.name, null, null);
      } else if (
        event.target.name === 'billing_cap' &&
        event.target.value === ''
      ) {
        handleUpdatedFormDataEvent(false, event.target.name, null, null);
      } else if (
        event.target.name === 'company_name' ||
        event.target.name === 'state' ||
        event.target.name === 'city' ||
        event.target.name === 'address'
      ) {
        handleUpdatedFormDataEvent(
          'isCustomerId',
          event.target.name,
          event.target.value,
          null,
        );
      } else if (
        event?.target?.name === 'change_agreement_start_date' ||
        event?.target?.name === 'change_addendum_start_date'
      ) {
        handleUpdatedFormDataEvent(
          false,
          event?.target?.name,
          event?.target?.checked,
        );
      } else if (
        event?.target?.name === 'content_optimization' ||
        event?.target?.name === 'design_optimization' ||
        event?.target?.name === 'photography_optimization' ||
        event?.target?.name === 'copy_listing_translation' ||
        event?.target?.name === 'creative_listing_translation'
      ) {
        setFormData({
          ...formData,
          [event.target.name]: parseInt(event.target.value, 10)
            ? event.target.value
            : null,
        });
        setUpdatedFormData({
          ...updatedFormData,
          [event.target.name]: parseInt(event.target.value, 10)
            ? event.target.value
            : null,
        });
      } else {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setUpdatedFormData({
          ...updatedFormData,
          [event.target.name]: event.target.value,
        });
      }

      if (
        Object.keys(apiError)?.includes('non_field_errors') ||
        Object.keys(apiError)?.includes(event.target.name)
      ) {
        setApiError({
          ...apiError,
          [event.target.name]: '',
        });
      }
      if (
        additionalMarketplaceError &&
        Object.keys(additionalMarketplaceError)?.includes(event.target.name)
      ) {
        setAdditionalMarketplaceError({
          ...additionalMarketplaceError,
          [additionalMarketplaceError]: '',
        });
      }

      if (
        additionalMonthlySerError &&
        Object.keys(additionalMonthlySerError)?.includes(event.target.name)
      ) {
        setAdditionalMonthlySerError({
          ...additionalMonthlySerError,
          [event.target.name]: '',
        });
      }

      if (
        additionalOnetimeSerError &&
        Object.keys(additionalOnetimeSerError)?.includes(event.target.name)
      ) {
        setAdditionalOnetimeSerError({
          ...additionalOnetimeSerError,
          [event.target.name]: '',
        });
      }
      if (
        customerError &&
        Object.keys(customerError)?.includes(event.target.name)
      ) {
        if (event.target.name === 'zip_code' && customerError?.zip_code) {
          if (event.target.value) {
            updateAgreementErrorMinusValue(sectionError.agreement - 1);
          } else {
            setSectionError({
              ...sectionError,
              agreement: sectionError.agreement ? sectionError.agreement : 0,
            });
          }
          setCustomerErrors({
            ...customerError,
            [event.target.name]: '',
          });
        }
      }
      if (event.target.name === 'dsp_fee' && contractError?.dsp_fee) {
        if (event.target.value) {
          updateDspErrorMinusValue(sectionError.dsp - 1);
        } else {
          setSectionError({
            ...sectionError,
            dsp: sectionError.dsp ? sectionError.dsp : 0,
          });
        }
      }
      if (
        event.target.name === 'dsp_one_time_fee' &&
        contractError?.dsp_one_time_fee
      ) {
        if (event.target.value) {
          updateDspErrorMinusValue(sectionError.dsp - 1);
        } else {
          setSectionError({
            ...sectionError,
            dsp: sectionError.dsp ? sectionError.dsp : 0,
          });
        }
      }

      if (
        (key === 'threshold_type' && contractErrorSalesThreshold) ||
        contractError?.yoy_percentage
      ) {
        setContractError({
          ...contractError,
          sales_threshold: '',
          yoy_percentage: '',
        });
        updateStatementErrorMinusValue(sectionError.statement - 1);
      } else if (event.target.name === 'sales_threshold') {
        if (contractErrorSalesThreshold) {
          setContractError({
            ...contractError,
            sales_threshold: '',
            yoy_percentage: '',
          });
          updateStatementErrorMinusValue(sectionError.statement - 1);
        }
      } else {
        setContractError({
          ...contractError,
          [event.target.name]: '',
        });
      }
    }
  };

  const getOptions = (key, type) => {
    if (type === 'multi') {
      if (key === 'additional_marketplaces') return additionalMarketplaces;
      if (key === 'additional_monthly_services') return monthlyService;
    }
    if (key === 'rev_share') return revShare;
    if (key === 'primary_marketplace') return marketPlaces;
    if (key === 'additional_one_time_services') return oneTimeService;
    if (key === 'currency') return agreementCurrency;
    if (key === 'channel') return channelOptions;
    if (key === 'agency_fee') return agencyFeeOptions;
    return accountLength;
  };

  const generateDropdown = (item, type) => {
    return (
      <Select
        classNamePrefix={
          (apiError && apiError[item.key]) ||
          (!(formData && formData[item.key]) && item.isMandatory)
            ? 'react-select  form-control-error'
            : 'react-select'
        }
        styles={{
          control: (base, state) => ({
            ...base,
            background:
              (apiError?.non_field_errors &&
                apiError.non_field_errors[0] &&
                item.key === 'primary_marketplace') ||
              (!(formData && formData[item.key]) && item.isMandatory)
                ? '#FBF2F2'
                : '#F4F6FC',
            // match with the menu
            // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
            // Overwrittes the different states of border
            borderColor:
              (apiError?.non_field_errors &&
                apiError.non_field_errors[0] &&
                item.key === 'primary_marketplace') ||
              (!(formData && formData[item.key]) && item.isMandatory)
                ? '#D63649'
                : '#D5D8E1',

            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              // Overwrittes the different states of border
              boxShadow: state.isFocused ? null : null,
              outlineColor: state.isFocused ? null : null,
            },
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: '556178',
            };
          },
        }}
        placeholder={item.placeholder ? item.placeholder : 'Select'}
        defaultValue={
          item.key === 'primary_marketplace'
            ? formData.primary_marketplace?.name
              ? {
                  value: formData.primary_marketplace.name,
                  label: displayMarketplaceName(
                    formData.primary_marketplace.name,
                    'primary',
                  ),
                }
              : formData?.primary_marketplace
              ? {
                  value: formData.primary_marketplace,
                  label: displayMarketplaceName(
                    formData.primary_marketplace,
                    'primary',
                  ),
                }
              : null
            : item.key === 'currency'
            ? {
                value: formData?.currency?.value,
                label: formData?.currency?.label,
              }
            : item.key === 'channel'
            ? {
                value: formData?.channel?.value,
                label: formData?.channel?.label,
              }
            : item.key === 'agency_fee'
            ? formData?.agency_fee?.label
              ? {
                  value: formData?.agency_fee?.value,
                  label: formData?.agency_fee?.label,
                }
              : {
                  value: formData?.agency_fee,
                  label: `${formData?.agency_fee}%`,
                }
            : formData[item.key] && formData[item.key].label
            ? {
                value: formData[item.key].label,
                label: formData[item.key].value,
              }
            : formData[item.key]
            ? {
                value: formData[item.key],
                label: formData[item.key],
              }
            : null
        }
        options={getOptions(item.key, 'single')}
        name={item.key}
        components={{ DropdownIndicator }}
        onChange={(event) => handleChange(event, item.key, 'choice')}
        isDisabled={
          (type !== 'dsp' && contractAddendumDetails.notActive) ||
          item?.key === 'channel'
        }
      />
    );
  };

  const tileDisabled = (date, agreementStartDate) => {
    if (formData?.draft_from) {
      return (
        dayjs(date.date).get('date') !== dayjs(agreementStartDate).get('date')
      );
    }
    return false;
  };

  const displayContractType = () => {
    switch (formData && formData.contract_type) {
      case 'recurring':
        return 'Recurring Service Agreement';

      case 'recurring (90 day notice)':
        return 'Recurring (90 day notice) Service Agreement';

      case 'one time':
        return 'One Time Service Agreement';

      case 'dsp only':
        return 'DSP Only Service Agreement';

      default:
        break;
    }
    return '';
  };

  // function which return next month or next-to-next month for dsp advertising
  // if date is between 1-20 then return 1st og next month
  // if date greater than 20 then return 1st of next-to-next month

  const addDaysNew = (theDate) => {
    const tempStartDate = theDate.getDate();
    if (tempStartDate >= 1 && tempStartDate <= 20) {
      return new Date(theDate.getFullYear(), theDate.getMonth() + 1, 1);
    }
    return new Date(theDate.getFullYear(), theDate.getMonth() + 2, 1);
  };

  const generateHTML = (item, type) => {
    if (item.type?.includes('number')) {
      return (
        <NumberFormat
          className={`${
            (contractError && contractError[item.key]) ||
            (!(formData && formData[item.key]) && item.isMandatory)
              ? 'form-control form-control-error'
              : 'form-control'
          } ${
            formData?.is_dynamic_dsp_fee &&
            item?.key === 'dsp_fee' &&
            !item?.isDynamicMonthlyBudget
              ? 'disabled'
              : ''
          }`}
          format={item.key === 'zip_code' ? '##########' : null}
          name={item.key}
          defaultValue={formData[item.key]}
          value={formData[item.key]}
          placeholder={item.placeholder ? item.placeholder : item.label}
          prefix={item.type === 'number-currency' ? selectedCurrency : ''}
          suffix={item.type === 'number-percent' ? '%' : ''}
          onChange={(event) => handleChange(event, item.key)}
          thousandSeparator={item.key !== 'zip_code'}
          decimalScale={2}
          allowNegative={false}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            if (floatValue == null) {
              return formattedValue === '';
            }
            if (item?.zeroAcceptableField) {
              return floatValue >= 0 && floatValue <= 100000000;
            }
            return floatValue > 0 && floatValue <= 100000000;
          }}
        />
      );
    }

    if (item.type === 'date') {
      if (item.part === 'dsp') {
        // PDV- 8747 - for agreement and for addendum- user can select date from 1st date of current month. (1-20 date logic remain as it is).
        const dateToday = new Date();
        const firstDay = new Date(
          dateToday.getFullYear(),
          dateToday.getMonth(),
          1,
        );
        return (
          <DatePicker
            className={
              !formData?.changed_dsp_start_date
                ? 'form-control isDisabled'
                : 'form-control'
            }
            id="dspdate"
            value={
              contractAddendumDetails?.notActive &&
              !formData?.changed_dsp_start_date // if addundum -> then return 1-20 day logic
                ? addDaysNew(new Date())
                : formData?.dsp_start_date
                ? new Date(formData?.dsp_start_date)
                : new Date(firstMonthDate)
            }
            minDate={firstDay}
            maxDate={
              formData?.changed_dsp_start_date ? dspAdvertisingMaxDate : null
            }
            format="MM/dd/yyyy"
            clearIcon={null}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            placeholderText="Select Date"
            onChange={(date) => {
              handleChange(date, 'dsp_start_date', 'date');
              setFirstMonthDate(new Date(date));
            }}
          />
        );
      }
      if (item?.key === 'start_date') {
        return (
          <DatePicker
            className={
              type !== 'dsp' && contractAddendumDetails.notActive
                ? 'form-control isDisabled'
                : formData && formData[item.key] && item.isMandatory
                ? 'form-control'
                : 'form-control form-control-error'
            }
            id="date"
            minDate={
              agreementData?.start_date
                ? contractAddendumDetails?.notActive // if addendum, then don't change the agreement start date.
                  ? new Date(agreementData?.start_date)
                  : new Date(agreementData?.start_date) > new Date()
                  ? new Date(currentMonthsFirstDate)
                  : new Date(agreementData?.start_date) < currentMonthsFirstDate
                  ? new Date(currentMonthsFirstDate)
                  : new Date(currentMonthsFirstDate)
                : new Date()
            }
            value={
              item.key === 'start_date' && contractAddendumDetails?.notActive
                ? new Date(agreementData?.start_date) // if addendum, then don't change the agreement start date.
                : new Date(startDate) ||
                  (item.key && formData[item.key] && formData[item.key] !== null
                    ? new Date(formData[item.key])
                    : '')
            }
            onChange={(date) => handleChange(date, 'start_date', 'date')}
            format="MM/dd/yyyy"
            clearIcon={null}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            placeholderText="Select Date"
            tileDisabled={(date) =>
              tileDisabled(date, agreementData?.start_date)
            }
            disabled={
              (type !== 'dsp' && contractAddendumDetails.notActive) ||
              (item?.key === 'start_date' &&
                !formData?.change_agreement_start_date)
            }
          />
        );
      }

      if (item?.key === 'addendum_start_date') {
        return (
          <DatePicker
            className={
              !contractAddendumDetails.notActive
                ? 'form-control isDisabled'
                : 'form-control'
            }
            id="date"
            minDate={
              formData?.change_addendum_start_date &&
              new Date(agreementData?.start_date) <= new Date()
                ? currentMonthsFirstDate
                : new Date(agreementData?.start_date) <= new Date()
                ? new Date()
                : new Date(agreementData?.start_date)
            }
            value={
              formData?.addendum_start_date !== null
                ? new Date(formData?.addendum_start_date)
                : new Date()
            }
            onChange={(date) =>
              handleChange(date, 'addendum_start_date', 'date')
            }
            format="MM/dd/yyyy"
            clearIcon={null}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            placeholderText="Select Date"
            tileDisabled={(date) =>
              tileDisabled(date, agreementData?.addendum_start_date)
            }
            disabled={
              item?.key === 'addendum_start_date' &&
              !formData?.change_addendum_start_date
            }
          />
        );
      }
    }
    if (item.type === 'choice') {
      return (
        <ContractInputSelect>
          {generateDropdown(item, type)}
        </ContractInputSelect>
      );
    }

    if (item && item.field === 'customer') {
      return (
        <input
          className={
            type !== 'dsp' && contractAddendumDetails.notActive
              ? 'form-control isDisabled'
              : (customerError && customerError[item?.key]) ||
                (!customer?.[item?.key] && item.isMandatory)
              ? 'form-control form-control-error'
              : 'form-control '
          }
          type="text"
          placeholder={item.placeholder ? item.placeholder : item.label}
          onChange={(event) => handleChange(event, item.key)}
          name={item.key}
          defaultValue={customer?.[item.key]}
          disabled={type !== 'dsp' && contractAddendumDetails.notActive}
        />
      );
    }

    if (item?.type === 'checkbox') {
      return (
        <CheckBox key={item?.key}>
          <label
            className="check-container customer-pannel"
            htmlFor={item?.key}
          >
            {item?.label}
            <input
              type="checkbox"
              name={item?.key}
              id={item?.key}
              value={formData?.[item?.key]}
              checked={formData?.[item?.key]}
              onChange={(event) => handleChange(event, item?.key, 'checkbox')}
            />
            <span className="checkmark" />
          </label>
        </CheckBox>
      );
    }

    return (
      <input
        className={`form-control ${
          formData?.[item?.key] && item?.isMandatory ? '' : 'form-control-error'
        } ${item.key === 'contract_type' ? 'disabled' : ''}`}
        disabled={
          item.key === 'contract_type' ||
          (item.key === 'dsp_one_time_fee' &&
            contractAddendumDetails?.notActive)
        }
        type="text"
        placeholder={item?.placeholder ? item.placeholder : item?.label}
        onChange={(event) => handleChange(event, item.key)}
        name={item.key}
        defaultValue={
          item.key === 'contract_type'
            ? displayContractType()
            : formData?.[item.key]
        }
      />
    );
  };

  const nextStep = (key) => {
    if (key === 'statement') {
      setOpenCollapse({
        agreement: false,
        statement: true,
        dspAddendum: false,
        addendum: false,
      });
      if (contractAddendumDetails?.notActive) executeScroll('draft-addendum');
      else executeScroll('statement');
    }
    if (key === 'dspAddendum') {
      setOpenCollapse({
        ...openCollapse,
        agreement: false,
        statement: false,
        dspAddendum: true,
        addendum: false,
      });
      if (contractAddendumDetails?.notActive) executeScroll('draft-addendum');
      else executeScroll('dspAddendum');
    }
    if (key === 'addendum') {
      setOpenCollapse({
        ...openCollapse,
        agreement: false,
        statement: false,
        dspAddendum: false,
        addendum: true,
      });
      if (contractAddendumDetails?.notActive) executeScroll('draft-addendum');
      else executeScroll('addendum');
    }
    if (key === 'feeStructure') {
      setOpenCollapse({
        ...openCollapse,
        agreement: false,
        statement: false,
        dspAddendum: false,
        addendum: false,
        feeStructure: true,
      });
      if (contractAddendumDetails?.notActive) executeScroll('draft-addendum');
      else executeScroll('feeStructure');
    }
    if (key === 'final') {
      if (newAddendumData) {
        const addendumData = {
          customer_id: id,
          addendum: newAddendumData && newAddendumData.addendum,
          contract_addendum: contractAddendumDetails?.id,
        };
        const contractData = {
          customer_id: id,
          addendum: newAddendumData && newAddendumData.addendum,
          contract: agreementData.id,
        };
        setContractLoading({ loader: true, type: 'page' });

        createAddendum(
          contractAddendumDetails?.notActive ? addendumData : contractData,
        ).then((res) => {
          setCheckAddendumData(true);
          setContractLoading({ loader: false, type: 'page' });
          if (res?.status === 201) {
            setNewAddendum(res && res.data);
            setOpenCollapse({
              ...openCollapse,
              statement: false,
              dspAddendum: false,
              addendum: true,
            });
            if (updatedFormData?.addendum) {
              delete updatedFormData.addendum;
            }

            setUpdatedFormData({ ...updatedFormData });
            if (!Object.keys(updatedFormData).length) {
              getContractDetails();
              setIsEditContract(false);
            }
            toast.success('Changes Saved!');

            if (contractAddendumDetails?.notActive)
              executeScroll('draft-addendum');
            else executeScroll('addendum');
            setShowEditor(false);
            setOriginalAddendumData(res?.data);
            setCheckAddendumData(true);
          }
        });
      }
    }
  };

  const changeQuantity = (oneTimeServiceData, flag) => {
    showFooter(true);

    clearOneTimeQntyError(oneTimeServiceData);
    if (additionalOneTimeServicesLength) {
      const changedService = formData.additional_one_time_services.filter(
        (item) => {
          if (
            item.service_id
              ? item.service_id === oneTimeServiceData.value
              : item.service?.id === oneTimeServiceData.value
          ) {
            let quantity = 0;
            if (item.quantity) {
              quantity = parseInt(item.quantity, 10);
            }
            if (flag === 'add') {
              if (quantity < 999) {
                quantity += 1;
                item.quantity = quantity;
              }
            }
            if (flag === 'minus') {
              if (quantity > 1) {
                quantity -= 1;
                item.quantity = quantity;
              }
            }
          }
          return item;
        },
      );

      setFormData({
        ...formData,
        additional_one_time_services: changedService,
      });
      setUpdatedFormData({
        ...updatedFormData,
        additional_one_time_services: {
          ...additionalOnetimeServices,
          create: changedService,
        },
      });
    }
  };

  const displayError = (item) => {
    if (item === 'non_field_errors') {
      return (
        <ErrorMsg>
          {apiError?.non_field_errors && apiError.non_field_errors[0]}
        </ErrorMsg>
      );
    }
    if (item === 'custom_amazon_store_price') {
      return (
        <ErrorMsg>
          {additionalOnetimeSerError?.custom_amazon_store_price}
        </ErrorMsg>
      );
    }

    return (
      <>
        <ErrorMsg>
          {apiError && apiError[item.key] && apiError[item.key][0]}
        </ErrorMsg>
        <ErrorMsg>
          {contractError &&
            contractError[item.key] &&
            contractError[item.key][0]}
        </ErrorMsg>
        <ErrorMsg>
          {customerError &&
            customerError[item.key] &&
            customerError[item.key][0]}
        </ErrorMsg>
        <ErrorMsg>
          {additionalOnetimeSerError &&
            additionalOnetimeSerError[item.key] &&
            additionalOnetimeSerError[item.key][0]}
        </ErrorMsg>
        <ErrorMsg>
          {additionalMonthlySerError &&
            additionalMonthlySerError[item.key] &&
            additionalMonthlySerError[item.key][0]}
        </ErrorMsg>
        <ErrorMsg>
          {additionalMarketplaceError &&
            additionalMarketplaceError[item.key] &&
            additionalMarketplaceError[item.key][0]}
        </ErrorMsg>
      </>
    );
  };

  const onAddDiscount = (flag, accntType = '', isAMCService = false) => {
    setDiscountFlag({
      serviceType: flag,
      accountType: accntType,
      is_amc_service_discount: isAMCService,
    });
    const result =
      discountData?.length &&
      discountData.filter(
        (item) => item.service_type === flag && item.account_type === accntType,
      );
    if (result) {
      setSelectedDiscount(result);
    }

    setShowDiscountModal(true);
  };

  const onThresholdTypeChange = (event, key, threshold) => {
    setSelectedThreshold(threshold);
    handleChange(event, key);
  };

  const renderRedCrossImgHtml = () => {
    return <img className="red-cross" src={RedCross} alt="right-check" />;
  };
  const renderGreenCheckImgHtml = () => {
    return (
      <img className="green-check-select" src={GreenCheck} alt="right-check" />
    );
  };

  const renderCollapseBtnErrorHtml = (
    isOneTimeService,
    errorTypeCount,
    collapseType,
    collapseSection,
  ) => {
    return showRightTick(collapseSection) ? (
      renderGreenCheckImgHtml()
    ) : (
      <>
        {renderRedCrossImgHtml()}
        <div className="error-found"> Missing Information</div>
      </>
    );
  };

  const displayEditFields = () => {
    return (
      <div className="sidebar">
        <>
          {isEditContract ? (
            <>
              <ServiceAgreementSection
                setFormData={setFormData}
                updatedFormData={updatedFormData}
                setUpdatedFormData={setUpdatedFormData}
                setAdditionalOnetimeServices={setAdditionalOnetimeServices}
                executeScroll={executeScroll}
                setOpenCollapse={setOpenCollapse}
                openCollapse={openCollapse}
                agreementErrCount={agreementErrCount}
                statementErrCount={statementErrCount}
                renderCollapseBtnErrorHtml={renderCollapseBtnErrorHtml}
                loader={loader}
                generateHTML={generateHTML}
                displayError={displayError}
                showRightTick={showRightTick}
                additionalOneTimeServicesLength={
                  additionalOneTimeServicesLength
                }
                formData={formData}
                nextStep={nextStep}
                onAddDiscount={onAddDiscount}
                additionalMonthlySerError={additionalMonthlySerError}
                oneTimeService={oneTimeService}
                handleChange={handleChange}
                changeQuantity={changeQuantity}
                additionalOnetimeSerError={additionalOnetimeSerError}
                setShowAmazonPlanDropdown={setShowAmazonPlanDropdown}
                amazonService={amazonService}
                showAmazonPlanDropdown={showAmazonPlanDropdown}
                AmazonStoreOptions={AmazonStoreOptions}
                amazonStoreCustom={amazonStoreCustom}
                setAmazonStoreCustom={setAmazonStoreCustom}
                showFooter={showFooter}
                additionalOnetimeServices={additionalOnetimeServices}
                clearOneTimeQntyError={clearOneTimeQntyError}
                updateAdditionalOnetimeServicesSelectedData={
                  updateAdditionalOnetimeServicesSelectedData
                }
                discountData={discountData}
                servicesFees={servicesFees}
                selectedCurrency={selectedCurrency}
                notIncludedOneTimeServices={notIncludedOneTimeServices}
                setNotIncludedOneTimeServices={setNotIncludedOneTimeServices}
                showAmazonStorefrontDropdown={showAmazonStorefrontDropdown}
                setShowAmazonStorefrontDropdown={
                  setShowAmazonStorefrontDropdown
                }
                setAdditionalOnetimeSerError={setAdditionalOnetimeSerError}
                details={details}
                updateStatementErrorValue={updateStatementErrorValue}
                sectionError={sectionError}
                contractAddendumDetails={contractAddendumDetails}
              />

              {agreementData &&
              agreementData.contract_type?.includes('recurring') ? (
                <FeeStructureContainer
                  setOpenCollapse={setOpenCollapse}
                  openCollapse={openCollapse}
                  executeScroll={executeScroll}
                  formData={formData}
                  setFormData={setFormData}
                  setUpdatedFormData={setUpdatedFormData}
                  updatedFormData={updatedFormData}
                  loader={loader}
                  nextStep={nextStep}
                  DropdownIndicator={DropdownIndicator}
                  renderCollapseBtnErrorHtml={renderCollapseBtnErrorHtml}
                  revShareOptions={revShare}
                  marketPlacesOptions={marketPlaces}
                  setAdditionalMarketplaces={setAdditionalMarketplaces}
                  thresholdTypeOptions={thresholdTypeOptions}
                  selectedThreshold={selectedThreshold}
                  showFooter={showFooter}
                  sectionError={sectionError}
                  setSectionError={setSectionError}
                  feeStructureErrors={feeStructureErrors}
                  setFeeStructureErrors={setFeeStructureErrors}
                  getMonthlyServices={getMonthlyServices}
                  showRightTick={showRightTick}
                  getFeeStructureDetails={getFeeStructureDetails}
                  manageErrorCount={manageErrorCount}
                  checkMandatoryFieldsOfFeeType={checkMandatoryFieldsOfFeeType}
                  agreementCurrency={agreementCurrency}
                  selectedCurrency={selectedCurrency}
                  disableCombineRevShare={disableCombineRevShare}
                  contractAddendumDetails={contractAddendumDetails}
                  checkAddendumData={checkAddendumData}
                  addendumDetails={addendumDetails}
                  displayMarketplaceName={displayMarketplaceName}
                  savedMarketplaces={savedMarketplaces.recurring}
                  handleAddendumModal={handleAddendumModal}
                />
              ) : (
                ''
              )}

              <StatementOfWork
                setAdditionalOnetimeServices={setAdditionalOnetimeServices}
                executeScroll={executeScroll}
                setOpenCollapse={setOpenCollapse}
                openCollapse={openCollapse}
                statementErrCount={statementErrCount}
                renderCollapseBtnErrorHtml={renderCollapseBtnErrorHtml}
                loader={loader}
                generateHTML={generateHTML}
                displayError={displayError}
                apiError={apiError}
                thresholdTypeOptions={thresholdTypeOptions}
                onThresholdTypeChange={onThresholdTypeChange}
                selectedThreshold={selectedThreshold}
                formData={formData}
                contractErrorSalesThreshold={contractErrorSalesThreshold}
                contractError={contractError}
                handleChange={handleChange}
                onAddDiscount={onAddDiscount}
                monthlyService={monthlyService}
                showRightTick={showRightTick}
                nextStep={nextStep}
                showAdditionalMarketplace={showAdditionalMarketplace}
                setShowAdditionalMarketplace={setShowAdditionalMarketplace}
                showSection={showSection}
                yoyPercentageOptions={yoyPercentageOptions}
                additionalMonthlySerError={additionalMonthlySerError}
                oneTimeService={oneTimeService}
                changeQuantity={changeQuantity}
                additionalOnetimeSerError={additionalOnetimeSerError}
                setShowAmazonPlanDropdown={setShowAmazonPlanDropdown}
                amazonService={amazonService}
                showAmazonPlanDropdown={showAmazonPlanDropdown}
                amazonStoreCustom={amazonStoreCustom}
                setAmazonStoreCustom={setAmazonStoreCustom}
                AmazonStoreOptions={AmazonStoreOptions}
                showFooter={showFooter}
                setContractError={setContractError}
                setFormData={setFormData}
                setUpdatedFormData={setUpdatedFormData}
                updatedFormData={updatedFormData}
                additionalOnetimeServices={additionalOnetimeServices}
                clearOneTimeQntyError={clearOneTimeQntyError}
                updateAdditionalOnetimeServicesSelectedData={
                  updateAdditionalOnetimeServicesSelectedData
                }
                DropdownIndicator={DropdownIndicator}
                setShowCollpase={setShowCollpase}
                originalData={originalData}
                additionalMonthlyServices={additionalMonthlyServices}
                updateAdditionalMonthlyServices={
                  updateAdditionalMonthlyServices
                }
                setMonthlyAdditionalServices={setMonthlyAdditionalServices}
                fetchUncommonOptions={fetchUncommonOptions}
                getOptions={getOptions}
                additionalMarketplacesData={additionalMarketplacesData}
                agreementData={agreementData}
                setAdditionalMarketplaces={setAdditionalMarketplaces}
                updateAdditionalMarketplaces={updateAdditionalMarketplaces}
                setAdditionalMarketplace={setAdditionalMarketplace}
                setMarketPlaces={setMarketPlaces}
                marketplacesResult={marketplacesResult}
                discountData={discountData}
                servicesFees={servicesFees}
                getData={getData}
                selectedCurrency={selectedCurrency}
                stdServices={stdServices}
                setNonUSserviceError={setNonUSserviceError}
                nonUsServiceError={nonUsServiceError}
                updateStatementErrorValue={updateStatementErrorValue}
                sectionError={sectionError}
                notIncludedOneTimeServices={notIncludedOneTimeServices}
                setNotIncludedOneTimeServices={setNotIncludedOneTimeServices}
                showAmazonStorefrontDropdown={showAmazonStorefrontDropdown}
                setShowAmazonStorefrontDropdown={
                  setShowAmazonStorefrontDropdown
                }
                setAdditionalOnetimeSerError={setAdditionalOnetimeSerError}
                details={details}
                addendumDetails={addendumDetails}
                contractAddendumDetails={contractAddendumDetails}
                displayMarketplaceName={displayMarketplaceName}
                setVatMarketplaces={setVatMarketplaces}
                combinedOneTimeServices={combinedOneTimeServices}
                filterNotIncludedOneTimeServices={
                  filterNotIncludedOneTimeServices
                }
                savedMarketplaces={savedMarketplaces.recurring}
                advertisingServices={advertisingServices}
                amcServiceError={amcServiceError}
                setAmcServiceError={setAmcServiceError}
                getAddedOrRemovedAMCServices={getAddedOrRemovedAMCServices}
              />
              {formData?.contract_type?.includes('dsp') ||
              (formData?.contract_type?.includes('recurring') &&
                formData?.is_dsp_advertising) ? (
                <DspAdvertising
                  executeScroll={executeScroll}
                  loader={loader}
                  showSection={showSection}
                  setOpenCollapse={setOpenCollapse}
                  openCollapse={openCollapse}
                  dspErrCount={dspErrCount}
                  renderCollapseBtnErrorHtml={renderCollapseBtnErrorHtml}
                  showRightTick={showRightTick}
                  generateHTML={generateHTML}
                  displayError={displayError}
                  formData={formData}
                  nextStep={nextStep}
                  dspAdditionalMarketplaces={dspAdditionalMarketplaces}
                  showFooter={showFooter}
                  setFormData={setFormData}
                  setUpdatedFormData={setUpdatedFormData}
                  updatedFormData={updatedFormData}
                  originalData={originalData}
                  sectionError={sectionError}
                  setSectionError={setSectionError}
                  contractAddendumDetails={contractAddendumDetails}
                  addendumDetails={addendumDetails}
                  setCalculatedDate={setCalculatedDate}
                  setFirstMonthDate={setFirstMonthDate}
                  setEndDate={setEndDate}
                  selectedCurrency={selectedCurrency}
                  runValidateBudget={runValidateBudget}
                  setDspAdvertisingMaxDate={setDspAdvertisingMaxDate}
                  changedDspStartDateFlag={changedDspStartDateFlag}
                  setChangedDspStartDateFlag={setChangedDspStartDateFlag}
                  dspMarketpalcesError={dspMarketpalcesError}
                  setDspMarketpalcesError={setDspMarketpalcesError}
                  displayMarketplaceName={displayMarketplaceName}
                  savedMarketplaces={savedMarketplaces}
                  returnDspMonthlyDates={returnDspMonthlyDates}
                  firstMonthDate={firstMonthDate}
                  secondMonthDate={secondMonthDate}
                  thirdMonthDate={thirdMonthDate}
                  advertisingServices={advertisingServices}
                  details={details}
                  setShowCollpase={setShowCollpase}
                  amcServiceError={amcServiceError}
                  setAmcServiceError={setAmcServiceError}
                  onAddDiscount={onAddDiscount}
                  discountData={discountData}
                />
              ) : (
                ''
              )}
              <div className="straight-line horizontal-line  " />
              <AddendumSection
                showSection={showSection}
                executeScroll={executeScroll}
                setOpenCollapse={setOpenCollapse}
                openCollapse={openCollapse}
                loader={loader}
                newAddendumData={newAddendumData}
                onEditAddendum={onEditAddendum}
                showEditor={showEditor}
                setShowEditor={setShowEditor}
                setNewAddendum={setNewAddendum}
                nextStep={nextStep}
                setShowCollpase={setShowCollpase}
                setUpdatedFormData={setUpdatedFormData}
                showFooter={showFooter}
                updatedFormData={updatedFormData}
                originalAddendumData={originalAddendumData}
                contractAddendumDetails={contractAddendumDetails}
              />
            </>
          ) : (
            <>
              <ContractActivityLog
                activityLoader={activityLoader}
                activityData={activityData}
                images={images}
                activityCount={activityCount}
                pageNumber={pageNumber}
                isApicalled={isApicalled}
                agreementData={agreementData}
                setPageNumber={setPageNumber}
                getContractActivityLogInfo={getContractActivityLogInfo}
                loader={loader}
                checkContractStatus={checkContractStatus}
                contractAddendumDetails={contractAddendumDetails}
                role={role}
              />
            </>
          )}
        </>
      </div>
    );
  };

  return (
    <SidePanel
      className={
        !contractAddendumDetails?.notActive &&
        (agreementDataContractStatusValue === 'pending account setup' ||
          agreementDataContractStatusValue === 'active' ||
          agreementDataContractStatusValue === 'inactive')
          ? 'pdf-sidebar'
          : 'contract-sidebar'
      }
    >
      {displayEditFields()}
    </SidePanel>
  );
}
AgreementSidePanelContainer.defaultProps = agreementDefaultProptypes;

AgreementSidePanelContainer.propTypes = agreementSidePanelPropTypes;
