/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// Third party Imports
import dayjs from 'dayjs';
import Modal from 'react-modal';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, shape, string } from 'prop-types';

// Local Imports
import Theme from '../../../../theme/Theme';
import AgreementDetailTabMobView from './AgreementDetailTabMobView';
import AgreementDetailOuterContainer from './AgreementDetailsStyled';
import ShowCurrencySymbol from '../../../../common/ShowCurrencySymbol';
import PendingAgreementModal from '../Modals/PendingAgreementModal';
import {
  getMarketplaces,
  getNonUSStandardServicesMarketplaces,
} from '../../../../api';
import {
  agreementDSP,
  agreementRetainer,
  agreementRetainerRevShare,
  agreementRevShare,
  agreementToggleFeeType,
  PATH_AGREEMENT,
  quarterlyRetainerOptions,
  monthlyRetainerOptions,
  agreementDSPAdv,
  contractStatusIcons,
  agreementStatusNames,
  contractOptionsWithPaused,
  OptimizationArray,
} from '../../../../constants';
import {
  CaretUp,
  ClockIcon,
  DisabledRecurring,
  DspOnlyIcon,
  FileContract,
  BrokenFile,
  OrangeInfoIcon,
  RecurringIcon,
} from '../../../../theme/images';
import {
  ActionDropDown,
  Button,
  CardMetrics,
  Status,
  ToggleButton,
  WhiteCard,
} from '../../../../common';

export default function AgreementDetails({
  id,
  history,
  userRole,
  IconOption,
  DropdownIndicator,
  multipleAgreement,
  setDropdownOptions,
  draftContractOptions,
  handleContractOptions,
  getPauseHTML,
  isSpreetailUser,
}) {
  const customerState = useSelector((state) => state.customerState.data);

  const isDesktop = useMediaQuery({ minWidth: 991 });
  const [agreementDetailsCollapse, setAgreementDetailsCollapse] = useState({});
  const [accountType, setAccountType] = useState({});
  const [showMoreCount, setShowMoreCount] = useState([]);
  const [marketplacesList, setMarketplacesList] = useState([]);
  const [showModal, setShowModal] = useState({
    modal: false,
    type: null,
    id: null,
  });
  const getCurrentMonth = dayjs().get('month');
  const customStyles = {
    content: {
      top: '50%',
      right: '0px',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      maxHeight: '100%',
      overlay: ' {zIndex: 1000}',
      inset: '0% 0% 0% auto',
      marginRight: '0',
      borderRadius: '0px !important',
    },
  };

  const displayMarketplacesName = (value, type) => {
    const marketplaceArray =
      type === 'vat' ? marketplacesList?.vat : marketplacesList?.marketplace;
    return marketplaceArray?.find((op) => value === op.value)?.label;
  };

  useEffect(() => {
    const fields = [];
    const counter = 0;
    getMarketplaces('amazon').then((res) => {
      setMarketplacesList({ marketplace: res?.data });
      getNonUSStandardServicesMarketplaces().then((market) => {
        setMarketplacesList((prevState) => ({ ...prevState, vat: market }));
      });
      for (const service of multipleAgreement) {
        if (
          service.content_optimization ||
          service.design_optimization ||
          service.photography_optimization ||
          service.copy_listing_translation ||
          service.creative_listing_translation
        ) {
          fields.push({ [service.id]: counter + 1 });
        }
        if (service.standard_us_services?.length > 0) {
          fields.push({ [service.id]: counter + 1 });
        }
        if (service?.additional_marketplaces?.length > 0) {
          fields.push({ [service.id]: counter + 1 });
        }
        if (service?.additional_monthly_services?.length > 0) {
          fields.push({ [service.id]: counter + 1 });
        }
        if (service?.amc_services?.length > 0) {
          fields.push({ [service.id]: counter + 1 });
        }
        if (service?.additional_one_time_services?.length > 0) {
          fields.push({ [service.id]: counter + 1 });
        }

        if (
          service?.standard_non_us_services?.length &&
          service?.standard_non_us_services?.find(
            (op) => op.name === 'VAT Filing',
          )
        ) {
          fields.push({ [service.id]: counter + 1 });
        }
        if (
          service?.standard_non_us_services?.length &&
          service?.standard_non_us_services?.find(
            (op) => op.name === 'VAT Registration',
          )
        ) {
          fields.push({ [service.id]: counter + 1 });
        }
      }
      setShowMoreCount(fields);
    });
  }, []);

  useEffect(() => {
    for (const item of multipleAgreement) {
      setAccountType((prevState) => ({
        ...prevState,
        [item.id]:
          item?.seller_type?.label === 'Hyrbid'
            ? accountType[item.id]
            : item?.seller_type?.label === 'Hybrid'
            ? 'seller'
            : item?.seller_type?.label?.toLowerCase(),
      }));
    }
  }, [multipleAgreement, setAccountType]);

  const countDays = (value) => {
    const date1 = new Date();
    const date2 = new Date(value);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const generateBasicInfo = (label, value) => {
    return (
      <li className="other-details">
        <div className="label">{label}</div>
        <div className="label-info text-medium-font">
          {value === 'Invalid Date' ? '' : value}
        </div>
      </li>
    );
  };

  const toggleAccountType = (contractId) => {
    return (
      <ToggleButton>
        <div className="days-container toggle-container">
          <ul className="days-tab ">
            {agreementToggleFeeType.map((item) => (
              <li
                className={
                  accountType[contractId] === item.key
                    ? 'account-type active'
                    : 'account-type'
                }
                role="presentation"
                onClick={() =>
                  setAccountType({
                    ...accountType,
                    [contractId]: item.key,
                  })
                }
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </ToggleButton>
    );
  };
  const showActionDropdown = (agreement) => {
    return (
      <div
        className={
          isDesktop
            ? agreement?.seller_type?.label === 'Hybrid' &&
              agreement?.contract_type?.includes('recurring')
              ? ' col-md-5 col-12 text-right pl-0 mt-2'
              : ' col-md-3 col-12 text-right mt-2'
            : ''
        }
        role="presentation"
        onClick={() =>
          localStorage.setItem(
            'agreementID',
            agreement.id,
            agreement?.contract_addendum?.[0]?.id,
          )
        }
      >
        {userRole === 'Customer' ? (
          <ul className="right-side-content">
            {isDesktop ? (
              <>
                {agreement?.seller_type?.label === 'Hybrid' &&
                agreement?.contract_type?.includes('recurring') ? (
                  <li className="toggle-dropdown w-70 mb-3">
                    {' '}
                    {toggleAccountType(agreement.id)}{' '}
                  </li>
                ) : null}
              </>
            ) : null}
            <Link
              to={{
                pathname: PATH_AGREEMENT.replace(':id', id).replace(
                  ':contract_id',
                  agreement.id,
                ),
                state: history?.location?.search,
              }}
            >
              <Button className="btn-transparent w-50 view-contract">
                {' '}
                <img className="file-contract-icon" src={FileContract} alt="" />
                View Agreement
              </Button>
            </Link>
          </ul>
        ) : (
          <>
            <ul className="right-side-content">
              {isDesktop ? (
                <>
                  {agreement?.seller_type?.label === 'Hybrid' &&
                  agreement?.contract_type?.includes('recurring') ? (
                    <li className="toggle-dropdown w-70 mb-3">
                      {' '}
                      {toggleAccountType(agreement.id)}{' '}
                    </li>
                  ) : null}
                </>
              ) : null}

              <li
                className={
                  agreement?.seller_type?.label === 'Hybrid' &&
                  agreement?.contract_type?.includes('recurring')
                    ? 'toggle-dropdown '
                    : 'toggle-dropdown w-50'
                }
              >
                {(userRole !== 'Customer' &&
                  agreement?.draft_from &&
                  (agreement?.contract_status?.value === 'active' ||
                    agreement?.contract_status?.value === 'renewed' ||
                    agreement?.contract_status?.value === 'pause' ||
                    agreement?.contract_status?.value ===
                      'active pending for pause' ||
                    agreement?.contract_status?.value ===
                      'active pending for pause request' ||
                    agreement?.contract_status?.value ===
                      'pending cancellation approval' ||
                    agreement?.contract_status?.value ===
                      'pending for cancellation')) ||
                (userRole !== 'Customer' &&
                  !agreement?.draft_from &&
                  agreement?.contract_status?.value !== 'pending contract' &&
                  agreement?.contract_status?.value !==
                    'pending contract approval' &&
                  agreement?.contract_status?.value !==
                    'pending contract signature') ? (
                  <ActionDropDown>
                    {' '}
                    <Select
                      classNamePrefix="react-select"
                      placeholder="View Actions"
                      className="active"
                      options={setDropdownOptions(agreement)}
                      onChange={(event) =>
                        handleContractOptions(
                          event,
                          agreement.id,
                          agreement?.contract_addendum?.[0]?.id,
                        )
                      }
                      components={{
                        DropdownIndicator,
                        Option: IconOption,
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: Theme.lightOrange,
                          primary: Theme.orange,
                          primary50: Theme.lightOrange,
                        },
                      })}
                      value=""
                      isSearchable={false}
                    />
                  </ActionDropDown>
                ) : (
                  <ActionDropDown>
                    {' '}
                    <Select
                      classNamePrefix="react-select"
                      placeholder="View Actions"
                      className="active"
                      options={
                        isSpreetailUser
                          ? contractOptionsWithPaused
                          : userRole !== 'Customer' && // condition added for PDV-8257 sub brand ticket
                            (customerState?.account_type === 'standalone' || // if customer is standalon or child with active contract
                              (customerState?.account_type === 'child' && // then only show Edit Agreement option otherwise hide it from option
                                customerState?.active_contract))
                          ? draftContractOptions
                          : draftContractOptions?.filter(
                              (item) => item?.value !== 'edit',
                            )
                      }
                      onChange={(event) =>
                        handleContractOptions(event, agreement.id)
                      }
                      components={{
                        DropdownIndicator,
                        Option: IconOption,
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: Theme.lightOrange,
                          primary: Theme.orange,
                          primary50: Theme.lightOrange,
                        },
                      })}
                      value=""
                    />
                  </ActionDropDown>
                )}
              </li>
            </ul>
          </>
        )}
      </div>
    );
  };

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  const displayQuarterMonth = (feeDetails, array, currency) => {
    if (feeDetails) {
      const fields = [];
      for (const item of array) {
        fields.push(`
      <div class="col-6">
             <div style="color: #f4f6fc;
            text-transform: uppercase;
            font-size: 11px;
            margin-top: 8px;
           ">${item.label}</div>
            </div>
             <div class="col-6">
               <div style="color: #f4f6fc;
                font-size: 16px;
                margin-left: 25px;
                float: right;
                text-align: right;
                margin-top: 4px;
               "> ${ShowCurrencySymbol(currency)}${displayNumber(
          feeDetails[item.key],
        )}
             </div>
             </div>
  `);
      }
      return fields.length ? fields.toString().replaceAll(' ,', '') : 'No Data';
    }
    return null;
  };

  const checkQuarterMonthType = (feeDetails, currency, item) => {
    if (feeDetails?.[item.subType] === 'quarterly') {
      if (item.key === 'monthly_retainer') {
        return `
       ${displayQuarterMonth(
         feeDetails?.quarterly_monthly_retainer,
         quarterlyRetainerOptions,
         currency,
       )}`;
      }
      if (item.key === 'sales_threshold') {
        return `
       ${displayQuarterMonth(
         feeDetails?.quarterly_rev_share,
         quarterlyRetainerOptions,
         currency,
       )}`;
      }
    }
    if (feeDetails?.[item.subType] === 'monthly') {
      if (item.key === 'monthly_retainer') {
        return `
       ${displayQuarterMonth(
         feeDetails?.monthly_monthly_retainer,
         monthlyRetainerOptions,
         currency,
       )}`;
      }
      if (item.key === 'sales_threshold') {
        return `
       ${displayQuarterMonth(
         feeDetails?.monthly_rev_share,
         monthlyRetainerOptions,
         currency,
       )}`;
      }
    }
    return null;
  };

  const getQuarterMonthData = (feeDetails, currency, item) => {
    return `
      <div style="padding:0; margin: 0 0 4px 0; max-width: 240px; width: 100%;opacity: 100%;">
      <div class="row">
      ${checkQuarterMonthType(feeDetails, currency, item)}
      </div>
      </div>`;
  };

  const showDSPMarketplace = (marketplaces) => {
    const dspMarketplaces = [];
    for (const item of marketplaces) {
      dspMarketplaces.push(`
       <div class='row'>
        <div class="col-12">
          <div style="color: #f4f6fc;
            font-size: 11px;
            margin-top: 8px;
           ">${displayMarketplacesName(item.name)}</div>
          </div>
        </div>
      </div>
  `);
    }
    return dspMarketplaces.length
      ? dspMarketplaces.toString().replaceAll(' ,', '')
      : 'No Data';
  };

  const displayAdBudget = (agreement, item) => {
    const fields = [];
    fields.push(`
             <div class="col-6">
             <div style="color: #f4f6fc;
            text-transform: uppercase;
            font-size: 11px;
            margin-top: 8px;
           ">${item.label}</div>
            </div>
             <div class="col-6">
               <div style="color: #f4f6fc;
                font-size: 16px;
                margin-left: 25px;
                float: right;
                text-align: right;
                margin-top: 4px;
               "> $${displayNumber(agreement?.[item.key])}
             </div>
             </div>
       `);
    return fields.length ? fields.toString().replaceAll(' ,', '') : 'No Data';
  };

  const getAdBudgetData = (agreement) => {
    return `
      <div style="padding:0; margin: 0 0 4px 0; max-width: 240px; width: 100%;opacity: 100%;">
      <div class="row">
      ${displayAdBudget(agreement, {
        key: 'dsp_fee',
        label: `${dayjs(
          agreement?.dsp_marketplaces?.[0]?.monthly_budget_date,
        ).format('MMMM YY')}`,
        type: 'number-currency',
        prefix: '$',
      })}
      ${displayAdBudget(agreement, {
        key: 'dsp_fee_second',
        label: `${dayjs(
          agreement?.dsp_marketplaces?.[0]?.monthly_budget_second_date,
        ).format('MMMM YY')}`,
        type: 'number-currency',
        prefix: '$',
      })}
      ${displayAdBudget(agreement, {
        key: 'dsp_fee_third',
        label: `${dayjs(
          agreement?.dsp_marketplaces?.[0]?.monthly_budget_third_date,
        ).format('MMMM YY')}`,
        type: 'number-currency',
        prefix: '$',
      })}
      </div>
      </div>`;
  };

  const showDynamicDspMonthlyAdBudget = (agreement) => {
    return (
      <>
        <ReactTooltip
          id="view-dynamic-ad-budget"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          backgroundColor="#162c50"
          multiline
          html
        />
        <div
          data-tip={getAdBudgetData(agreement)}
          data-for="view-dynamic-ad-budget"
          data-html
          className="breakdown-label mt-2"
        >
          View Other Ad Budget
        </div>
      </>
    );
  };

  const generateDSPAdvHTML = (agreement) => {
    return agreementDSPAdv.map((item) => {
      if (agreement?.[item.key] || agreement?.[item.key]?.label) {
        return (
          <li className="mb-lg-0 mb-3">
            <CardMetrics className="fix-height">
              <div className="order-chart-box no-hover fix-height small-metrics-card ">
                <div className="row">
                  <div className="col-12">
                    <div className="label pr-3">{item.label}</div>
                    <div className="label-info text-medium-font">
                      {item.key === 'dsp_marketplaces' ? (
                        <>
                          {displayMarketplacesName(
                            agreement?.[item.key]?.[0]?.name,
                            'dsp',
                          )}{' '}
                          {agreement?.[item.key]?.length > 1
                            ? ` + ${agreement?.[item.key]?.length - 1} More`
                            : null}
                          <>
                            {agreement?.[item.key]?.length > 1 ? (
                              <>
                                <ReactTooltip
                                  id="view-dsp"
                                  aria-haspopup="true"
                                  place="bottom"
                                  effect="solid"
                                  backgroundColor="#162c50"
                                  multiline
                                  html
                                />
                                <div
                                  data-tip={showDSPMarketplace(
                                    agreement?.[item.key]?.filter(
                                      (op) =>
                                        op.name !==
                                        agreement?.[item.key]?.[0]?.name,
                                    ),
                                  )}
                                  data-for="view-dsp"
                                  data-html
                                  className="breakdown-label mt-2"
                                >
                                  View Marketplace(s)
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      ) : (
                        <>
                          {item.type === 'text' ? (
                            agreement?.[item?.key]?.label || ''
                          ) : agreement?.[item?.key] ? (
                            <>
                              {ShowCurrencySymbol(agreement?.currency?.value)}
                              {displayNumber(agreement?.[item?.key])}
                              {item?.key === 'dsp_fee' &&
                              agreement?.is_dynamic_dsp_fee
                                ? showDynamicDspMonthlyAdBudget(agreement)
                                : ''}
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardMetrics>
          </li>
        );
      }
      return null;
    });
  };

  const showFirstValue = (feeDetails, currency, item) => {
    if (feeDetails?.[item.subType] === 'quarterly') {
      const qtrRange = quarterlyRetainerOptions.find((op) =>
        op.range.includes(getCurrentMonth),
      )?.key;
      if (item.key === 'monthly_retainer') {
        return `${ShowCurrencySymbol(currency)}${displayNumber(
          feeDetails?.quarterly_monthly_retainer?.[qtrRange],
        )}`;
      }
      if (item.key === 'sales_threshold') {
        return `${ShowCurrencySymbol(currency)}${displayNumber(
          feeDetails?.quarterly_rev_share?.[qtrRange],
        )}`;
      }
    }

    if (feeDetails?.[item.subType] === 'monthly') {
      const monthRange = monthlyRetainerOptions.find(
        (op, index) => index === getCurrentMonth,
      )?.key;
      if (item.key === 'monthly_retainer') {
        return `${ShowCurrencySymbol(currency)}${displayNumber(
          feeDetails?.monthly_monthly_retainer?.[monthRange],
        )}`;
      }
      if (item.key === 'sales_threshold') {
        return `${ShowCurrencySymbol(currency)}${displayNumber(
          feeDetails?.monthly_rev_share?.[monthRange],
        )}`;
      }
    }
    return null;
  };

  const checkMonthQuarterCondition = (feeDetails, item) => {
    if (feeDetails?.[item.subType] === 'quarterly') {
      if (item.key === 'monthly_retainer') {
        return false;
      }
      if (item.key === 'sales_threshold') {
        return false;
      }
    }
    if (feeDetails?.[item.subType] === 'monthly') {
      if (item.key === 'monthly_retainer') {
        return false;
      }
      if (item.key === 'sales_threshold') {
        return false;
      }
    }
    return true;
  };

  const generateCardHTML = (agreement, contractType) => {
    const feeDetails = contractType?.includes('dsp')
      ? agreement
      : agreement?.fee_structure?.[accountType[agreement.id]];
    const feeType =
      agreement?.fee_structure?.[accountType[agreement.id]]?.fee_type;

    const cardArray = contractType?.includes('dsp')
      ? agreementDSP
      : feeType === 'Retainer + % Rev Share'
      ? agreementRetainerRevShare
      : feeType === 'Retainer Only'
      ? agreementRetainer
      : feeType === 'Revenue Share Only'
      ? agreementRevShare
      : [
          {
            key: 'fee_type',
            label: 'Fee Type',
            type: 'text',
          },
        ];

    return (
      <>
        {cardArray.map((item) => {
          const isCombinedRevShare =
            item.key === 'rev_share' &&
            agreement?.seller_type?.label === 'Hybrid';
          if (
            feeDetails?.[item.key] ||
            feeDetails?.[item.key]?.label ||
            feeDetails?.[item.subType] === 'monthly' ||
            feeDetails?.[item.subType] === 'quarterly'
          ) {
            return (
              <li className="mb-lg-0 mb-3 mt-lg-3 mt-0">
                <CardMetrics className="fix-height ">
                  <div className="order-chart-box no-hover fix-height small-metrics-card ">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="label pr-3">{item?.label}</div>
                        <div className="label-info text-medium-font ">
                          {checkMonthQuarterCondition(feeDetails, item) ? (
                            <>
                              {item.type === 'text' ? (
                                <>
                                  {!feeDetails?.[item?.key]?.label
                                    ? feeDetails?.[item?.key] ===
                                      'Retainer + % Rev Share'
                                      ? 'Retainer + Rev Share'
                                      : feeDetails?.[item?.key]
                                    : feeDetails?.[item?.key]?.label}
                                </>
                              ) : (
                                <>
                                  <NumberFormat
                                    displayType="text"
                                    isNumericString
                                    value={
                                      !feeDetails?.[item?.key]?.label
                                        ? feeDetails?.[item?.key]
                                        : feeDetails?.[item?.key]?.label
                                    }
                                    thousandSeparator
                                    prefix={
                                      item.prefix
                                        ? ShowCurrencySymbol(
                                            agreement?.currency?.value,
                                          )
                                        : null
                                    }
                                    suffix={
                                      item.key === 'agency_fee' &&
                                      feeDetails?.[item?.key]?.label?.includes(
                                        '%',
                                      )
                                        ? ''
                                        : item.suffix
                                    }
                                  />
                                  {item?.key === 'dsp_fee' &&
                                  agreement?.is_dynamic_dsp_fee
                                    ? showDynamicDspMonthlyAdBudget(agreement)
                                    : null}
                                </>
                              )}
                              {isCombinedRevShare ? (
                                <>
                                  <ReactTooltip
                                    id="view-combinedRevShare"
                                    aria-haspopup="true"
                                    place="bottom"
                                    effect="solid"
                                    backgroundColor="#162c50"
                                    multiline
                                    html
                                  />
                                  <div
                                    data-tip={`
                                    <div style="padding:0; margin: 0 0 4px 0; max-width: 240px; width: 100%;opacity: 100%;">
                                    ${
                                      agreement?.combined_rev_share
                                        ? `<p style='text-transform: uppercase; text-align: left;'>Combining SC/VC Sales</p>
                                        <p style='text-align: left;'>Applying Rev Share % and Sales Threshold after combining SC & VC Sales.</p>`
                                        : `<p style='text-transform: uppercase;text-align: left;'>Separating SC/VC Sales</p>
                                        <p style='text-align: left;'>Applying Rev Share % and Sales Threshold separately to each of SC Sales & VC Sales.</p>`
                                    }
                                    </div>`}
                                    data-for="view-combinedRevShare"
                                    data-html
                                    className="breakdown-label mt-2"
                                  >
                                    Calculation Method{' '}
                                    <img
                                      src={OrangeInfoIcon}
                                      alt="logo"
                                      style={{
                                        width: Theme.extraSmall,
                                        verticalAlign: 'middle',
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>

                        <div className="label metrics-right-label">
                          {item.key === 'sales_threshold' &&
                          !feeDetails?.threshold_type
                            ? 'None'
                            : feeDetails?.[item.subType] === 'monthly'
                            ? monthlyRetainerOptions.find(
                                (op, index) => index === getCurrentMonth,
                              )?.shortForm
                            : feeDetails?.[item.subType] === 'quarterly'
                            ? quarterlyRetainerOptions.find((op) =>
                                op.range.includes(getCurrentMonth),
                              )?.shortForm
                            : feeDetails?.[item.subType]}
                        </div>
                      </div>
                    </div>
                    {feeDetails?.[item.subType] === 'quarterly' ||
                    feeDetails?.[item.subType] === 'monthly' ? (
                      <>
                        <div className="label-info text-medium-font ">
                          {showFirstValue(
                            feeDetails,
                            agreement?.currency?.value,
                            item,
                          )}
                        </div>
                        <ReactTooltip
                          id="view-breakdown"
                          aria-haspopup="true"
                          place="bottom"
                          effect="solid"
                          backgroundColor="#162c50"
                          multiline
                          html
                        />
                        <div
                          data-tip={getQuarterMonthData(
                            feeDetails,
                            agreement?.currency?.value,
                            item,
                          )}
                          data-for="view-breakdown"
                          data-html
                          className="breakdown-label mt-2"
                        >
                          {`View Other ${
                            feeDetails?.[item.subType] === 'quarterly'
                              ? 'Quarters'
                              : 'Months'
                          }`}
                        </div>
                      </>
                    ) : null}
                  </div>
                </CardMetrics>
              </li>
            );
          }
          return null;
        })}
      </>
    );
  };

  const displayMarketplaces = (
    marketplaceArray,
    agreement,
    contractType,
    name,
  ) => {
    if (
      (contractType?.includes('dsp') &&
        agreement?.dsp_marketplaces &&
        Object.keys(agreement?.dsp_marketplaces)?.length) ||
      (contractType?.includes('recurring') &&
        agreement?.additional_marketplaces &&
        Object.keys(agreement?.additional_marketplaces)?.length) ||
      agreement?.primary_marketplace?.name
    ) {
      return (
        <>
          <div className={agreement?.contract_addendum ? 'col-6' : 'col-12'}>
            <div className="label">{name}</div>
            <ul className="selected-list">
              {contractType?.includes('recurring') ? (
                <li>
                  {displayMarketplacesName(
                    agreement?.primary_marketplace?.name,
                  )}{' '}
                  (Primary)
                </li>
              ) : null}
              {marketplaceArray?.map((item) => {
                if (contractType?.includes('recurring')) {
                  return (
                    <>
                      {item?.account_type?.toLowerCase() ===
                      accountType[agreement.id] ? (
                        <li key={item.id}>
                          {displayMarketplacesName(item.name) || ''}
                        </li>
                      ) : null}
                    </>
                  );
                }
                return (
                  <li key={item.id}>
                    {displayMarketplacesName(item.name) || ''}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      );
    }
    return null;
  };

  const displayAddendumStatus = (contractAddendum) => {
    return (
      <>
        <div className="col-6">
          <div className="label">Addendum(s)</div>
          {contractAddendum?.map((item) => (
            <Status
              className="m-1"
              type="agreement"
              label={
                item?.addendum_signed_date ||
                item?.addendum_status?.value === 'active'
                  ? `Signed (${dayjs(item?.addendum_signed_date).format(
                      'MMM DD, YYYY',
                    )})`
                  : item?.addendum_status?.label
                  ? item?.addendum_status?.label
                  : item?.addendum_status
              }
              backgroundColor={
                item?.addendum_signed_date ||
                item?.addendum_status?.value === 'active'
                  ? '#e3f2d2'
                  : '#fdf3d7'
              }
            />
          ))}
        </div>
      </>
    );
  };

  const displayOptimization = (name, agreement, array) => {
    const checkValueofArray = array.filter((op) => agreement[op.key] > 0);
    if (checkValueofArray?.length > 0) {
      return (
        <>
          <div className="straight-line light-line mt-3 mb-3" />
          <div className="row">
            {checkValueofArray.filter((op) => op.section === 1)?.length ? (
              <div className="col-6">
                <div className="label">{name}</div>
                <ul className="selected-list">
                  {checkValueofArray
                    .filter((op) => op.section === 1)
                    ?.map((item) => (
                      <li>
                        {item.label} - {agreement[item.key]} ASINs
                      </li>
                    ))}
                </ul>
              </div>
            ) : null}
            {checkValueofArray.filter((op) => op.section === 2)?.length ? (
              <div className="col-6">
                <div className="label">Translation/ Month</div>
                <ul className="selected-list">
                  {checkValueofArray
                    .filter((op) => op.section === 2)
                    .map((item) => (
                      <li>
                        {item.label} - {agreement[item.key]} ASINs
                      </li>
                    ))}
                </ul>
              </div>
            ) : null}
          </div>
        </>
      );
    }
    return null;
  };

  const displayStdServices = (name, array, nonUSService) => {
    if (array?.length > 0 || nonUSService?.length > 0) {
      return (
        <>
          <div className="straight-line light-line mt-3 mb-3" />
          <div className="label">{name}</div>
          <ul className="selected-list">
            {array?.map((item) => (
              <li>
                {item?.name || item?.service?.name}{' '}
                {item?.quantity ? `(${item.quantity})` : null}
              </li>
            ))}
            {nonUSService && nonUSService?.map((item) => <li>{item?.name}</li>)}
          </ul>
        </>
      );
    }
    return null;
  };

  const formatAmazonStorefront = (services, channelType) => {
    const storeFrontHome = services?.find(
      (op) =>
        op?.service?.name?.includes(`${channelType} Storefront Home`) &&
        op?.quantity !== 0,
    );
    const storeFrontTab = services?.find(
      (op) =>
        op?.service?.name === `${channelType} Storefront Tab` &&
        op?.quantity !== 0,
    );
    const storeFrontSubTab = services?.find(
      (op) =>
        op?.service?.name?.includes(`${channelType} Storefront Sub`) &&
        op?.quantity !== 0,
    );
    return (
      <li className="mb-2">
        {`${channelType} Storefront (${
          storeFrontHome?.quantity
            ? `${storeFrontHome.quantity} Home Page(s)`
            : '0 Home Page'
        } 
          ${
            storeFrontTab?.quantity
              ? ` + ${storeFrontTab.quantity} Tab(s)`
              : '+ 0 Tab'
          } 
          ${
            storeFrontSubTab?.quantity
              ? ` + ${storeFrontSubTab?.quantity} Sub Tab(s)`
              : '+ 0 Sub Tab'
          })`}
      </li>
    );
  };
  const formatInforgraphics = (services) => {
    const infoOriginal = services?.find(
      (op) =>
        op?.service?.name?.includes('Infographics Original') &&
        op?.quantity !== 0,
    );
    const infoVariation = services?.find(
      (op) =>
        op?.service?.name === 'Infographics Variation' && op?.quantity !== 0,
    );
    return (
      <li className="mb-2">
        {`Infographics (${
          infoOriginal?.quantity
            ? `${infoOriginal.quantity} Original(s)`
            : '0 Original'
        } 
          ${
            infoVariation?.quantity
              ? ` + ${infoVariation.quantity} Variation(s)`
              : '+ 0 Variation'
          })`}
      </li>
    );
  };

  const formatAContent = (services, serviceName) => {
    const similar = services?.find(
      (op) =>
        op?.service?.name?.includes(`${serviceName} Similar`) &&
        op?.quantity !== 0,
    );
    const original = services?.find(
      (op) =>
        op?.service?.name === `${serviceName} Original` && op?.quantity !== 0,
    );
    const premiumOriginal = services?.find(
      (op) =>
        op?.service?.name === `Premium ${serviceName} Content Original` &&
        op?.quantity !== 0,
    );
    const premiumVariation = services?.find(
      (op) =>
        op?.service?.name === `Premium ${serviceName} Content Variation` &&
        op?.quantity !== 0,
    );
    return (
      <li className="mb-2">
        {`${serviceName} Content (${
          similar?.quantity ? `${similar.quantity} Similar(s)` : '0 Similar'
        } 
          ${
            original?.quantity
              ? ` + ${original.quantity} Original(s)`
              : '+ 0 Original'
          } 
           ${
             premiumOriginal?.quantity
               ? ` + ${premiumOriginal.quantity} Premium Original(s)`
               : '+ 0 Premium Original'
           } 
            ${
              premiumVariation?.quantity
                ? ` + ${premiumVariation.quantity} Premium Variation(s)`
                : '+ 0 Premium Variation'
            })`}
      </li>
    );
  };

  const displayOneTimeServices = (name, services) => {
    if (services?.length > 0) {
      const checkAmazonStoreFront = services?.some((op) =>
        op?.service?.name?.includes('Amazon Storefront'),
      );
      const checkWalmartStoreFront = services?.some((op) =>
        op?.service?.name?.includes('Walmart Storefront'),
      );
      const checkInfographics = services?.some((op) =>
        op?.service?.name?.includes('Infographics'),
      );
      const checkAContent = services?.some((op) =>
        op?.service?.name?.includes('A+'),
      );
      const checkEnhancedContent = services?.some((op) =>
        op?.service?.name?.includes('Enhanced'),
      );
      return (
        <>
          <div className="straight-line light-line mt-3 mb-3" />
          <div className="label">{name}</div>
          <ul className="selected-list">
            {services
              .filter(
                (op) =>
                  !op?.service?.name.includes('Amazon Storefront') &&
                  !op?.service?.name.includes('Infographics') &&
                  !op?.service?.name.includes('A+') &&
                  !op?.service?.name.includes('Walmart Storefront') &&
                  !op?.service?.name.includes('Enhanced'),
              )
              .map((item) => (
                <li key={item.id}>
                  {item?.service?.name || ''} ({item?.quantity || ''})
                </li>
              ))}
            {checkAmazonStoreFront ? (
              <>{formatAmazonStorefront(services, 'Amazon')}</>
            ) : null}
            {checkWalmartStoreFront ? (
              <>{formatAmazonStorefront(services, 'Walmart')}</>
            ) : null}
            {checkInfographics ? <>{formatInforgraphics(services)}</> : null}
            {checkAContent ? <>{formatAContent(services, 'A+')}</> : null}
            {checkEnhancedContent ? (
              <>{formatAContent(services, 'Enhanced')}</>
            ) : null}
          </ul>
        </>
      );
    }
    return null;
  };

  const displayMonthlyServices = (name, array, contractId) => {
    if (array?.length > 0) {
      return (
        <>
          <div className="straight-line light-line mt-3 mb-3" />
          <div className="label">{name}</div>
          <ul className="selected-list">
            {array.map((item) => (
              <>
                {item?.account_type?.toLowerCase() ===
                accountType[contractId] ? (
                  <li>
                    {item.service.name}{' '}
                    {item.quantity ? `(${item.quantity})` : null}
                  </li>
                ) : null}
              </>
            ))}
          </ul>
        </>
      );
    }
    return null;
  };

  const displayAdvertisingServices = (name, agreement) => {
    return (
      <>
        <div className="straight-line light-line mt-3 mb-3" />
        <div className="label">{name}</div>
        <ul className="selected-list">
          {agreement?.is_dsp_advertising &&
          agreement?.contract_type?.includes('recurring') ? (
            <li>DSP Advertising</li>
          ) : null}
          {agreement?.amc_services?.map((item) => (
            <li>AMC Management {item.name} </li>
          ))}
        </ul>
      </>
    );
  };

  const displayNonUSServices = (name, services) => {
    if (services?.length > 0) {
      return (
        <>
          <div className="straight-line light-line mt-3 mb-3" />
          <div className="label">{name}</div>
          <ul className="selected-list">
            {services?.map((item) => (
              <li key={item?.id}>
                {displayMarketplacesName(item?.marketplace, 'vat') || ''}
                {item.frequency ? ` - ${item.frequency}` : null}
              </li>
            ))}
          </ul>
        </>
      );
    }
    return null;
  };

  const checkTheCount = (contractId) => {
    const a = showMoreCount?.filter(
      (op) => Object.keys(op)?.[0] === contractId,
    );
    return a?.length;
  };

  const getAgreementName = (agreementStatus) => {
    return agreementStatusNames?.[agreementStatus]
      ? agreementStatusNames?.[agreementStatus]
      : agreementStatus;
  };

  const handleOnAgreementStatusClick = (agreement) => {
    if (
      [
        'pending for cancellation',
        'pending cancellation approval',
        'cancel',
        'active partially pause',
        'active pending for pause',
        'active pending for pause request',
        'pause',
      ].includes(agreement?.contract_status?.value)
    )
      setShowModal({
        modal: true,
        id: agreement?.suspend_contract,
        type: agreement?.contract_type,
        endDate: agreement?.end_date,
        contractStatus: agreement?.contract_status?.value,
      });
  };

  const displayAgreementName = (agreement) => {
    const contractType = agreement?.contract_type?.toLowerCase();

    switch (contractType) {
      case 'recurring':
        return agreement?.channel?.value === 'amazon'
          ? 'Amazon Recurring Service Agreement'
          : 'Walmart Recurring Service Agreement';
      case 'recurring (90 day notice)':
        return agreement?.channel?.value === 'amazon'
          ? 'Amazon Recurring (90 day notice) Service Agreement'
          : 'Walmart Recurring (90 day notice) Service Agreement';
      case 'dsp only':
        return 'Amazon DSP Service Agreement';
      default:
        return null;
    }
  };

  return (
    <AgreementDetailOuterContainer>
      {isDesktop ? (
        <>
          {multipleAgreement.map((agreement) => {
            const contractType = agreement?.contract_type?.toLowerCase();
            const marketplaceArray = contractType?.includes('dsp')
              ? agreement?.dsp_marketplaces
              : agreement?.additional_marketplaces;
            return (
              <>
                <WhiteCard className="mt-4">
                  <div className="row">
                    <div
                      className={
                        agreement.seller_type?.label === 'Hybrid' &&
                        contractType?.includes('recurring')
                          ? 'col-7 pr-1'
                          : 'col-9'
                      }
                    >
                      <ul className="agreement-type">
                        <li>
                          <p className="black-heading-title text-medium-font  mt-0 mb-0 mr-5">
                            <img
                              width="48px"
                              className="solid-icon"
                              src={
                                agreement?.contract_status?.value === 'pause' ||
                                agreement?.contract_status?.value === 'draft'
                                  ? DisabledRecurring
                                  : contractType?.includes('dsp')
                                  ? DspOnlyIcon
                                  : RecurringIcon
                              }
                              alt="logo"
                            />{' '}
                            {displayAgreementName(agreement)}
                            {/* PDV-8634 condition to display icon only for collection and out of contract. */}
                            {agreement?.in_collections ? (
                              <FontAwesomeIcon
                                icon="fal fa-coins"
                                style={{
                                  // marginBottom: '-3px',
                                  color: Theme.blue,
                                }}
                                className="ml-2"
                                data-for="in-colletion-tooltip"
                                data-tip="In Collections"
                              />
                            ) : null}
                            {agreement?.out_of_contract ? (
                              <img
                                className="ml-2"
                                width="14px"
                                style={{ marginBottom: '-3px' }}
                                src={BrokenFile} // PDV-8634 change the icon as per BP status ()coin OR broken file
                                alt=""
                                data-for="out-of-contract-tooltip"
                                data-tip="Out Of Contract"
                              />
                            ) : null}
                          </p>
                          <ReactTooltip
                            className="asian-info-icon"
                            id="in-colletion-tooltip"
                            aria-haspopup="true"
                            place="bottom"
                            effect="solid"
                          />
                          <ReactTooltip
                            className="asian-info-icon"
                            id="out-of-contract-tooltip"
                            aria-haspopup="true"
                            place="bottom"
                            effect="solid"
                          />
                          <div style={{ display: 'flow-root' }}>
                            <Status
                              type="agreement"
                              className="mr-2 mt-1 text-medium-font "
                              icon={
                                !!contractStatusIcons[
                                  agreement?.contract_status?.value
                                ]
                              }
                              fontIcon={
                                contractStatusIcons[
                                  agreement?.contract_status?.value
                                ]
                              }
                              label={getAgreementName(
                                agreement?.contract_status?.value,
                              )}
                              backgroundColor={
                                agreement?.contract_status?.value ===
                                'completed'
                                  ? Theme.lightGreen
                                  : Theme.gray8
                              }
                              labelColor={
                                agreement?.contract_status?.value ===
                                'completed'
                                  ? Theme.green
                                  : Theme.black
                              }
                              cursor={
                                [
                                  'pending for cancellation',
                                  'pending cancellation approval',
                                  'active partially pause',
                                  'active pending for pause',
                                  'active pending for pause request',
                                  'pause',
                                ].includes(agreement?.contract_status?.value)
                                  ? 'pointer'
                                  : 'default'
                              }
                              handleOnClick={() =>
                                handleOnAgreementStatusClick(agreement)
                              }
                            />
                            {!agreement.contract_status?.value?.includes(
                              'cancel',
                            ) &&
                            agreement?.end_date &&
                            countDays(agreement?.end_date) <= 90 ? (
                              <Status
                                type="agreement"
                                className="mr-2 mb-1 mt-1 text-medium-font "
                                label={`${countDays(agreement?.end_date)} days`}
                                labelColor={Theme.orange}
                                icon
                                iconType={ClockIcon}
                                backgroundColor={Theme.lighterOrange}
                              />
                            ) : null}
                          </div>
                        </li>

                        {generateBasicInfo(
                          'Duration',
                          agreement?.length?.label,
                        )}
                        {agreement?.contract_status?.value !== 'pause' ? (
                          <>
                            {generateBasicInfo(
                              'Original Start',
                              dayjs(agreement?.start_date).format(
                                'MMM DD, YYYY',
                              ),
                            )}
                            {/* PDV-9084- No need to show  'First Renewed date' for dsp only agreement  */}
                            {!contractType?.includes('dsp') &&
                            agreement?.is_renewed &&
                            (agreement?.contract_status?.value !== 'pending' ||
                              agreement?.contract_status?.value !==
                                'pending account setup') ? (
                              <>
                                {generateBasicInfo(
                                  'First Renewed',
                                  dayjs(agreement?.renewed_date).format(
                                    'MMM DD, YYYY',
                                  ),
                                )}
                              </>
                            ) : null}

                            {/* PDV-8928 - Showing out of contract date */}
                            {agreement?.out_of_contract
                              ? generateBasicInfo(
                                  'Out Of Contract',
                                  dayjs(agreement?.out_of_contract_date).format(
                                    'MMM DD, YYYY',
                                  ),
                                )
                              : null}

                            {generateBasicInfo(
                              'Expires',
                              dayjs(agreement?.end_date).format('MMM DD, YYYY'),
                            )}
                            {contractType?.includes('recurring') &&
                            agreement?.seller_type?.label !== 'Hybrid' &&
                            agreement?.seller_type?.label ? (
                              <li className="other-details">
                                <div className="label">Agreement Type</div>
                                <div className="label-info text-medium-font ">
                                  {agreement?.seller_type?.label}
                                </div>
                              </li>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {getPauseHTML(
                              agreement && agreement.pause_contract,
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                    {showActionDropdown(agreement)}
                  </div>
                  <div className="metrics-card mb-3">
                    <ul className="metrics-columns">
                      {contractType?.includes('recurring') &&
                      agreement?.fee_structure &&
                      Object.keys(agreement?.fee_structure)?.length &&
                      !agreement?.fee_structure?.fee_type ? (
                        <>
                          {agreement?.fee_structure?.[
                            accountType[agreement?.id]
                          ]?.fee_type ? (
                            <div className="straight-line light-line mt-3" />
                          ) : null}
                          {generateCardHTML(agreement, contractType)}
                        </>
                      ) : null}
                    </ul>
                  </div>
                  {agreement?.additional_monthly_services?.find(
                    (op) => op?.service?.name === 'DSP Advertising',
                  ) || agreement?.is_dsp_advertising ? (
                    <>
                      <div className="straight-line light-line mt-3 mb-3" />
                      <div className="metrics-card mb-3">
                        <ul className="metrics-columns">
                          {generateDSPAdvHTML(agreement)}
                        </ul>
                      </div>
                    </>
                  ) : null}
                  {agreement?.contract_addendum?.length ||
                  (contractType?.includes('dsp') &&
                    agreement?.dsp_marketplaces &&
                    Object.keys(agreement?.dsp_marketplaces)?.length) ||
                  (contractType?.includes('recurring') &&
                    agreement?.additional_marketplaces &&
                    Object.keys(agreement?.additional_marketplaces)?.length) ||
                  agreement?.primary_marketplace?.name ? (
                    <div className="straight-line light-line  mb-3" />
                  ) : null}
                  <div className="row">
                    {displayMarketplaces(
                      marketplaceArray,
                      agreement,
                      contractType,
                      contractType?.includes('dsp')
                        ? 'DSP Marketplace(s)'
                        : 'Marketplace(s)',
                    )}

                    {agreement?.contract_addendum?.length ? (
                      <>{displayAddendumStatus(agreement?.contract_addendum)}</>
                    ) : null}
                  </div>

                  <Collapse isOpened={agreementDetailsCollapse[agreement.id]}>
                    {displayOptimization(
                      'Optimizations / Month',
                      agreement,
                      OptimizationArray,
                    )}
                    {displayStdServices(
                      'Standard Services',
                      agreement?.standard_us_services,
                      agreement?.standard_non_us_services?.filter(
                        (op) =>
                          op.name !== 'VAT Registration' &&
                          op.name !== 'VAT Filing',
                      ),
                      agreement?.id,
                    )}
                    {displayMonthlyServices(
                      'Additional Monthly Services',
                      agreement?.additional_monthly_services,
                      agreement?.id,
                    )}
                    {displayAdvertisingServices(
                      'Advertising Services',
                      agreement,
                    )}
                    {displayOneTimeServices(
                      'One Time Services',
                      agreement?.additional_one_time_services,
                      agreement?.id,
                    )}
                    {displayNonUSServices(
                      'VAT Registration',
                      agreement?.standard_non_us_services?.filter(
                        (op) => op.name === 'VAT Registration',
                      ),
                      agreement?.id,
                    )}
                    {displayNonUSServices(
                      'VAT Filing',
                      agreement?.standard_non_us_services?.filter(
                        (op) => op.name === 'VAT Filing',
                      ),
                      agreement?.id,
                    )}
                  </Collapse>
                </WhiteCard>
                {checkTheCount(agreement.id) > 0 ? (
                  <div
                    className="collapse-in-out"
                    onClick={() =>
                      setAgreementDetailsCollapse({
                        [agreement.id]: !agreementDetailsCollapse[agreement.id],
                      })
                    }
                    role="presentation"
                  >
                    {!agreementDetailsCollapse[agreement.id]
                      ? `Expand Agreement`
                      : 'Collapse Agreement'}
                    <img
                      className="ml-1"
                      src={CaretUp}
                      alt="caret"
                      style={{
                        transform: agreementDetailsCollapse[agreement.id]
                          ? 'rotate(180deg)'
                          : '',

                        width: '20px',
                        height: '20px',
                        verticalAlign: 'middle',
                      }}
                    />{' '}
                  </div>
                ) : null}
              </>
            );
          })}
        </>
      ) : (
        <AgreementDetailTabMobView
          setAgreementDetailsCollapse={setAgreementDetailsCollapse}
          agreementDetailsCollapse={agreementDetailsCollapse}
          multipleAgreement={multipleAgreement}
          countDays={countDays}
          toggleAccountType={toggleAccountType}
          showActionDropdown={showActionDropdown}
          generateBasicInfo={generateBasicInfo}
          getPauseHTML={getPauseHTML}
          generateCardHTML={generateCardHTML}
          generateDSPAdvHTML={generateDSPAdvHTML}
          displayMarketplaces={displayMarketplaces}
          displayOptimization={displayOptimization}
          displayStdServices={displayStdServices}
          displayMonthlyServices={displayMonthlyServices}
          displayNonUSServices={displayNonUSServices}
          OptimizationArray={OptimizationArray}
          checkTheCount={checkTheCount}
          displayOneTimeServices={displayOneTimeServices}
          displayAddendumStatus={displayAddendumStatus}
          accountType={accountType}
          handleOnAgreementStatusClick={handleOnAgreementStatusClick}
          getAgreementName={getAgreementName}
        />
      )}
      <Modal
        isOpen={showModal.modal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add team modal"
      >
        <PendingAgreementModal
          setShowModal={setShowModal}
          selectedAgreement={showModal}
        />
      </Modal>
    </AgreementDetailOuterContainer>
  );
}

AgreementDetails.defaultProps = {
  isSpreetailUser: false,
  multipleAgreement: [],
  history: {},
};

AgreementDetails.propTypes = {
  isSpreetailUser: bool,
  id: string.isRequired,
  history: shape({
    location: {},
  }),
  userRole: string.isRequired,
  multipleAgreement: arrayOf(shape({})),
  DropdownIndicator: func.isRequired,
  IconOption: func.isRequired,
  draftContractOptions: func.isRequired,
  setDropdownOptions: func.isRequired,
  handleContractOptions: func.isRequired,
  getPauseHTML: func.isRequired,
};
