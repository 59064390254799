export const getNonUSStdServicesOfAddendum = (formData, originalData) => {
  const saperateFormDataNonUsmarketplaces = (dataInfo) => {
    return (
      dataInfo?.length &&
      dataInfo
        .filter((item) => item.name === 'VAT Registration')
        .map((filteredItem) => filteredItem)
    );
  };
  const addedRemovedStandardServices = (data1, data2) => {
    return data1?.filter((item) => {
      return !data2.some((item1) => {
        return item?.marketplace === item1?.marketplace;
      });
    });
  };
  // 1. For VAT Registration
  const formDataNonUsmarketplaces =
    saperateFormDataNonUsmarketplaces(
      formData?.standard_non_us_services?.length &&
        formData?.standard_non_us_services,
    ) || [];

  const originalDataNonUsmarketplaces =
    saperateFormDataNonUsmarketplaces(
      originalData?.standard_non_us_services?.length &&
        originalData?.standard_non_us_services,
    ) || [];

  const uniqueNonUsServices = addedRemovedStandardServices(
    formDataNonUsmarketplaces,
    originalDataNonUsmarketplaces,
  );

  // 2. for Vat Filling

  const seperateVATFilingMarketplaces = (dataInfo) => {
    const marketplaces =
      dataInfo?.length &&
      dataInfo.filter(
        (item) =>
          item.name === 'VAT Filing' &&
          item.marketplace !== null &&
          item.frequency !== null,
      );

    const result = [];
    if (marketplaces?.length) {
      marketplaces.map((item) => {
        result.push(
          //  `${displayMarketplaceName(item.marketplace, 'vat', 'lhs')} (${
          //    item?.frequency
          //  })`,
          item,
        );
        return null;
      });
    }
    return result.length ? result : [];
  };

  const formDataVATFilingMarketplaces =
    seperateVATFilingMarketplaces(
      formData?.standard_non_us_services?.length &&
        formData?.standard_non_us_services,
    ) || [];

  const originalDataVATFilingMarketplaces =
    seperateVATFilingMarketplaces(
      originalData?.standard_non_us_services?.length &&
        originalData?.standard_non_us_services,
    ) || [];

  const addedVatFillingNonUsServices = addedRemovedStandardServices(
    formDataVATFilingMarketplaces,
    originalDataVATFilingMarketplaces,
  );

  // 3. for international service

  const seperateFormDataInternationalNonUsServices = (dataInfo) => {
    return (
      dataInfo?.length &&
      dataInfo.filter((item) => item?.name?.includes('International'))
    );
  };

  const uniqueInternationalNonUsServices = (data1, data2) =>
    data1?.filter((item) => {
      return !data2.some((item1) => {
        return item.name === item1.name;
      });
    });

  const formDataInternationalNonUsServices =
    seperateFormDataInternationalNonUsServices(
      formData?.standard_non_us_services?.length &&
        formData?.standard_non_us_services,
    ) || [];

  const originalDataInternationalNonUsServices =
    seperateFormDataInternationalNonUsServices(
      originalData?.standard_non_us_services?.length &&
        originalData?.standard_non_us_services,
    ) || [];

  const addedInternationalNonUsServices = uniqueInternationalNonUsServices(
    formDataInternationalNonUsServices,
    originalDataInternationalNonUsServices,
  );

  return {
    vatRegistration: uniqueNonUsServices,
    vatFilling: addedVatFillingNonUsServices,
    internationalService: addedInternationalNonUsServices,
  };
};

export const getUpdatedStrByReplacingChannelType = (strData, formData) => {
  return strData?.replaceAll('CHANNEL_TYPE', formData?.channel?.label);
};
