/* eslint-disable no-else-return */
import React, { useState, useEffect, useCallback } from 'react';

// Third party imports
import dayjs from 'dayjs';
import Modal from 'react-modal';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { func, string, bool, shape, oneOfType, arrayOf } from 'prop-types';

// local Imports
import Theme from '../../../../theme/Theme';
import PastAgreement from './PastAgreement';
import AgreementDetails from './AgreementDetails';
import OneTimeAgreement from './OneTimeAgreement';
import AgreementPauseModal from '../Modals/AgreementPauseModal';
import AddChildAgreementModal from '../Modals/AddChildAgreementModal';
import { getAccountDetails } from '../../../../store/actions/accountState';
import { AddNewContractModal, AgreementCancelModal } from '../Modals';
import { FileContract, ArrowIcons, CloseIcon } from '../../../../theme/images';
import {
  createContract,
  deleteContract,
  deleteContractAddendum,
  getBGSManagers,
  saveDraftAddendum,
} from '../../../../api';
import {
  PageLoader,
  Tabs,
  ModalBox,
  Button,
  DropDownUncontained,
  DropdownIndicator,
  ViewData,
  DropDownSelect,
  WhiteCard,
  SingleSelectDropdown,
  GrayMessageBar,
} from '../../../../common';
import {
  PATH_AGREEMENT,
  contractOptions,
  draftContractOptions,
  newAgreementTypes,
  oneTimeAgreementOptions,
  contractOptionsWithoutPauseCancel,
  contractOptionsWithoutAddendum,
  draftAddendumOptions,
  contractOptionsWithAddendum,
  PATH_CUSTOMER_DETAILS,
  contractOptionsWithPaused,
  contractOptionsWithActivePendingPaused,
  SpreetailRole,
  SpreetailRoleRestrictMessage,
} from '../../../../constants';

export default function AgreementDetailContainer({
  id,
  setShowMemberList,
  showModal,
  setShowModal,
  userRole,
  memberData,
  params,
  isBBEInternalUser,
  childBPList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in agreement section
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const agreementMessage =
    params?.nav === 'recurring'
      ? dynamicMessages?.recurring_agreements?.placeholder
      : params?.nav === 'one-time'
      ? dynamicMessages?.one_time_agreements?.placeholder
      : null;

  const multipleAgreement = useSelector(
    (state) => state.accountState.multipleAgreement,
  );
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const customerState = useSelector((state) => state.customerState.data);
  const loader = useSelector((state) => state.accountState.isLoading);
  const [showPastAgreements, setShowPastAgreements] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bgsManagerEmail, setBgsManagerEmail] = useState(null);
  const [showAddContractModal, setShowAddContractModal] = useState({
    agreement: {},
    show: false,
  });
  const [typeOfNewAgreement, setTypeOfNewAgreement] = useState({});
  const [existingContracts, setExistingContracts] = useState([]);
  const [replaceExisting, setReplaceExisting] = useState('alongside');
  const [replacedContract, setReplacedContract] = useState('');
  const [contractLoader, setContractLoader] = useState(false);
  const [isAllowToCancelAgreement, setIsAllowToCancelAgreement] =
    useState(false);
  const [cancelAgreementAccess, setCancelAgreementAccess] = useState(false);
  const [isBgsManager, setIsBgsManager] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  // For Dropdown Options
  const [tempContractOptions, setTempContractOptions] =
    useState(contractOptions);
  const [tempContractOptionsPendingPause, setTempContractOptionsPendingPause] =
    useState(contractOptionsWithoutAddendum);
  const [
    tempContractOptionsWithoutPauseCancel,
    setTempContractOptionsWithoutPauseCancel,
  ] = useState(contractOptionsWithoutPauseCancel);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );
  const [isBgs, setIsBgs] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const { Option } = components;

  // PDV-8556 - cancel agreement permissions
  const IconOption = (dataProps) => {
    return dataProps?.data?.value === 'cancel' &&
      !cancelAgreementAccess ? null : (
      <Option {...dataProps}>
        <img
          className="drop-down-user"
          src={dataProps.data.icon}
          alt="user"
          style={{
            marginRight: '9px',
            height: '20px',
            verticalAlign: 'middle',
            width: '18px',
            float: 'left',
          }}
        />
        {dataProps.data.label}
      </Option>
    );
  };

  const finalRequestApprovalUser = userInfo?.is_final_request_approvers;
  const firstRequestApprovalUser = userInfo?.is_primary_request_approvers;

  useEffect(() => {
    if (childBPList?.length) setSelectedCustomerId(childBPList?.[0]?.value);
  }, [childBPList]);

  const checkPermission = useCallback(
    (members) => {
      if (
        userInfo?.role?.includes('BGS Admin') ||
        finalRequestApprovalUser ||
        firstRequestApprovalUser
      ) {
        setIsAllowToCancelAgreement(true);
      }
      if (members) {
        for (const user of members) {
          if (user.user) {
            if (
              (user?.role_group?.name === 'BGS Manager' ||
                user?.role_group?.name === 'BGS' ||
                finalRequestApprovalUser ||
                firstRequestApprovalUser) &&
              user?.user?.id === userInfo?.id
            ) {
              setIsAllowToCancelAgreement(true);
            }

            if (
              user?.role_group?.name === 'BGS Manager' &&
              user?.user?.id === userInfo?.id
            ) {
              setIsBgsManager(true);
            }

            if (
              user?.role_group?.name === 'BGS' &&
              user?.user?.id === userInfo?.id
            ) {
              setIsBgs(true);
            }
          }
        }
      }
    },
    [userInfo, finalRequestApprovalUser, firstRequestApprovalUser],
  );

  // PDV-8556 - Cancel agreement permissions
  // checking the new permission for cancel agreement
  const checkCancelAgreementPermission = useCallback(
    (members) => {
      if (
        userInfo?.role?.includes('BGS Admin') ||
        finalRequestApprovalUser ||
        firstRequestApprovalUser
      ) {
        setCancelAgreementAccess(true);
      } else if (members) {
        for (const user of members) {
          if (user?.user) {
            const isBgsManagerAssignedToBP =
              user?.role_group?.name === 'BGS Manager' &&
              user?.user?.id === userInfo?.id;

            if (isBgsManagerAssignedToBP) {
              setCancelAgreementAccess(true);
            }
          }
        }
      }
    },
    [finalRequestApprovalUser, firstRequestApprovalUser, userInfo],
  );
  useEffect(() => {
    checkPermission(memberData);
    checkCancelAgreementPermission(memberData);
  }, [checkCancelAgreementPermission, checkPermission, memberData]);

  const filterAddendumOptions = (param, setOptions) => {
    if (param && param.length) {
      const remainingAddendumOptions = param.filter(
        (item) => item.label !== 'Draft Addendum',
      );
      setOptions(remainingAddendumOptions);
    }
  };

  useEffect(() => {
    // Remove "Draft Addendum" from options if any addendum contract is not Active/Renewed.
    const showAddendumOption = multipleAgreement?.filter(
      (ele) =>
        ele?.contract_status?.value === 'active' ||
        ele?.contract_status?.value === 'renewed',
    );
    if (showAddendumOption?.length > 0) {
      setTempContractOptions(contractOptions);
      setTempContractOptionsPendingPause(contractOptionsWithoutAddendum);
      setTempContractOptionsWithoutPauseCancel(
        contractOptionsWithoutPauseCancel,
      );
    } else {
      filterAddendumOptions(tempContractOptions, setTempContractOptions);
      filterAddendumOptions(
        tempContractOptionsPendingPause,
        setTempContractOptionsPendingPause,
      );
      filterAddendumOptions(
        tempContractOptionsWithoutPauseCancel,
        setTempContractOptionsWithoutPauseCancel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleAgreement]);

  useEffect(() => {
    dispatch(getAccountDetails(selectedCustomerId));
    getBGSManagers(selectedCustomerId).then((res) => {
      setBgsManagerEmail(res?.data?.email);
    });
  }, [dispatch, selectedCustomerId]);

  const createNewContract = (details) => {
    // Check if the agreemnt is draft or not & send API params accordingly
    createContract(details).then((res) => {
      setContractLoader(false);
      setIsLoading(false);
      history.push({
        pathname: PATH_AGREEMENT.replace(':id', selectedCustomerId).replace(
          ':contract_id',
          res?.data?.id,
        ),
        state: history?.location?.search,
        showEditView: true,
      });
    });
  };

  const addBGSMangerEmail = () => {
    const firstModal = showModal;
    setShowModal(false);
    setShowMemberList({
      show: true,
      add: true,
      modal: true,
      agreement: firstModal,
    });
  };

  const getPauseHTML = (agreement) => {
    return (
      <>
        <li>
          <p className="basic-text ">
            {agreement && agreement.pause_length} Days
          </p>
        </li>
        <li>
          {/* <span className="dot" /> */}
          <p className="basic-text ">
            Paused &nbsp;
            {dayjs(agreement && agreement.start_date).format('MMM DD, YYYY')}
          </p>
        </li>
        <li>
          {/* <span className="dot" /> */}
          <p className="basic-text ">
            Expires:{' '}
            {dayjs(agreement && agreement.end_date).format('MMM DD, YYYY')}
          </p>
        </li>
      </>
    );
  };

  const handleContractOptions = (event, agreementId, contractAddendumId) => {
    switch (event.value) {
      case 'view':
        history.push({
          pathname: PATH_AGREEMENT.replace(':id', selectedCustomerId).replace(
            ':contract_id',
            agreementId,
          ),
          state: history?.location?.search,
        });
        break;
      case 'draft':
        createNewContract({
          customer_id: selectedCustomerId,
          draft_from: agreementId,
        });
        break;
      case 'pause':
        setShowModal({ pause: true, agreementId });
        break;
      case 'cancel':
        setShowModal({ cancel: true, agreementId });
        break;
      case 'edit':
        history.push({
          pathname: PATH_AGREEMENT.replace(':id', selectedCustomerId).replace(
            ':contract_id',
            agreementId,
          ),
          state: history?.location?.search,
          showEditView: true,
        });
        break;
      case 'delete':
        setShowModal({ delete: true, agreementId });
        break;
      case 'addendum':
        saveDraftAddendum(null, { contract: agreementId }).then((res) => {
          if (res.status === 201 || res.status === 200) {
            history.push({
              pathname: PATH_AGREEMENT.replace(
                ':id',
                selectedCustomerId,
              ).replace(':contract_id', agreementId),
              state: history?.location?.search,
              showEditView: true,
            });
          }
        });
        break;
      case 'deleteAddendum':
        setShowModal({ delete: true, contractAddendumId });
        break;
      default:
        break;
    }
  };

  const setDropdownOptions = (agreement) => {
    if (isSpreetailUser) {
      return contractOptionsWithPaused;
    }
    if (
      agreement?.contract_status?.value === 'pause' ||
      agreement?.contract_status?.value === 'active pending for pause'
    ) {
      if (isBgsManager || userInfo?.role?.includes('BGS Admin') || isBgs)
        return contractOptionsWithActivePendingPaused;
      else return contractOptionsWithPaused;
    }

    if (agreement && agreement?.contract_addendum?.length) {
      // If agreements addendum_status is not active then show this dropdown options
      const notActiveAddendum = agreement.contract_addendum?.filter((item) => {
        if (item.addendum_status?.value) {
          return item?.addendum_status?.value !== 'active';
        }
        return item?.addendum_status !== 'active';
      });
      if (notActiveAddendum?.length) {
        return draftAddendumOptions;
      }
    }

    if (
      isAllowToCancelAgreement &&
      agreement?.contract_status?.value === 'active pending for pause' &&
      !agreement?.pause_contract?.is_approved
    ) {
      return tempContractOptionsPendingPause;
    }

    if (
      agreement?.contract_status?.value === 'pending for cancellation' ||
      agreement?.contract_status?.value === 'pending cancellation approval'
    ) {
      return tempContractOptionsWithoutPauseCancel;
    }

    if (
      (agreement?.contract_status?.value !== 'inactive' &&
        agreement?.contract_status?.value !== 'cancel') ||
      agreement?.contract_status?.value === 'active' ||
      agreement?.contract_status?.value === 'renewed'
    ) {
      // return contractOptions;
      if (isAllowToCancelAgreement) {
        return tempContractOptions;
      }
      return contractOptionsWithAddendum;
    }
    return tempContractOptionsWithoutPauseCancel;
  };

  const generateModals = () => {
    if (showModal.pauseApproval) {
      return (
        <>
          <p className="black-heading-title text-center mt-3">
            Pause Agreement
          </p>
          {!bgsManagerEmail ? (
            <p className="long-text mb-2 text-center ">
              For approval please add the{' '}
              <span
                className="cursor"
                style={{ color: Theme.orange }}
                onClick={() => addBGSMangerEmail()}
                role="presentation"
              >
                BGS Manager
              </span>{' '}
              first.
            </p>
          ) : (
            ''
          )}
          <p className="long-text mb-2 text-center ">
            {' '}
            Are you sure you would like to request approval to pause this
            agreement?
          </p>
        </>
      );
    }
    if (showModal.contractAddendumId) {
      return (
        <>
          <p className="black-heading-title text-center  mt-2">
            Delete Addendum
          </p>
          <div className="alert-msg pb-3 ">
            Are you sure you would like to delete this addendum?
            <div className="sure-to-proceed">This action cannot be undone.</div>
          </div>
        </>
      );
    }

    return (
      <>
        <p className="black-heading-title text-center  mt-2">
          Delete Agreement
        </p>
        <div className="alert-msg pb-3 ">
          Are you sure you would like to delete this agreement?
          <div className="sure-to-proceed">This action cannot be undone.</div>
        </div>
      </>
    );
  };

  const saveDetails = (status) => {
    setIsLoading(true);
    if (showModal.cancel) {
      setShowModal(false);
      toast.success(
        `We've emailed to the concerned team for cancellation of your agreement.`,
      );
      dispatch(getAccountDetails(selectedCustomerId));
    } else if (showModal.pause) {
      // https://bbe.atlassian.net/browse/PDV-9280 - Billing Pausing Improvement Logic
      if (status) {
        toast.success(`Paused request ${status} successfully...`);
      } else if (!isBgsManager) {
        if (userInfo?.role === 'BGS Admin') {
          toast.success(`Pause Request has been approved.`);
        } else {
          toast.success(
            `We have emailed the BGS manager for Pausing your agreement.`,
          );
        }
      }
      setShowModal(false);
      dispatch(getAccountDetails(selectedCustomerId));
    } else if (showModal.contractAddendumId) {
      deleteContractAddendum(showModal.contractAddendumId).then(() => {
        setShowModal(false);
        setIsLoading(false);
        dispatch(getAccountDetails(selectedCustomerId));
        toast.success('Your Addendum is deleted successfully.');
      });
    } else {
      deleteContract(showModal.agreementId).then(() => {
        setShowModal(false);
        setIsLoading(false);
        dispatch(getAccountDetails(selectedCustomerId));
        toast.success('Your Agreement is deleted successfully.');
      });
    }
  };

  const addNewOneTime = (channel) => {
    const data = {
      customer_id: id,
      contract_type: 'one time',
      start_date: dayjs(new Date()).format('YYYY-MM-DD'),
      channel,
    };
    createContract(data).then((res) => {
      history.push({
        pathname: PATH_AGREEMENT.replace(':id', id).replace(
          ':contract_id',
          res && res.data && res.data.id,
        ),
        state: history?.location?.search,
      });
    });
  };

  const checkExistingAgreements = (agreementType) => {
    if (params.nav === 'one-time') {
      addNewOneTime(agreementType?.channel);
    } else {
      // Filter active & renewed agreements of selected type
      const filteredContracts = multipleAgreement.filter(
        (agreement) =>
          agreement.contract_type === agreementType.value &&
          agreement?.channel?.value === agreementType?.channel &&
          (agreement.contract_status.value === 'active' ||
            agreement.contract_status.value === 'renewed'),
      );
      setExistingContracts([...filteredContracts]);

      // if there are any filterd agreements present then show modal, else create new agreement
      if (filteredContracts.length > 0) {
        setShowAddContractModal({ agreement: agreementType, show: true });
        setReplacedContract(filteredContracts[0].id);
      } else {
        setIsLoading(true);
        const data = {
          customer_id: selectedCustomerId,
          contract_type: agreementType.value,
          start_date: dayjs(new Date()).format('YYYY-MM-DD'),
          channel: agreementType?.channel,
          length: agreementType.value === 'dsp only' ? '3 Months' : '12 Months',
        };
        if (agreementType?.channel === 'walmart') {
          data.agency_fee = null;
        }
        createNewContract(data);
      }
    }
  };

  const confirmContract = () => {
    // if agreement will run alongside current agreement, else replace existing agreement

    const data = {
      customer_id: selectedCustomerId,
      contract_type: typeOfNewAgreement.value,
      channel: 'amazon',
      start_date: dayjs(new Date()).format('YYYY-MM-DD'),
      length:
        typeOfNewAgreement.value === 'dsp only' ? '3 Months' : '12 Months',
    };

    // PDV-9693 for walmart contract send agency_fee null
    if (showAddContractModal?.agreement?.channel === 'walmart') {
      data.agency_fee = null;
      data.channel = 'walmart';
    }
    // start date and length field added as per bug ticket PDV-9188
    createNewContract(data);
  };

  const checkActiveRenewedAgreementCount = () => {
    const fields = [];
    for (const agreement of multipleAgreement) {
      if (
        agreement.contract_status?.value === 'active' ||
        agreement.contract_status?.value === 'renewed' ||
        (agreement.contract_type.includes('one') &&
          agreement.contract_status?.value === 'active')
      )
        fields.push(agreement);
    }
    return fields.length;
  };

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      setSelectedCustomerId(event?.value);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        isSearchable={false}
      />
    );
  };

  return (
    <>
      <div className="col-12 cutomer-middle-panel">
        <div className="row">
          <div
            className={
              userRole !== 'Customer' ? 'col-md-7 pr-0 col-12' : 'col-12'
            }
          >
            <div className="justify-space-between ">
              <Tabs>
                <ul className="tabs border-none scrollable-container">
                  <li
                    className={params?.nav === 'recurring' ? 'active' : ''}
                    onClick={() =>
                      history.push(
                        `${PATH_CUSTOMER_DETAILS.replace(
                          ':id',
                          id,
                        )}?tab=agreement&nav=recurring&access=${isBBEInternalUser}`,
                      )
                    }
                    role="presentation"
                  >
                    Recurring Agreements
                  </li>
                  <li
                    className={params?.nav === 'one-time' ? 'active' : ''}
                    onClick={() =>
                      history.push(
                        `${PATH_CUSTOMER_DETAILS.replace(
                          ':id',
                          id,
                        )}?tab=agreement&nav=one-time&access=${isBBEInternalUser}`,
                      )
                    }
                    role="presentation"
                  >
                    One Time Agreements
                  </li>
                </ul>
              </Tabs>
            </div>
          </div>
          {/* Added below condition as per ticket PDV-8398 */}
          {userRole !== 'Customer' &&
          (customerState?.account_type === 'standalone' ||
            (customerState?.account_type === 'child' &&
              customerState?.active_contract)) ? (
            <div className="col-md-5 col-12 pl-md-0 text-right">
              <AddNewAgreementDropdown
                className={
                  childBPList?.length || params.nav === 'one-time'
                    ? 'agreement-line'
                    : 'bottom-line'
                }
              >
                {childBPList?.length ? (
                  <Button
                    className="agreement-btn"
                    data-tip={SpreetailRoleRestrictMessage}
                    data-for={
                      isSpreetailUser ? 'spreetailRetrictNewAgreement' : ''
                    }
                    onClick={() => setShowAgreementModal(true)}
                    disabled={isSpreetailUser}
                  >
                    {' '}
                    <FontAwesomeIcon
                      className="mr-2"
                      icon="fa-regular fa-plus"
                      color="#ff4817"
                      fontSize="12px"
                    />{' '}
                    {params.nav === 'one-time'
                      ? 'Add New One Time Agreement'
                      : 'Add New Agreement'}
                  </Button>
                ) : (
                  <DropDownSelect className="dropdown-btn addNewAgreement w-250">
                    {loader ? null : (
                      <DropDownUncontained
                        options={
                          params.nav === 'one-time'
                            ? oneTimeAgreementOptions
                            : newAgreementTypes
                        }
                        setSelectedOption={setTypeOfNewAgreement}
                        extraAction={checkExistingAgreements}
                        DropdownIndicator={DropdownIndicator}
                        multipleAgreement={multipleAgreement}
                      />
                    )}
                  </DropDownSelect>
                )}
                <ReactTooltip
                  id="spreetailRetrictNewAgreement"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />
              </AddNewAgreementDropdown>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="horizontal-line straight-line mb-3" />

        {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in agreement section */}
        {agreementMessage ? (
          <GrayMessageBar message={agreementMessage} />
        ) : null}
        <>
          {loader ? (
            <PageLoader
              component="agrement-details"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />
          ) : (
            <>
              {params?.nav === 'recurring' ? (
                <>
                  {childBPList?.length ? (
                    <WhiteCard className="mb-3 mt-3">
                      <ViewData>
                        <div className="row">
                          <div className="col-md-4  col-sm-12 ">
                            <div className="view-data-for mt-2 pt-1 ">
                              View data for:
                            </div>{' '}
                          </div>
                          <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
                          <div className="col-md-4 col-12 pl-0">
                            {displayChildBPFilter()}
                          </div>
                        </div>
                      </ViewData>
                    </WhiteCard>
                  ) : null}
                  <AgreementDetails
                    id={selectedCustomerId}
                    history={history}
                    userRole={userRole}
                    IconOption={IconOption}
                    DropdownIndicator={DropdownIndicator}
                    setDropdownOptions={setDropdownOptions}
                    draftContractOptions={draftContractOptions}
                    handleContractOptions={handleContractOptions}
                    multipleAgreement={multipleAgreement.filter(
                      (ele) =>
                        !ele.contract_type?.toLowerCase()?.includes('one') &&
                        ele?.contract_status?.value !== 'inactive' &&
                        ele?.contract_status?.value !== 'cancel',
                    )}
                    getPauseHTML={getPauseHTML}
                    isSpreetailUser={isSpreetailUser}
                  />
                  <div className="looking-past-agre" role="presentation">
                    <p className="gray-normal-text">
                      <img
                        width="16px;"
                        style={{ verticalAlign: 'text-top', marginLeft: '5px' }}
                        src={FileContract}
                        alt="file"
                      />{' '}
                      Looking for Past Agreements?
                      <span
                        className="cursor"
                        role="presentation"
                        onClick={() => setShowPastAgreements(true)}
                        style={{
                          fontWeight: '600',
                          marginLeft: '7px',
                          position: 'relative',
                        }}
                      >
                        {' '}
                        View here
                        <img
                          style={{
                            transform: 'rotate(180deg)',
                            width: '13px',
                            top: '2px',
                            right: '-16px',
                            position: 'absolute',
                          }}
                          src={ArrowIcons}
                          alt="arrow"
                        />
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <OneTimeAgreement
                    agreements={multipleAgreement.filter((op) =>
                      op.contract_type.toLowerCase().includes('one'),
                    )}
                    id={selectedCustomerId}
                    history={history}
                    DropdownIndicator={DropdownIndicator}
                    IconOption={IconOption}
                    setShowModal={setShowModal}
                    userRole={userRole}
                    childBPList={childBPList}
                    selectedCustomerId={selectedCustomerId}
                    setSelectedCustomerId={setSelectedCustomerId}
                    isSpreetailUser={isSpreetailUser}
                  />
                </>
              )}
            </>
          )}
        </>
        {showPastAgreements ? (
          <PastAgreement
            showPastAgreements={showPastAgreements}
            setShowPastAgreements={setShowPastAgreements}
            history={history}
            id={selectedCustomerId}
            agreements={multipleAgreement.filter(
              (op) =>
                op?.contract_status?.value === 'inactive' ||
                op?.contract_status?.value === 'cancel',
            )}
          />
        ) : (
          ''
        )}
        <Modal
          isOpen={showModal[Object.keys(showModal)[0]]}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Edit modal"
        >
          {showModal.pause || showModal.cancel ? (
            <img
              src={CloseIcon}
              alt="close"
              className="float-right cursor cross-icon"
              onClick={() => {
                setShowModal(false);
              }}
              role="presentation"
            />
          ) : null}

          {showModal.cancel ? (
            <AgreementCancelModal
              agreementId={showModal?.agreementId}
              isBgsManager={isBgsManager}
              saveDetails={() => saveDetails()}
              bgsManagerEmail={bgsManagerEmail}
              addBGSMangerEmail={addBGSMangerEmail}
              contractType={
                multipleAgreement?.find(
                  (item) => item?.id === showModal?.agreementId,
                )?.contract_type
              }
              agreementCount={checkActiveRenewedAgreementCount()}
              showModal={showModal}
              confirmContract={confirmContract}
            />
          ) : showModal.pause ? (
            <AgreementPauseModal
              agreementId={showModal?.agreementId}
              isBgs={isBgs}
              isBgsManager={isBgsManager}
              saveDetails={(status) => saveDetails(status)}
              bgsManagerEmail={bgsManagerEmail}
              addBGSMangerEmail={addBGSMangerEmail}
              setShowModal={setShowModal}
            />
          ) : (
            <ModalBox>
              <div className="modal-body">
                {generateModals()}
                <div className="row mt-1">
                  <div className="col-6 mt-4">
                    <Button
                      className="btn-primary w-100"
                      onClick={() => saveDetails()}
                      disabled={!bgsManagerEmail && !showModal.delete}
                    >
                      {isLoading ? (
                        <PageLoader color="#fff" type="button" />
                      ) : (
                        'Confirm Delete'
                      )}
                    </Button>
                  </div>
                  <div className="col-6 mt-4">
                    <Button
                      className="btn-transparent w-100"
                      onClick={() => {
                        setShowModal({
                          ...showModal,
                          pause: false,
                          pauseApproval: false,
                          cancel: false,
                          delete: false,
                        });
                      }}
                    >
                      Don&apos;t Delete
                    </Button>
                  </div>
                </div>
              </div>
            </ModalBox>
          )}
        </Modal>
        {showAddContractModal?.show ? (
          <AddNewContractModal
            customStyles={customStyles}
            showAddContractModal={showAddContractModal}
            setShowAddContractModal={setShowAddContractModal}
            typeOfNewAgreement={typeOfNewAgreement}
            existingContracts={existingContracts}
            replaceExisting={replaceExisting}
            setReplaceExisting={setReplaceExisting}
            replacedContract={replacedContract}
            setReplacedContract={setReplacedContract}
            confirmContract={confirmContract}
            contractLoader={contractLoader}
            setContractLoader={setContractLoader}
          />
        ) : null}
        <Modal
          isOpen={showAgreementModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="add agreement"
        >
          <FontAwesomeIcon
            icon="fa-light fa-times"
            style={{ color: '#000', fontSize: '20px' }}
            onClick={() => setShowAgreementModal(false)}
            className="float-right cursor p-3"
          />
          <AddChildAgreementModal
            id={id}
            setShowAgreementModal={setShowAgreementModal}
            childBPList={childBPList}
          />
        </Modal>
      </div>
    </>
  );
}

AgreementDetailContainer.defaultProps = {
  memberData: [],
  isBBEInternalUser: 'internal',
  childBPList: [],
};

AgreementDetailContainer.propTypes = {
  id: string.isRequired,
  memberData: arrayOf(shape({})),
  setShowMemberList: func.isRequired,
  setShowModal: func.isRequired,
  showModal: oneOfType([bool, shape({})]).isRequired,
  userRole: string.isRequired,
  params: shape({}).isRequired,
  isBBEInternalUser: string,
  childBPList: arrayOf(shape({})),
};

const AddNewAgreementDropdown = styled.div`
  &.bottom-line {
    margin: -15px 0 0 -15px;
  }

  &.agreement-line {
    margin: -13px 0 0 0;
  }

  .agreement-btn {
    border-radius: 18px;
    border: 1px solid ${Theme.gray11};
    min-height: 36px;
    text-align: center;
    font-size: ${Theme.extraNormal};
    font-family: ${Theme.baseMediumFontFamily};
    color: ${Theme.black};
    padding: 10px 12px;
    margin-bottom: 10px;
    background-color: ${Theme.gray6};
  }

  .dropdown-btn {
    padding: 6px 6px;
  }
  @media only screen and (max-width: 767px) {
    &.bottom-line,
    &.agreement-line {
      margin-top: 15px;
    }
  }
`;
