import React, { useCallback, useEffect, useRef, useState } from 'react';

import { arrayOf, func, shape, string } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import MetricsInvoices from './MetricsInvoices';
import InvoiceList from './InvoiceList';
import { PageLoader } from '../../../../../common';
import { getMetricsInvoiceData } from '../../../../../api';

const Invoice = ({
  onLoading,
  id,
  invoiceType,
  channelInvoiceType,
  bpName,
  memberData,
}) => {
  const [loader, setLoader] = useState(false);
  const [invoiceMetricsData, setInvoiceMetricsData] = useState(null);
  const mounted = useRef(false);

  // get invoice metrics data
  const getInvoiceMericsData = useCallback(
    (type, channelType) => {
      setLoader(true);

      getMetricsInvoiceData(type, id, channelType).then((response) => {
        if (mounted.current) {
          if (response?.status === 200 && response?.data) {
            setInvoiceMetricsData(response?.data);
            setLoader(false);
          } else {
            setInvoiceMetricsData(null);
            setLoader(false);
          }
        }
      });
    },
    [id],
  );

  // use effect for get invoice metrics data
  useEffect(() => {
    mounted.current = true;
    getInvoiceMericsData(invoiceType, channelInvoiceType);
    return () => {
      mounted.current = false;
    };
  }, [channelInvoiceType, getInvoiceMericsData, invoiceType]);

  useEffect(() => {
    onLoading(loader);
  }, [loader, onLoading]);

  return (
    <div className="mt-4">
      {loader ? (
        <PageLoader
          component="agrement-details"
          type="detail"
          color={Theme.orange}
          width={40}
          height={40}
        />
      ) : (
        <>
          <MetricsInvoices data={invoiceMetricsData} />
          <InvoiceList
            invoiceType={invoiceType}
            loader={loader}
            id={id}
            bpName={bpName}
            channelInvoiceType={channelInvoiceType}
            memberData={memberData}
          />
        </>
      )}
    </div>
  );
};

export default Invoice;

Invoice.defaultProps = {
  invoiceType: 'rev share',
  channelInvoiceType: 'all',
  onLoading: () => {},
  bpName: '',
};

Invoice.propTypes = {
  id: string.isRequired,
  invoiceType: string,
  channelInvoiceType: string,
  onLoading: func,
  bpName: string,
  memberData: arrayOf(shape({})).isRequired,
};
